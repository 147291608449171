import { Injectable } from '@angular/core'
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita'
import { ICurrency } from '../../common/api-interfaces'

export interface CurrenciesState extends EntityState<ICurrency, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'currencies', idKey: 'code' })
export class CurrenciesStore extends EntityStore<CurrenciesState> {
    constructor() {
        super()
    }
}
