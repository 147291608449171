import { animate, state, style, transition, trigger } from '@angular/animations'
import { HttpClient, HttpParams } from '@angular/common/http'
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { faTrash, faUserAltSlash } from '@fortawesome/pro-regular-svg-icons'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { IContact, IUser } from '../api-interfaces'
import { isAccountNumber, isEmail } from '../common.mixin'
import { ListComponent } from '../list.component'
import { ConfirmationService } from '../services/confirmation.service'
import { ContactService } from '../services/core/contact.service'
import { SessionService } from '../services/session.service'
import { ToastrService } from '../services/toastr.service'

@Component({
    selector: 'contact-book',
    templateUrl: 'contact-book.component.html',
    animations: [
        trigger('showButton', [
            state(
                'true',
                style({
                    width: '55px',
                    display: 'block',
                })
            ),
            state(
                'false',
                style({
                    width: '0',
                    display: 'none',
                })
            ),
            transition('0 <=> 1', animate('200ms')),
        ]),
    ],
})
export class ContactBookComponent extends ListComponent<IContact> implements OnInit {
    @Input()
    public user: IUser
    @Output()
    public readonly onSelect = new EventEmitter<IContact>()
    @Output()
    public readonly onUpdate = new EventEmitter<void>()
    public fetchEvent = new Subject<void>()
    // This one is only use for display, since we need to manipulate search property before sending it
    public searchText = ''

    public isLoading = false
    public deletingContacts = new Set<string>()
    public defaultLimit = 5
    public faTrash = faTrash
    public faUserAltSlash = faUserAltSlash

    constructor(
        http: HttpClient,
        ngbModal: NgbModal,
        changeDetector: ChangeDetectorRef,
        route: ActivatedRoute,
        router: Router,
        session: SessionService,
        confirmation: ConfirmationService,
        public modal: NgbActiveModal,
        private toastr: ToastrService,
        private contactService: ContactService,
        public translate: TranslateService
    ) {
        super(http, ngbModal, changeDetector, route, router, session, confirmation)
    }

    public ngOnInit(): void {
        this.apiUrl = `/users/${this.user!.id}/contacts`
        super.ngOnInit()
    }

    public addContact(name: string): void {
        this.isLoading = true
        this.http
            .get<IContact[]>(this.apiUrl, {
                params: new HttpParams().set('search', name.trim().replace(/\s/g, '')),
            })
            .subscribe(contacts => {
                const contact = contacts.find(
                    c => !!c.counterUser && (c.counterUser.accountNumber === name || c.counterUser.email === name)
                )
                if (contact) {
                    this.isLoading = false
                    this.onSelect.emit(contact)
                } else {
                    if (!isAccountNumber(name, { allowSpaces: true }) && !isEmail(name)) {
                        this.toastr.error(this.translate.instant('contact-book.must-be-an-account-number-or-email'))
                        this.isLoading = false
                        return
                    }
                    this.contactService
                        .create({
                            counterUser: isEmail(name)
                                ? { email: name.trim().replace(/\s/g, '') }
                                : { accountNumber: name.trim().replace(/\s/g, '') },
                            baseUser: { id: this.user.id },
                        })
                        .pipe(
                            finalize(() => {
                                this.isLoading = false
                            })
                        )
                        .subscribe(() => {
                            this.updateEvent.next()
                            this.onUpdate.emit()
                        })
                }
            })
    }

    public removeContact(contact: IContact): void {
        if (this.deletingContacts.has(contact.id)) {
            return
        }
        this.deletingContacts.add(contact.id)
        this.http
            .delete<void>(`/contacts/${contact.id}`)
            .pipe(
                finalize(() => {
                    this.deletingContacts.delete(contact.id)
                })
            )
            .subscribe(() => {
                this.updateEvent.next()
                this.onUpdate.emit()
            })
    }

    public searchContacts(search: string): void {
        this.search = search.replace(/ /g, '')
        this.searchEvent.next()
    }

    protected async changeState(): Promise<void> {
        // do nothing
    }
}

export function openContactBook(ngbModal: NgbModal, owner: IUser): NgbModalRef {
    const contactBookModal = ngbModal.open(ContactBookComponent, {
        windowClass: 'modal-primary',
    })
    const contactBook = contactBookModal.componentInstance as ContactBookComponent
    contactBook.user = owner
    return contactBookModal
}
