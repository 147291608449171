import { Pipe, PipeTransform } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import formatDate from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
    public transform(value: any, format: string): SafeUrl {
        if (typeof value !== 'string') {
            return value
        }
        return formatDate(parseISO(value), format)
    }
}
