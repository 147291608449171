import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'

@Directive({
    selector: '[nameValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NameValidatorDirective, multi: true }],
})
export class NameValidatorDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        if (!!control.value?.match(/[0-9!$%^&*()_+|~=`{}[:;<>?@#\]]/g)) {
            return { nameError: true }
        }
        return null
    }
}
