import { Component } from '@angular/core'
import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import { timer } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { StatisticsService } from '../../../../common/services/core/statistics.service'

@Component({
    selector: 'online-users-count',
    templateUrl: 'online-users-count.component.html',
})
export class OnlineUsersCountComponent {
    public faUsers = faUsers
    public userCount$ = timer(0, 1000 * 60).pipe(flatMap(() => this.statistics.getOnlineUsersCount()))
    constructor(private statistics: StatisticsService) {}
}
