import { Pipe, PipeTransform } from '@angular/core'
import { isBefore, parseISO } from 'date-fns'

@Pipe({ name: 'isBefore' })
export class IsBeforePipe implements PipeTransform {
    public transform(value: any): boolean {
        if (typeof value !== 'string') {
            return false
        }
        return isBefore(parseISO(value), new Date())
    }
}
