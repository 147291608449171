import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { faEdit, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { forkJoin, Observable, Subject, Subscription } from 'rxjs'
import { filter, finalize, flatMap, switchMap, tap } from 'rxjs/operators'
import { File as IFile } from '../api-interfaces'
import { toStartCase } from '../common.mixin'
import { DropzoneComponent } from '../dropzone/dropzone.component'
import { buildFileUrl } from '../file'
import { Transaction } from '../models/accounting/transaction.model'
import { ConfirmationResult, ConfirmationService } from '../services/confirmation.service'
import { ToastrService } from '../services/toastr.service'

@Component({
    selector: 'transaction-documents',
    templateUrl: 'transaction-documents.component.html',
})
export class TransactionDocumentsComponent implements OnInit, OnDestroy {
    @Input()
    public transaction: Transaction
    @Input()
    public buttonAlign = 'right'
    @Input()
    public showButton = true
    @Input()
    public canCreate = true
    @Input()
    public canView = true
    @Input()
    public canEdit = true
    @Input()
    public canDelete = true
    @Input()
    public requiredMessage: string
    @Output()
    public readonly onUpload = new EventEmitter<void>()

    @ViewChild('dropzone')
    public dropzone: DropzoneComponent

    public isUploading = false

    public files: File[] = []
    public existingFiles: IFile[] | null

    public subscriptions = new Subscription()
    public fetchEvent = new Subject<void>()

    public toStartCase = toStartCase
    public getFileUrl = buildFileUrl
    public faTimes = faTimes
    public faEdit = faEdit

    public limitOpts: number[] = [5, 8, 10, 12, 15, 20, 50]
    public limit: number
    public offset = 0
    public count = 0
    public page: number

    protected defaultLimit = this.limitOpts[1]

    protected apiUrl: string

    constructor(
        private http: HttpClient,
        private confirmation: ConfirmationService,
        private toastr: ToastrService,
        public translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.limit = this.defaultLimit
        this.subscriptions.add(
            this.fetchEvent
                .pipe(
                    switchMap(() => this.find()),
                    tap(response => {
                        this.count =
                            response.headers && response.headers.has('x-total-count')
                                ? ~~response.headers.get('x-total-count')!
                                : 0
                        this.page = this.offset / this.limit + 1
                    })
                )
                .subscribe(files => {
                    this.existingFiles = files.body
                })
        )
        this.fetchEvent.next()
    }

    public find(): Observable<HttpResponse<IFile[]>> {
        return this.http.get<IFile[]>(`/admin/transactions/${this.transaction.id}/files`, {
            observe: 'response',
            params: new HttpParams().set('limit', this.limit + '').set('offset', this.offset + ''),
        })
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public select(page?: number): void {
        if (typeof page !== 'undefined') {
            this.page = page
        }
        this.offset = (this.page - 1) * this.limit || 0
        this.fetchEvent.next()
    }

    public uploadFiles(): void {
        this.isUploading = true
        this.subscriptions.add(
            forkJoin(
                this.files.map<any>(file =>
                    this.http.post<File>(`/admin/transactions/${this.transaction.id}/files`, file, {
                        headers: new HttpHeaders({
                            'X-File-Name': encodeURIComponent(file.name),
                            'Content-Type': file.type,
                        }),
                    })
                )
            )
                .pipe(
                    finalize(() => {
                        this.isUploading = false
                    })
                )
                .subscribe(() => {
                    this.files = []
                    this.onUpload.emit()
                    this.fetchEvent.next()
                    this.toastr.success(this.translate.instant('transaction-form.transaction-files-uploaded'))
                })
        )
    }

    public deleteFile(file: IFile): void {
        this.subscriptions.add(
            this.confirmation
                .show({
                    type: 'danger',
                    text: `${this.translate.instant('common.you-are-about-to-delete')} ${file.name}`,
                    confirmText: this.translate.instant('common.delete'),
                    confirmClass: 'danger',
                    cancelText: this.translate.instant('common.keep'),
                    cancelClass: 'success',
                })
                .pipe(
                    filter(result => result === ConfirmationResult.CONFIRMED),
                    flatMap(() => this.http.delete<void>(`/transactions/${this.transaction.id}/files/${file.id}`))
                )
                .subscribe(() => {
                    this.fetchEvent.next()
                    this.toastr.success(`${file.name} deleted`)
                })
        )
    }

    public submit(): void {
        if (this.dropzone) {
            this.dropzone.submit()
        }
    }

    public isValid(): boolean {
        return this.dropzone.isValid()
    }
}
