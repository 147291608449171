<div class="modal-header">
    <h5 class="modal-title">{{ 'common.contact-book' | translate }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="modal.dismiss()">&times;</button>
</div>
<div class="modal-subheader">{{ 'common.add-contacts' | translate }}</div>
<div class="modal-body">
    <form class="mb-3" #contactForm="ngForm" (ngSubmit)="addContact(searchText)">
        <div class="input-group mb-3">
            <span class="input-group-text">
                <fa-icon [icon]="faSearch"></fa-icon>
            </span>
            <input
                autocomplete="off"
                type="text"
                name="search"
                (ngModelChange)="searchContacts($event)"
                [(ngModel)]="searchText"
                [placeholder]="'common.search-contacts' | translate"
                class="form-control"
                [focus]="true"
            />
            <button
                [@showButton]="(itemsStream | async)?.length === 0 && searchText.length > 0"
                type="submit"
                class="btn btn-primary overflow-hidden"
                [ngClass]="{ loading: isLoading }"
                [disabled]="isLoading"
            >
                {{ 'common.add' | translate }}
            </button>
        </div>
    </form>
    <ng-container *ngIf="itemsStream | async as items">
        <ng-container *ngIf="items.length > 0; else noContacts">
            <div class="list-group list-group-flush mb-3">
                <a
                    href
                    class="list-group-item list-group-item-action d-flex align-items-center justify-content-center p-3"
                    *ngFor="let contact of items"
                    (click)="onSelect.emit(contact); $event.preventDefault()"
                >
                    <div class="avatar me-3">
                        <user-avatar [user]="contact.counterUser"></user-avatar>
                    </div>
                    <div class="w-100">
                        <div class="text-primary">{{ contact.counterUser.name }}</div>
                        <small
                            >{{ contact.counterUser.accountNumber | accountNumber }}
                            <span class="text-muted text-capitalize">/ {{ contact.counterUser.type }}</span></small
                        >
                    </div>
                    <div *ngIf="contact.id" class="btn-group">
                        <button
                            type="button"
                            class="btn btn-default text-medium"
                            (click)="removeContact(contact); $event.stopPropagation(); $event.preventDefault()"
                            [ngClass]="{ loading: deletingContacts.has(contact.id) }"
                            [disabled]="deletingContacts.has(contact.id)"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>
                </a>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <ngb-pagination
                        class="mb-0"
                        [(page)]="page"
                        [collectionSize]="count"
                        (pageChange)="select()"
                        [pageSize]="limit"
                        [rotate]="false"
                        [boundaryLinks]="true"
                    >
                    </ngb-pagination>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #noContacts>
        <div *ngIf="searchText.length === 0; else addContacts" class="text-center mt-4 mb-3">
            <fa-icon class="text-muted" [icon]="faUserAltSlash" size="3x"></fa-icon>
            <h3 class="text-muted mt-2">{{ 'common.no-contacts' | translate }}</h3>
            <p class="text-muted">
                {{ 'common.add-people' | translate }}
            </p>
        </div>
    </ng-template>
    <ng-template #addContacts>
        <div class="text-center my-5">
            <fa-icon class="text-muted" [icon]="faUserAltSlash" size="3x"></fa-icon>
            <h3 class="text-muted mt-2">"{{ searchText }}" {{ 'common.not-found' | translate }}</h3>
            <small class="text-muted">{{ 'common.press-enter-to-add' | translate }} "{{ searchText }}"</small>
        </div>
    </ng-template>
</div>
