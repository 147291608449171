import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms'

@Directive({
    selector: '[integerValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: IntegerValidatorDirective, multi: true }],
})
export class IntegerValidatorDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const required = Validators.required(control)
        if (required !== null) {
            return null
        }
        if (!Number.isInteger(control.value)) {
            return { invalidInteger: true }
        }
        return null
    }
}
