import { Currency } from './currency.model'
import { ICurrency, IUser, Product, Wallet as IWallet } from '../../api-interfaces'
import { User } from '../core/user.model'

export class Wallet implements IWallet {
    public id: string
    public balance: string
    public user: IUser | null
    public product: Product | null
    public currency: ICurrency
    public availableBalance: string
    public frozenBalance: string
    public createdAt: string
    public updatedAt: string

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data)
            this.currency = new Currency(data.currency)
            if (data.user) {
                this.user = new User(data.user)
            }
        }
    }

    public isRootWallet(): boolean {
        return this.user === null
    }
}
