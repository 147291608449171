import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { pick } from 'lodash'
import { filter, withLatestFrom } from 'rxjs/operators'
import { AvatarService } from './avatar.service'
import { SessionService, UserEventType } from './session.service'

@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    private isEnabled = false

    constructor(session: SessionService, router: Router, private avatarService: AvatarService) {
        window.intercomSettings = window.intercomSettings || {}
        this.boot()
        if (this.isEnabled) {
            session.userEvent.pipe(withLatestFrom(session.userStream)).subscribe(([event, user]) => {
                const avatarUrl = this.avatarService.getAvatarUrl(user)
                if (event.type === UserEventType.LOGIN) {
                    window.intercomSettings = {
                        ...window.intercomSettings,
                        user_id: user.id,
                        user_hash: user.intercomHash,
                        name: user.name,
                        email: user.email,
                        created_at: new Date(user.createdAt).getTime(),
                        avatar: {
                            type: 'avatar',
                            image_url: avatarUrl,
                        },
                        // Custom fields
                        ...(pick(user, ['accountNumber', 'status', 'approvedAt']) as any),
                    }
                    this.update()
                } else if (event.type === UserEventType.LOGOUT) {
                    this.shutdown()
                    this.boot()
                }
            })
            router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
                this.update()
            })
        }
    }

    public boot(): void {
        if (window.INTERCOM_APP_ID) {
            if (!window.Intercom) {
                /* eslint-disable */
                ;(function () {
                    var w = window
                    var ic = w.Intercom
                    if (typeof ic === 'function') {
                        ic('reattach_activator')
                        ic('update', w.intercomSettings)
                    } else {
                        var d = document
                        var i: any = function () {
                            i.c(arguments)
                        }
                        i.q = []
                        i.c = function (args: any) {
                            i.q.push(args)
                        }
                        w.Intercom = i
                        const l = () => {
                            var s = d.createElement('script')
                            s.type = 'text/javascript'
                            s.async = true
                            s.src = `https://widget.intercom.io/widget/${window.INTERCOM_APP_ID}`
                            var x = d.getElementsByTagName('script')[0]
                            x.parentNode!.insertBefore(s, x)
                        }
                        if ((w as any).attachEvent) {
                            ;(w as any).attachEvent('onload', l)
                        } else {
                            w.addEventListener('load', l, false)
                        }
                    }
                })()
                /* eslint-enable */
            }
            this.isEnabled = true
            window.Intercom!('boot', { app_id: window.INTERCOM_APP_ID })
        }
    }

    public trackEvent(event: string, data?: any): void {
        if (this.isEnabled) {
            window.Intercom!('trackEvent', event, data)
        }
    }

    public shutdown(): void {
        if (this.isEnabled) {
            window.intercomSettings = {}
            window.Intercom!('shutdown')
            this.isEnabled = false
        }
    }

    public update(options?: { email?: string; hide_default_launcher?: boolean }): void {
        if (this.isEnabled) {
            window.Intercom!('update', options)
        }
    }

    public hide(): void {
        if (this.isEnabled) {
            window.Intercom!('hide')
        }
    }

    public show(): void {
        if (this.isEnabled) {
            window.Intercom!('show')
        }
    }

    public startConversation(prePopulatedMessage?: string): void {
        if (this.isEnabled) {
            window.Intercom!('showNewMessage', prePopulatedMessage)
        }
    }
}
