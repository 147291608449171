import {
    animate,
    state as animationState,
    transition as animationTransition,
    style,
    trigger,
} from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { IsActiveMatchOptions, NavigationStart, Router } from '@angular/router'
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons'
import { faSignalAlt3 } from '@fortawesome/pro-duotone-svg-icons'
import {
    faBalanceScale,
    faBell,
    faBook,
    faChartBar,
    faChartLine,
    faChartNetwork,
    faCity,
    faClipboardList,
    faCog,
    faCoins,
    faCompass,
    faCreditCardFront,
    faDesktop,
    faDollarSign,
    faFileInvoice,
    faHistory,
    faInbox,
    faLink,
    faNewspaper,
    faReceipt,
    faShieldAlt,
    faSitemap,
    faSync,
    faToolbox,
    faUniversity,
    faUser,
    faUserFriends,
    faUsers,
    faWallet,
} from '@fortawesome/pro-regular-svg-icons'
import {
    faAngleDown,
    faArrowAltToBottom,
    faBox,
    faCaretRight,
    faExchange,
    faMinus,
    faPaperPlane,
    faPlus,
    faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { Subscription, of } from 'rxjs'
import { filter, mergeMap } from 'rxjs/operators'
import { WhitelabelService } from 'src/app/common/services/core/whitelabel.service'
import { environment } from 'src/environments/environment'
import { MenuService, MobileNavLayout } from './menu.service'
import { SessionService } from '../../../common/services/session.service'

@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    animations: [
        trigger('toggleMenu', [
            animationState('false', style({ height: '0px' })),
            animationState('true', style({ height: '*' })),
            animationTransition('1 => 0', animate('280ms ease-in')),
            animationTransition('0 => 1', animate('280ms ease-out')),
        ]),
    ],
})
export class MenuComponent implements OnInit, OnDestroy {
    public faArrowAltToBottom = faArrowAltToBottom
    public faDesktop = faDesktop
    public faPaperPlane = faPaperPlane
    public faBook = faBook
    public faCreditCardFront = faCreditCardFront
    public faToolbox = faToolbox
    public faInbox = faInbox
    public faCog = faCog
    public faUser = faUser
    public faUsers = faUsers
    public faCity = faCity
    public faWallet = faWallet
    public faBell = faBell
    public faCaretRight = faCaretRight
    public faSync = faSync
    public faBalanceScale = faBalanceScale
    public faUniversity = faUniversity
    public faCompass = faCompass
    public faChartNetwork = faChartNetwork
    public faLink = faLink
    public faShieldAlt = faShieldAlt
    public faReceipt = faReceipt
    public faFileInvoice = faFileInvoice
    public faTimes = faTimes
    public faAngleDown = faAngleDown
    public faUserFriends = faUserFriends
    public faChartBar = faChartBar
    public faPlus = faPlus
    public faMinus = faMinus
    public faExchange = faExchange
    public faBox = faBox
    public faSitemap = faSitemap
    public faChartLine = faChartLine
    public faNewsPaper = faNewspaper
    public faDollarSign = faDollarSign
    public faCoins = faCoins
    public faClipboardList = faClipboardList
    public faCcMastercard = faCcMastercard
    public faHistory = faHistory
    public faSignalAlt3 = faSignalAlt3

    public layout = environment.layout
    public appName = environment.appName
    public logo: string

    private subscriptions = new Subscription()
    private _activeMenu: string | undefined

    constructor(
        public session: SessionService,
        private router: Router,
        private menuService: MenuService,
        public translate: TranslateService,
        private whitelabel: WhitelabelService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.router.events
                .pipe(filter((event): event is NavigationStart => event instanceof NavigationStart))
                .subscribe((event: NavigationStart) => {
                    this.activeMenu = event.url
                })
        )

        this.subscriptions.add(
            this.whitelabel.whitelabel$
                .pipe(mergeMap(whitelabel => (whitelabel ? of(whitelabel.horizontalLogo) : of(`/img/brand/logo.svg`))))
                .subscribe(logo => {
                    this.logo = `url(${logo})`
                })
        )
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public isActive(
        state: string,
        options: IsActiveMatchOptions = {
            matrixParams: 'subset',
            queryParams: 'subset',
            paths: 'subset',
            fragment: 'ignored',
        }
    ): boolean {
        return this.router.isActive(state, options)
    }

    public isOpen(menu: string): boolean {
        return this.activeMenu === menu
    }

    public toggleMenu(menu: string): void {
        this.activeMenu = this.activeMenu !== menu ? menu : undefined
    }

    public closeMobileNav(): void {
        this.menuService.mobileNav = MobileNavLayout.HIDDEN
    }

    public get activeMenu(): string | undefined {
        return this._activeMenu
    }

    public set activeMenu(route: string | undefined) {
        if (!route) {
            this._activeMenu = undefined
            return
        }
        if (route.startsWith('/admin')) {
            route = route.substring('/admin'.length)
        }
        if (route.startsWith('/merchant')) {
            route = route.substring('/merchant'.length)
        }
        if (route.startsWith('/')) {
            route = route.substring(1)
        }
        const routeParts = route.split('/')
        this._activeMenu = routeParts[0] || undefined
    }

    public navigate(route: string): void {
        this.router.navigate([route])
    }
}
