import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'
import { isAccountNumber, isEmail } from '../common.mixin'

@Directive({
    selector: '[recipientValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: RecipientValidatorDirective, multi: true }],
})
export class RecipientValidatorDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        if (!isAccountNumber(control.value || '', { allowSpaces: true }) && !isEmail(control.value || '')) {
            return { invalidRecipient: true }
        }
        return null
    }
}
