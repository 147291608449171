import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { merge, of, ReplaySubject, Subject } from 'rxjs'
import { filter, flatMap, map, switchMap } from 'rxjs/operators'
import { SessionService, UserEventType } from './session.service'

@Injectable({
    providedIn: 'root',
})
export class MailNotificationsService {
    public unreadMessagesStream = new ReplaySubject<number>(1)
    public onRead = new Subject<void>()
    private notifyEvent = new Subject<void>()

    constructor(private session: SessionService, private http: HttpClient) {
        merge(this.notifyEvent, this.session.userEvent.pipe(filter(event => event.type === UserEventType.LOGIN)))
            .pipe(
                switchMap(() => this.session.userStream),
                flatMap(user =>
                    user
                        ? this.http.head(`/users/${user.id}/mails`, {
                              observe: 'response',
                              params: new HttpParams().set('scope', 'unread'),
                          })
                        : of(null)
                ),
                map(response =>
                    response && response.headers.has('x-total-count') ? ~~response.headers.get('x-total-count')! : 0
                )
            )
            .subscribe(count => {
                this.unreadMessagesStream.next(count)
            })
    }

    public notify(): void {
        this.onRead.next()
        this.notifyEvent.next()
    }
}
