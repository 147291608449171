import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { OnlineUser } from '../../api-interfaces'

@Injectable({
    providedIn: 'root',
})
export class StatisticsService {
    constructor(private http: HttpClient) {}

    public getOnlineUsersCount(): Observable<number> {
        return this.http
            .head('/admin/statistics/online-users', { observe: 'response' })
            .pipe(
                map(response =>
                    response && response.headers.has('x-total-count') ? ~~response.headers.get('x-total-count')! : 0
                )
            )
    }

    public getOnlineUsers(): Observable<OnlineUser[]> {
        return this.http.get<OnlineUser[]>('/admin/statistics/online-users')
    }
}
