import { Injectable } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { assignInWith } from 'lodash'
import { from, Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { BootstrapComponentType } from '../common.mixin'
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component'

export interface ConfirmationOptions {
    type?: BootstrapComponentType
    title?: string
    text: string
    confirmText?: string
    confirmClass?: BootstrapComponentType
    cancelText?: string
    cancelClass?: BootstrapComponentType
    showCancel?: boolean
}

export interface InputConfirmationOptions extends ConfirmationOptions {
    input: 'text'
    inputLabel?: string
    inputValidator?(value: string): string | null
}

export enum ConfirmationResult {
    CONFIRMED = 'confirmed',
    CANCELLED = 'cancelled',
    DISMISSED = 'dismissed',
}

@Injectable({
    providedIn: 'root',
})
export class ConfirmationService {
    constructor(private modal: NgbModal) {}
    public show(options: ConfirmationOptions): Observable<ConfirmationResult>
    public show(options: InputConfirmationOptions): Observable<[ConfirmationResult, string]>
    public show(options: InputConfirmationOptions): Observable<ConfirmationResult | [ConfirmationResult, string]> {
        const modal = this.modal.open(ConfirmationModalComponent, {
            windowClass: options.type ? 'modal-' + options.type : '',
            size: 'sm',
        })
        const modalInstance = modal.componentInstance as ConfirmationModalComponent
        assignInWith(modalInstance, options, (initValue, optValue) =>
            ![undefined, null].includes(optValue) ? optValue : initValue
        )
        const mapResult = (result: ConfirmationResult): ConfirmationResult | [ConfirmationResult, string] =>
            options.input ? [result, modalInstance.inputValue] : result
        return from(modal.result).pipe(
            map(confirmed => mapResult(confirmed ? ConfirmationResult.CONFIRMED : ConfirmationResult.CANCELLED)),
            catchError(() => of(mapResult(ConfirmationResult.DISMISSED)))
        )
    }
}
