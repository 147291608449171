import { Component, Input } from '@angular/core'

@Component({
    selector: 'image',
    template: `<img class="img-fluid w-100" [src]="src" />`,
})
export class ImageComponent {
    @Input()
    public src: string
}
