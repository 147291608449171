<form
    class="form-validation e2e-file-form"
    #userFileForm="ngForm"
    (ngSubmit)="save()"
    [ngClass]="{ 'ng-submitted': userFileForm.submitted }"
    autocomplete="false"
    novalidate
>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'common.update-file' | translate }}</h5>
        <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="type">{{ 'common.type' | translate }}</label>
            <ng-select
                name="type"
                [(ngModel)]="copy.type"
                [items]="types"
                [clearable]="false"
                [searchable]="false"
                [placeholder]="'common.select-type' | translate"
                required
                #type="ngModel"
            >
                <ng-template ng-label-tmp let-item="item">{{ toStartCase(mapTypes(item)) }}</ng-template>
                <ng-template ng-option-tmp let-item="item">{{ toStartCase(mapTypes(item)) }}</ng-template>
            </ng-select>
            <div *ngIf="type.invalid && (userFileForm.submitted || type.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="type?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-w-sm" [ngClass]="{ loading: isSaving }" [disabled]="isSaving">
            {{ 'common.save' | translate }}
        </button>
    </div>
</form>
