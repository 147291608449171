<div class="table-responsive">
    <ul *ngIf="user.type === 'business'" class="nav nav-pills nav-fill mb-2">
        <li class="nav-item">
            <a href class="nav-link" [ngClass]="{ active: currentTab === 'business' }" (click)="currentTab = 'business'; $event.preventDefault()">
                {{ 'common.business' | translate }}
            </a>
        </li>
        <li class="nav-item">
            <a href class="nav-link" [ngClass]="{ active: currentTab === 'manager' }" (click)="currentTab = 'manager'; $event.preventDefault()">
                {{ 'common.manager' | translate }}
            </a>
        </li>
    </ul>
    <ng-container *ngIf="user.type === 'personal' || (user.type === 'business' && currentTab === 'manager'); else business">
        <table *ngIf="details" class="table table-striped table-bordered">
            <tbody>
                <tr *ngFor="let property of fields">
                    <td class="text-medium">{{ getProperty(property) }}</td>
                    <td>
                        <ng-container *ngIf="property !== 'address'; else address">
                            {{ getValue(property) }}
                        </ng-container>
                        <ng-template #address>
                            <postal-address [address]="details.address"></postal-address>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-template #business>
        <table *ngIf="businessDetails" class="table table-striped table-bordered">
            <tbody>
                <tr *ngFor="let property of businessFields">
                    <td class="text-medium">{{ getProperty(property) }}</td>
                    <td>
                        <ng-container *ngIf="!['address', 'businessAddress'].includes(property)">
                            {{ getBusinessValue(property) }}
                        </ng-container>
                        <postal-address *ngIf="property === 'address'" [address]="businessDetails.address"></postal-address>
                        <postal-address *ngIf="property === 'businessAddress'" [address]="businessDetails.businessAddress"></postal-address>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-template>
</div>
