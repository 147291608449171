<div class="modal-header">
    <h5 class="modal-title">{{ 'common.session-expiration' | translate }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body text-center">
    <img src="/img/icons/refresh.svg" class="img-md my-3" />
    <h4 class="text-primary mb-3">{{ 'common.safety-measure' | translate }}</h4>
    <p>
        {{ 'common.session-about-to-expire' | translate }}
        <a href (click)="$event.preventDefault(); refresh(); activeModal.dismiss()">{{ 'common.keep-me-logged-in' | translate }}</a>
        {{ 'common.to' | translate | lowercase }} {{ 'common.extend-session' | translate }}
    </p>
</div>
<div class="modal-footer flex-column flex-md-row justify-content-center justify-content-md-end">
    <button class="btn btn-default btn-w-sm" (click)="logout()">{{ 'common.log-out' | translate }}</button>
    <button class="btn btn-primary btn-w-sm ms-1" (click)="refresh(); activeModal.dismiss()">{{ 'common.keep-me-logged-in' | translate }}</button>
</div>
