import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { StatusMessageComponent } from './status-message.component'
import { StatusMessageService } from './status-message.service'

@Component({
    selector: 'status-message-outlet',
    template: '',
})
/* This component is only an outlet for the status message, so we don't have to add logic to all clients */
export class StatusMessageOutletComponent implements OnInit {
    constructor(private statusMessage: StatusMessageService, private viewContainerRef: ViewContainerRef) {}

    public ngOnInit(): void {
        this.statusMessage.initializeListener(this.viewContainerRef, StatusMessageComponent)
    }
}
