import { Injectable } from '@angular/core'
import { User } from './session.service'
import { StorageService } from './storage.service'

@Injectable({
    providedIn: 'root',
})
export class AvatarService {
    private static readonly AVATAR_CACHE_KEY = 'avatar_last_cached_at'
    // use 1 by default to improve caching
    private _timestamp: number
    public set timestamp(value: string) {
        this._timestamp = ~~value
        this.storage.setItem(AvatarService.AVATAR_CACHE_KEY, this.timestamp)
    }
    public get timestamp(): string {
        return this._timestamp + ''
    }
    constructor(private storage: StorageService) {
        this.timestamp = this.storage.hasItem(AvatarService.AVATAR_CACHE_KEY)
            ? this.storage.getItem(AvatarService.AVATAR_CACHE_KEY)!
            : '1'
    }
    public reload(): void {
        this.timestamp = Date.now() + ''
    }
    public getAvatarUrl(user: Pick<User, 'id'>): string {
        return `/.api/users/${user.id}/avatar` + `?v=${this.timestamp}`
    }
}
