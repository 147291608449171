import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
} from '@angular/core'
import { ControlContainer, NgForm, NgModel } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import BigNumber from 'bignumber.js'
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { CurrenciesQuery } from '../../store/currencies/currencies.query'
import { WalletsQuery } from '../../store/wallets/wallets.query'
import { CurrencyType, ICurrency, IUser } from '../api-interfaces'
import { SessionService } from '../services/session.service'

@Component({
    selector: 'currency-select',
    templateUrl: 'currency-select.component.html',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (ngForm: NgForm) => ngForm,
            deps: [[new Optional(), new SkipSelf(), NgForm]],
        },
    ],
})
export class CurrencySelectComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
    @Input()
    public currency: ICurrency
    @Output()
    public readonly currencyChange = new EventEmitter<ICurrency>(true)
    @Output()
    public readonly currencySelectOpen = new EventEmitter<void>(true)
    @Input()
    public placeholder = `${this.translate.instant('common.select-currency')}`
    @Input()
    public disabled = false
    @Input()
    public required = false
    @Input()
    public user: IUser
    @Input()
    public isLarge = false
    @Input()
    public currencyTypes: CurrencyType[] = ['fiat', 'crypto']
    @Input()
    public depositable: boolean
    @Input()
    public holdable: boolean
    @Input()
    public transferable: boolean
    @Input()
    public filterBy: string[]
    @Input()
    public salesProceeds = false
    @ViewChild('currencyInput', { static: true })
    public currencyInput: NgModel
    @Input()
    public useFullName = false
    @Input()
    public hideZeroBalance = false

    /**
     * WL-specific flag. Will return first the currencies that are defined for the whitelabel.
     * See whitelabel.service.ts for the whitelabels and their priority currencies
     */
    @Input()
    public priorityCurrencies = false

    public layout = environment.layout
    public balances$: Observable<(ICurrency & { balance: string })[]>
    public preferredCurrency$: Observable<ICurrency>

    private criteria: BehaviorSubject<{
        currencyTypes: CurrencyType[]
        depositable: boolean
        holdable: boolean
        transferable: boolean
    }>

    private subscriptions = new Subscription()

    constructor(
        private currenciesQuery: CurrenciesQuery,
        private walletsQuery: WalletsQuery,
        private session: SessionService,
        public translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.criteria = new BehaviorSubject({
            currencyTypes: this.currencyTypes,
            depositable: this.depositable,
            holdable: this.holdable,
            transferable: this.transferable,
        })

        this.balances$ = combineLatest([
            this.priorityCurrencies
                ? this.currenciesQuery.selectAllByPermissionWithPriority()
                : this.currenciesQuery.selectAllByPermission(),
            this.walletsQuery.balances$,
            this.criteria,
        ]).pipe(
            map(([currencies, wallets, criteria]) => {
                let balances
                balances = currencies
                    .filter(currency => {
                        if (this.filterBy && !this.filterBy.includes(currency.code)) {
                            return false
                        }
                        let query = this.currencyTypes.includes(currency.type)
                        if (criteria.depositable !== undefined) {
                            query = query && currency.depositable === criteria.depositable
                        }
                        if (criteria.holdable !== undefined) {
                            query = query && currency.holdable === criteria.holdable
                        }
                        if (criteria.transferable !== undefined) {
                            query = query && currency.transferable === criteria.transferable
                        }
                        return query
                    })
                    .map(currency => {
                        const wallet = wallets.find(w => w.currency.code === currency.code)
                        const balance = (wallet && wallet.availableBalance) || 0
                        const salesProceeds = wallet
                            ? new BigNumber(wallet.usdValue).minus((wallet as any).topupAmount).toFixed(2, 1)
                            : 0
                        return {
                            ...currency,
                            balance: new BigNumber(balance).toFixed(currency.decimalPlaces, 1),
                            usdValue: wallet ? wallet.usdValue : '0.00',
                            value: wallet ? wallet.value : '0.00',
                            salesProceeds,
                        }
                    })
                    .filter(wallet => (this.hideZeroBalance ? !new BigNumber(wallet.balance).isZero() : true))

                if (!balances.length) {
                    balances = currencies
                        .filter(currency => currency.code === (this.currencyTypes.includes('fiat') ? 'USD' : 'BTC'))
                        .map(currency => ({
                            ...currency,
                            balance: '0.00',
                            usdValue: '0.00',
                            salesProceeds: '0.00',
                        }))

                    // This is for cases in certain WLs where they do not support USD / BTC
                    if (!balances.length) {
                        balances = currencies
                            .filter(
                                currency => currency.type === (this.currencyTypes.includes('fiat') ? 'fiat' : 'crypto')
                            )
                            .map(currency => ({
                                ...currency,
                                balance: '0.00',
                                usdValue: '0.00',
                                salesProceeds: '0.00',
                            }))
                    }
                }

                return balances
            })
        )

        this.preferredCurrency$ = this.currenciesQuery.preferredCurrency$
    }

    public ngOnChanges(): void {
        this.criteria?.next({
            currencyTypes: this.currencyTypes,
            depositable: this.depositable,
            holdable: this.holdable,
            transferable: this.transferable,
        })
    }

    public ngAfterContentInit(): void {
        this.subscriptions.add(
            this.balances$.subscribe(balances => {
                if (this.currency === undefined) {
                    this.currency =
                        balances.find(balance => balance.code === this.session.user.preferredCurrency.code) ||
                        balances.find(balance => balance.code === 'USD') ||
                        balances.find(balance => balance.code === 'BTC') ||
                        balances[0]
                    this.currencyChange.emit(this.currency)
                }
            })
        )
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public compareCurrencies(a: ICurrency, b: ICurrency): boolean {
        return a.code === b.code
    }
}
