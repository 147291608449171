import { Injectable } from '@angular/core'
import { faBell } from '@fortawesome/pro-light-svg-icons'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { Disposable } from '../disposable'

export enum AlertTag {
    VERIFICATION = 'verification',
}

export interface Alert {
    severity: string
    message: string
    icon?: IconDefinition
    tag?: AlertTag
    callToAction?: {
        label: string
        route: string
    }
}

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    public alerts: Alert[] = []

    public add(alert: Alert): Disposable {
        if (!alert.icon) {
            alert.icon = faBell
        }
        this.alerts.push(alert)
        return {
            dispose: () => {
                this.remove(alert)
            },
        }
    }

    public remove(target: Alert): void {
        const index = this.alerts.indexOf(target)
        if (index !== -1) {
            this.alerts.splice(index, 1)
        }
    }

    public clearByTag(tag: AlertTag): void {
        let index: number
        do {
            index = this.alerts.findIndex(alert => alert.tag === tag)
            if (index !== -1) {
                this.alerts.splice(index, 1)
            }
        } while (index !== -1)
    }

    public clear(): void {
        this.alerts = []
    }
}
