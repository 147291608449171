import { Injectable } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, of } from 'rxjs'
import { ConfirmPasswordModalComponent } from './confirm-password.modal'
import { SessionService, TokenScope } from '../services/session.service'

@Injectable({
    providedIn: 'root',
})
export class SecurityCheckService {
    constructor(private ngbModal: NgbModal, private session: SessionService) {}

    /**
     * Asks the user for permission to do something that requires a higher scope than basic
     * Resolves if the check was successful or the permission is still granted from another request
     * @param scope The required scope
     */
    public getScopePermission(scope: TokenScope, onSuccess?: () => void): Observable<void> {
        // Resolve if token has already the correct scope
        if (this.session.hasTokenScope(scope)) {
            if (onSuccess) {
                onSuccess()
            }
            return of(undefined)
        }
        const modal = this.ngbModal.open(ConfirmPasswordModalComponent, {
            windowClass: 'modal-primary security-check-modal',
        })
        ;(modal.componentInstance as ConfirmPasswordModalComponent).scope = scope
        return new Observable(observer => {
            modal.result
                .then(() => {
                    observer.next()
                    observer.complete()
                    if (onSuccess) {
                        onSuccess()
                    }
                })
                .catch(() => {
                    observer.complete()
                })
        })
    }
}
