import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { NotFoundComponent } from './app/not-found/not-found.component'

const routes: Routes = [
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
    },
    {
        path: 'mailbox',
        loadChildren: () => import('./mailbox/mailbox.module').then(mod => mod.MailboxModule),
    },
    {
        path: 'transactions',
        loadChildren: () => import('./transactions/transactions.module').then(mod => mod.TransactionsModule),
    },
    {
        path: 'services',
        loadChildren: () => import('./services/services.module').then(mod => mod.ServicesModule),
    },
    {
        path: 'cards',
        loadChildren: () => import('./user-cards/user-cards.module').then(mod => mod.UserCardsModule),
    },
    {
        path: 'discover',
        loadChildren: () => import('./discover/discover.module').then(mod => mod.DiscoverModule),
    },
    {
        path: 'deposit',
        loadChildren: () => import('./deposit/deposit.module').then(mod => mod.DepositModule),
    },
    {
        path: 'transfer',
        loadChildren: () => import('./transfer/transfer.module').then(mod => mod.TransferModule),
    },
    {
        path: 'withdraw',
        loadChildren: () => import('./withdraw/withdraw.module').then(mod => mod.WithdrawModule),
    },
    {
        path: 'spot-trading',
        loadChildren: () =>
            import('./user-spot-trading/user-spot-trading.module').then(mod => mod.UserSpotTradingModule),
    },
    {
        path: 'redeem',
        loadChildren: () => import('./user-redemption/user-redemption.module').then(mod => mod.UserRedemptionModule),
    },
    {
        path: 'buy-and-sell',
        loadChildren: () => import('./user-trades/user-trades.module').then(mod => mod.UserTradesModule),
    },
    {
        path: 'fees',
        loadChildren: () => import('./fees/fees.module').then(mod => mod.FeesModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule),
    },
    {
        path: 'register',
        loadChildren: () => import('./registration/registration.module').then(mod => mod.RegistrationModule),
    },
    {
        path: 'register',
        loadChildren: () => import('./kyc-wizard/kyc-wizard.module').then(mod => mod.KycWizardModule),
    },
    {
        path: 'privacy',
        loadChildren: () => import('./privacy/privacy.module').then(mod => mod.PrivacyModule),
    },
    {
        path: 'terms',
        loadChildren: () => import('./terms/terms.module').then(mod => mod.TermsModule),
    },
    {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(mod => mod.NotificationsModule),
    },
    {
        path: 'activities',
        loadChildren: () => import('./user-activities/user-activities.module').then(mod => mod.UserActivitiesModule),
    },
    {
        path: 'merchant',
        loadChildren: () => import('./merchant/merchant.module').then(mod => mod.MerchantModule),
    },
    {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(mod => mod.NewsModule),
    },
    {
        path: 'embedded',
        loadChildren: () => import('./embedded/embedded.module').then(mod => mod.EmbeddedModule),
    },
    {
        path: 'p',
        loadChildren: () => import('./payment/payment.module').then(mod => mod.PaymentModule),
    },
    {
        path: 'migration',
        loadChildren: () => import('./migration/migration.module').then(mod => mod.MigrationModule),
    },
    {
        path: 'signatory',
        loadChildren: () => import('./signatory/signatory.module').then(mod => mod.SignatoryModule),
    },
    {
        path: 'mastercard-private',
        loadChildren: () => import('./user-shops/user-shops.module').then(mod => mod.UserShopsModule),
    },
    // redirect / to /login
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    // cover all other states by 404 state
    {
        path: '**',
        component: NotFoundComponent,
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
