import { HttpClient } from '@angular/common/http'
import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import BigNumber from 'bignumber.js'
import { forkJoin, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { ICard, ICardProgram, IFee } from '../api-interfaces'
import { SessionService } from '../services/session.service'

interface NiumCardFeeSchedule {
    topupFee: string | null
    atmFee: string | null
    atmDeclineFee: string | null
    fx: string | null
    declineTransaction: string | null
    replacementFee: string | null
    ecomFee: string | null
    posFee: string | null
    annualLoadingLimit: string | null
    monthlySpendingLimit: string | null
    dailyPosEcomLimit: string | null
    dailyAtmLimit: string | null
    unloadFundsFee: string | null
}

interface SinopayCardFeeSchedule {
    topupFee: string
    withdrawalFee: string
    balanceInquiryFee: string
    renewalFee: string
    maxBalance: string
    minimumWithdrawal: string
    maximumWithdrawal: string
    dailyWithdrawalLimit: string
    monthlyWithdrawalLimit: string
    annualTopupLimit: string
    topupLimit: string
    allowedMonthlyTopup: string
}

enum NiumCardProgram {
    AERAPASS_P1 = '117cab0b-7992-4e4a-9581-1c241e77684d',
    AERAPASS_P2 = '4409785f-3df4-42af-bcf2-df1d23194872',
    AERAPASS_P3 = '5e4572d8-195c-4f3d-84da-6788c467543f',
    AERAPASS_P4 = '96e53529-3807-44f2-88cc-8514b8826819',
    AERAPASS_P5 = '172ba6d8-714b-45d2-9106-c2cc691af4d7',
    QNET = '198b1bf4-5b99-45c1-b7ee-2785c2d05e92',
    SGPMX_SILVER = '77b97782-788f-4102-b6bc-1607ce97b0c4',
    SGPMX_GOLD = '2f45a69f-5adb-4cab-b4d6-5af9a3cf4c8b',
}

@Component({
    selector: 'card-fee-schedule',
    templateUrl: 'card-fee-schedule.component.html',
})
export class CardFeeScheduleComponent implements OnInit {
    @Input()
    public card?: ICard
    public cardProgram: ICardProgram

    public niumCardFees: NiumCardFeeSchedule
    public sinopayCardFees: SinopayCardFeeSchedule

    private subscriptions = new Subscription()

    constructor(protected http: HttpClient, public session: SessionService, public translate: TranslateService) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.session.userStream
                .pipe(
                    switchMap(user =>
                        forkJoin<IFee[], ICardProgram | undefined>([
                            this.http.get<IFee[]>(`/users/${user.id}/fee-schedule`, {
                                params: {
                                    currencyCode: 'USD',
                                },
                            }),
                            this.http.get<ICardProgram>(
                                this.card
                                    ? `/cards/${this.card.id}/card-program`
                                    : `/users/${this.session.user.id}/card-program`
                            ),
                        ])
                    )
                )
                .subscribe(([fees, cardProgram]) => {
                    if (!cardProgram) {
                        return
                    }

                    this.cardProgram = cardProgram

                    const topup = fees.find(fee => fee.method === 'card' && fee.type === 'withdrawal') || null
                    const unload = fees.find(fee => fee.method === 'card' && fee.type === 'deposit') || null

                    if (cardProgram.provider === 'nium') {
                        this.niumCardFees = this.getNiumCardFeeSchedule(cardProgram, topup, unload)
                    } else {
                        this.sinopayCardFees = this.getSinopayFeeSchedule(topup)
                    }
                })
        )
    }

    public getNiumCardFeeSchedule(
        cardProgram: ICardProgram,
        topupFee: IFee | null,
        unloadFundsFee: IFee | null
    ): NiumCardFeeSchedule {
        const schedule: Record<keyof Pick<NiumCardFeeSchedule, 'topupFee' | 'unloadFundsFee'>, string> = {
            topupFee: this.determineFees(topupFee),
            unloadFundsFee: this.determineFees(unloadFundsFee),
        }
        switch (cardProgram?.config.niumClientId) {
            case NiumCardProgram.AERAPASS_P1:
                return {
                    ...schedule,
                    atmFee: '4',
                    atmDeclineFee: '0.5',
                    fx: '2.75',
                    declineTransaction: '0.25',
                    replacementFee: '10',
                    ecomFee: '0',
                    posFee: '0',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            case NiumCardProgram.AERAPASS_P2:
                return {
                    ...schedule,
                    atmFee: '8',
                    atmDeclineFee: '0.5',
                    fx: '2.75',
                    declineTransaction: '0.25',
                    replacementFee: '10',
                    ecomFee: '0.05',
                    posFee: '0.05',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            case NiumCardProgram.AERAPASS_P3:
                return {
                    ...schedule,
                    atmFee: '5',
                    atmDeclineFee: '0.5',
                    fx: '2.75',
                    declineTransaction: '0.25',
                    replacementFee: '5',
                    ecomFee: '0',
                    posFee: '0',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            case NiumCardProgram.AERAPASS_P4:
                return {
                    ...schedule,
                    atmFee: '10',
                    atmDeclineFee: '1',
                    fx: '1.25',
                    declineTransaction: '10',
                    replacementFee: '10',
                    ecomFee: '0',
                    posFee: '0',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            case NiumCardProgram.AERAPASS_P5:
                return {
                    ...schedule,
                    atmFee: '8',
                    atmDeclineFee: '0.5',
                    fx: '3.25',
                    declineTransaction: '0.25',
                    replacementFee: '10',
                    ecomFee: '0.25',
                    posFee: '0.25',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            case NiumCardProgram.QNET:
                return {
                    ...schedule,
                    atmFee: '5',
                    atmDeclineFee: '0.5',
                    fx: '2.00',
                    declineTransaction: '0',
                    replacementFee: '10',
                    ecomFee: '0',
                    posFee: '0',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            case NiumCardProgram.SGPMX_GOLD:
                return {
                    ...schedule,
                    atmFee: '5',
                    atmDeclineFee: '0.5',
                    fx: '2.95',
                    declineTransaction: '0.25',
                    replacementFee: '10',
                    ecomFee: '0',
                    posFee: '0',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            case NiumCardProgram.SGPMX_SILVER:
                return {
                    ...schedule,
                    atmFee: '5',
                    atmDeclineFee: '0.5',
                    fx: '3.50',
                    declineTransaction: '0.25',
                    replacementFee: '10',
                    ecomFee: '0',
                    posFee: '0',
                    annualLoadingLimit: cardProgram.maxCardBalance || null,
                    monthlySpendingLimit: '750000',
                    dailyPosEcomLimit: '25000',
                    dailyAtmLimit: '1500',
                }
            default:
                return {
                    ...schedule,
                    atmFee: null,
                    atmDeclineFee: null,
                    fx: null,
                    declineTransaction: null,
                    replacementFee: null,
                    ecomFee: null,
                    posFee: null,
                    annualLoadingLimit: null,
                    monthlySpendingLimit: null,
                    dailyPosEcomLimit: null,
                    dailyAtmLimit: null,
                }
        }
    }

    public getSinopayFeeSchedule(topupFee: IFee | null): SinopayCardFeeSchedule {
        return {
            topupFee: this.determineFees(topupFee),
            withdrawalFee: '2',
            balanceInquiryFee: '0.6',
            renewalFee: '4',
            maxBalance: '5000',
            minimumWithdrawal: '200',
            maximumWithdrawal: '1000',
            dailyWithdrawalLimit: '4000',
            monthlyWithdrawalLimit: '5000',
            annualTopupLimit: '30000',
            topupLimit: '5000',
            allowedMonthlyTopup: '5',
        }
    }

    private isZeroFee(fee: string): boolean {
        return new BigNumber(fee).isZero()
    }

    private determineFees(fee: IFee | null): string {
        let result = ''
        if (
            (fee && fee.fixed && !this.isZeroFee(fee.fixed || '0')) ||
            (fee && fee.relative && !this.isZeroFee(fee.relative))
        ) {
            if (fee.fixed && !this.isZeroFee(fee.fixed)) {
                result += new BigNumber(fee.fixed).toFixed(2) + ' USD'
            }
            if (fee.fixed && !this.isZeroFee(fee.fixed) && fee.relative && !this.isZeroFee(fee.relative)) {
                result += ' + '
            }
            if (fee.relative && !this.isZeroFee(fee.relative)) {
                result += new BigNumber(fee.relative).times(100).toFixed(2) + '%'
            }
        } else {
            result = this.translate.instant('common.free').toUpperCase()
        }
        return result
    }
}
