<ng-container *ngIf="session.userStream | async; let user">
    <div *ngIf="user.twoFactor">
        <p>
            <span class="label label-success">{{ 'common.enabled' | translate }}</span>
        </p>
    </div>
    <div *ngIf="!user.twoFactor && !twoFactor">
        <button
            type="button"
            class="btn btn-primary btn-w-sm e2e-generate-2fa-secret"
            (click)="generate2FASecret()"
            [ngClass]="{ loading: isLoading }"
            [disabled]="isLoading"
        >
            {{ 'common.enable-2fa' | translate }}
        </button>
    </div>
    <form
        name="twoFactor"
        #verificationForm="ngForm"
        class="form-validation"
        (ngSubmit)="toggleTwoFactorAuth(true)"
        autocomplete="false"
        novalidate
        *ngIf="(!user.twoFactor && !!twoFactor) || user.twoFactor"
    >
        <div *ngIf="!user.twoFactor">
            <img [src]="twoFactor!.qrCode" class="mx-auto d-block img-thumbnail p-0 mb-2 e2e-2fa-qr" />
            <p class="text-center e2e-2fa-secret">
                {{ 'common.or-enter-this-code' | translate }} <b class="text-info">{{ twoFactor!.secret }}</b>
            </p>
            <div class="alert alert-info">
                {{ 'common.scan-this-code-with-your-auth-app' | translate }}
            </div>
            <p class="mb-4 text-center">{{ 'common.enter-six-digit-code' | translate }}</p>
            <div class="d-flex justify-content-center mb-5">
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button *ngIf="!user.twoFactor" type="submit" class="btn btn-primary btn-w-sm" [ngClass]="{ loading: isLoading }" [disabled]="isLoading">
                <span>{{ 'common.verify-code' | translate }}</span>
            </button>
        </div>
    </form>
</ng-container>
