import { HttpClient, HttpParams } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { combineLatest, Subject, Subscription } from 'rxjs'
import { debounceTime, switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import * as striptags from 'striptags'
import { Mail, Paginated } from '../../../../common/api-interfaces'
import { MailNotificationsService } from '../../../../common/services/mail-notifications.service'
import { SessionService } from '../../../../common/services/session.service'

@Component({
    selector: 'header-mailbox',
    templateUrl: 'header-mailbox.component.html',
})
export class HeaderMailboxComponent implements OnInit, OnDestroy {
    public striptags = striptags
    public faEnvelope = faEnvelope
    public mails: Mail[] = []
    public count = 0
    public displayName = environment.displayName
    private subscriptions = new Subscription()
    private fetchEvent = new Subject<void>()

    constructor(
        public session: SessionService,
        private http: HttpClient,
        public router: Router,
        private mailNotifications: MailNotificationsService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            combineLatest(this.fetchEvent, this.session.userStream)
                .pipe(
                    debounceTime(600),
                    switchMap(([_, user]) =>
                        this.http.get<Paginated<Mail>>(`/users/${user.id}/mails`, {
                            params: new HttpParams()
                                .set('limit', 8 + '')
                                .set('offset', 0 + '')
                                .set('filter.read', 'false'),
                        })
                    )
                )
                .subscribe(({ data, meta }) => {
                    this.mails = data
                    this.count = meta.count
                })
        )
        this.subscriptions.add(this.mailNotifications.onRead.subscribe(() => this.fetchEvent.next()))
        this.fetchEvent.next()
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
