import { Directive, ElementRef, HostListener, OnChanges, Renderer2 } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Directive({
    selector: '[emptyToNull]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: EmptyToNullDirective,
            multi: true,
        },
    ],
})
export class EmptyToNullDirective implements ControlValueAccessor, OnChanges {
    private lastValue: any

    constructor(private renderer: Renderer2, private element: ElementRef) {}

    public ngOnChanges(): void {
        // nothing to do
    }

    public writeValue(value: any): void {
        const normalizedValue = value === null || value === undefined ? '' : value
        this.renderer.setProperty(this.element.nativeElement, 'value', normalizedValue)
    }

    @HostListener('input', ['$event.target.value'])
    public onInput(value: any): void {
        if (this.lastValue !== value) {
            this.lastValue = value
            this.renderer.setProperty(this.element.nativeElement, 'value', value)
            this._onChange(value === '' ? null : value)
        }
    }

    public registerOnChange(fn: (value: any) => any): void {
        this._onChange = fn
    }

    public registerOnTouched(): void {
        // nothing to do here
    }

    public setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled)
    }

    private _onChange = (_: any) => {
        // to be initialized
    }
}
