import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms'

@Directive({
    selector: '[number][formControlName],[number][formControl],[number][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: NumberValidatorDirective,
            multi: true,
        },
    ],
})
export class NumberValidatorDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const required = Validators.required(control)
        if (required !== undefined && required !== null) {
            return null
        }
        // eslint-disable-next-line id-blacklist
        return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(control.value) ? null : { number: true }
    }
}
