import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import * as Color from 'color'
import { environment } from 'src/environments/environment'
import { IUser } from '../api-interfaces'
import { AvatarService } from '../services/avatar.service'
import { WhitelabelService } from '../services/core/whitelabel.service'

@Component({
    selector: 'user-avatar',
    templateUrl: 'user-avatar.component.html',
})
export class UserAvatarComponent implements OnChanges {
    @Input()
    public user?: IUser | null
    @Input()
    public size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined
    @Input()
    public online = false
    @Input()
    public border = false
    @Input()
    public elementClass = ''

    public appName = environment.appName
    public appInitials = this.whitelabelService.getDisplayName().slice(0, 2).toUpperCase()

    private _primaryColor: string
    private _successColor: string

    constructor(private avatarService: AvatarService, private whitelabelService: WhitelabelService) {}

    public get primaryColor(): string {
        if (!this._primaryColor) {
            this._primaryColor = Color(getComputedStyle(document.body).getPropertyValue('--bs-primary').trim()).hex()
        }
        return this._primaryColor
    }
    public get successColor(): string {
        if (!this._successColor) {
            this._successColor = Color(getComputedStyle(document.body).getPropertyValue('--bs-success').trim()).hex()
        }
        return this._successColor
    }

    public get avatarUrl(): string {
        return `/.api/users/${this.user?.id}/avatar?v=${this.avatarService.timestamp}`
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.user) {
            this.user = changes.user.currentValue
        }
    }

    public get styles(): string {
        return (this.border ? 'avatar-border ' : '') + (this.size ? `avatar-${this.size} ` : '') + this.elementClass
    }
}
