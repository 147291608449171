import { Pipe, PipeTransform } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'

@Pipe({ name: 'formatDistanceToNow' })
export class FormatDistanceToNowPipe implements PipeTransform {
    public transform(value: any, includeSeconds = false, addSuffix = false): SafeUrl {
        if (typeof value !== 'string') {
            return value
        }
        return formatDistanceToNow(parseISO(value), { includeSeconds, addSuffix })
    }
}
