<form #beneficiaryDetailsForm="ngForm" [ngClass]="{ 'ng-submitted': submitted }" class="form-validation" novalidate>
    <div class="pt-2 mb-1"></div>
    <div class="d-flex align-items-center">
        <h6 class="text-primary pe-1">{{ 'common.beneficiary' | translate }}</h6>
        <hr class="w-100 m-0 pb-2" />
    </div>
    <ng-container *ngIf="beneficiary.method !== 'crypto'">
        <div class="form-group">
            <label [ngClass]="{ 'font-md': isLarge }" for="accountHolder">{{ 'common.account-name' | translate }}</label>
            <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-lg': isLarge }"
                id="accountHolder"
                name="accountHolder"
                [(ngModel)]="beneficiary.accountHolder"
                emptyToNull
                #accountHolder="ngModel"
                [disabled]="disabled"
                required
            />
            <div *ngIf="accountHolder.invalid && (submitted || accountHolder.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="accountHolder?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
            </div>
        </div>
    </ng-container>
    <div class="form-group">
        <label [ngClass]="{ 'font-md': isLarge }" for="accountNumber">
            {{ getAccountNumberLabel() }}
        </label>
        <input
            type="text"
            class="form-control"
            [ngClass]="{ 'form-control-lg': isLarge }"
            id="accountNumber"
            name="accountNumber"
            [(ngModel)]="beneficiary.accountNumber"
            emptyToNull
            #accountNumber="ngModel"
            [disabled]="disabled"
            required
        />
        <div *ngIf="accountNumber.invalid && (submitted || accountNumber.dirty)" class="error-messages">
            <div class="form-control-feedback" *ngIf="accountNumber?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
        </div>
    </div>
    <ng-container *ngIf="beneficiary.method === 'crypto'">
        <div class="form-group">
            <label [ngClass]="{ 'font-md': isLarge }" for="accountHolder">
                {{ 'common.wallet-label' | translate }}
                <small class="text-muted">({{ 'common.optional' | translate }})</small>
            </label>
            <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-lg': isLarge }"
                id="accountHolder"
                name="accountHolder"
                [(ngModel)]="beneficiary.accountHolder"
                emptyToNull
                #accountHolder="ngModel"
                [disabled]="disabled"
            />
        </div>
    </ng-container>
    <ng-container *ngIf="beneficiary.method === 'swift' || beneficiary.method === 'local'">
        <ng-container *ngIf="!hideAddressForm">
            <div class="pt-2 mb-1"></div>
            <div class="d-flex align-items-center">
                <h6 class="text-primary pe-1 text-nowrap">{{ 'beneficiary-details-form.beneficiary-address' | translate | titlecase }}</h6>
                <hr class="w-100 m-0 pb-2" />
            </div>
            <address-form
                [(address)]="beneficiary.accountHolderAddress"
                [isLarge]="isLarge"
                [requireAll]="requireAll"
                [disabled]="disabled"
                prefix="accountHolder"
                #accountHolderAddressForm
            ></address-form>
        </ng-container>
        <div class="pt-2 mb-1"></div>
        <div class="d-flex align-items-center">
            <h6 class="text-primary pe-1 text-nowrap">{{ 'common.beneficiary-bank' | translate }}</h6>
            <hr class="w-100 m-0 pb-2" />
        </div>
        <div class="form-group">
            <label [ngClass]="{ 'font-md': isLarge }" for="bankName">{{ 'common.name' | translate }}</label>
            <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-lg': isLarge }"
                id="bankName"
                name="bankName"
                [(ngModel)]="beneficiary.bankName"
                emptyToNull
                #bankName="ngModel"
                [disabled]="disabled"
                required
            />
            <div *ngIf="bankName.invalid && (submitted || bankName.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="bankName?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
            </div>
        </div>
        <div class="form-group" *ngIf="showSwift()">
            <label [ngClass]="{ 'font-md': isLarge }" for="bankSwiftCode">
                {{ getSwiftLabel() }} <small class="text-muted" *ngIf="!isIntermediateBankCollapsed">({{ 'common.optional' | translate }})</small>
            </label>
            <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-lg': isLarge }"
                id="bankSwiftCode"
                name="bankSwiftCode"
                [(ngModel)]="beneficiary.bankSwiftCode"
                emptyToNull
                #bankSwiftCode="ngModel"
                [disabled]="disabled"
                [required]="isIntermediateBankCollapsed"
            />
            <div *ngIf="bankSwiftCode.invalid && (submitted || bankSwiftCode.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="bankSwiftCode?.errors?.required"></div>
            </div>
        </div>
        <div class="form-group" *ngIf="showRoutingNumber()">
            <label [ngClass]="{ 'font-md': isLarge }" for="routingNumber">{{ getRoutingNumberLabel() }}</label>
            <input
                type="text"
                class="form-control"
                [ngClass]="{ 'form-control-lg': isLarge }"
                id="routingNumber"
                name="routingNumber"
                [(ngModel)]="beneficiary.routingNumber"
                emptyToNull
                #routingNumber="ngModel"
                [disabled]="disabled"
                required
            />
            <div *ngIf="routingNumber.invalid && (submitted || routingNumber.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="routingNumber?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
            </div>
        </div>
        <ng-container *ngIf="!hideAddressForm">
            <div class="pt-2 mb-1"></div>
            <div class="d-flex align-items-center">
                <h6 class="text-primary pe-1 text-nowrap">{{ 'beneficiary-details-form.beneficiary-bank-address' | translate }}</h6>
                <hr class="w-100 m-0 pb-2" />
            </div>
            <address-form
                [(address)]="beneficiary.bankAddress"
                [isLarge]="isLarge"
                [requireAll]="requireAll"
                [disabled]="disabled"
                prefix="bank"
                #bankAddressForm
            ></address-form>
        </ng-container>
        <div class="form-group" *ngIf="beneficiary.method === 'swift'">
            <a
                href
                class="d-block"
                (click)="
                    isIntermediateBankCollapsed = !isIntermediateBankCollapsed;
                    beneficiary.intermediateBankName = null;
                    beneficiary.intermediateBankSwiftCode = null;
                    beneficiary.intermediateBankAddress = null;
                    $event.preventDefault()
                "
                >{{ isIntermediateBankCollapsed ? '+ ' + ('common.add' | translate) : '-' + ('common.remove' | translate) }}
                {{ 'common.intermediate-bank' | translate | lowercase }}</a
            >
        </div>
        <ng-container *ngIf="!isIntermediateBankCollapsed">
            <div class="pt-2 mb-1"></div>
            <div class="d-flex align-items-center">
                <h6 class="text-primary pe-1 text-nowrap">{{ 'common.intermediate-bank' | translate }}</h6>
                <hr class="w-100 m-0 pb-2" />
            </div>
            <div class="form-group">
                <label [ngClass]="{ 'font-md': isLarge }" for="intermediateBankName">{{ 'common.name' | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    [ngClass]="{ 'form-control-lg': isLarge }"
                    id="intermediateBankName"
                    name="intermediateBankName"
                    [(ngModel)]="beneficiary.intermediateBankName"
                    emptyToNull
                    #intermediateBankName="ngModel"
                    [disabled]="disabled"
                    required
                />
                <div *ngIf="intermediateBankName.invalid && (submitted || intermediateBankName.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="intermediateBankName?.errors?.required">
                        {{ 'common.this-is-required' | translate }}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label [ngClass]="{ 'font-md': isLarge }" for="intermediateBankSwiftCode">{{ 'common.swift-code' | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    [ngClass]="{ 'form-control-lg': isLarge }"
                    id="intermediateBankSwiftCode"
                    name="intermediateBankSwiftCode"
                    [(ngModel)]="beneficiary.intermediateBankSwiftCode"
                    emptyToNull
                    #intermediateBankSwiftCode="ngModel"
                    [disabled]="disabled"
                    required
                />
                <div *ngIf="intermediateBankSwiftCode.invalid && (submitted || intermediateBankSwiftCode.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="intermediateBankSwiftCode?.errors?.required">
                        {{ 'common.this-is-required' | translate }}
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!hideAddressForm">
                <div class="pt-2 mb-1"></div>
                <div class="d-flex align-items-center">
                    <h6 class="text-primary pe-1 text-nowrap">{{ 'common.intermediate-bank' | translate }} {{ 'common.address' | translate }}</h6>
                    <hr class="w-100 m-0 pb-2" />
                </div>
                <address-form
                    [(address)]="beneficiary.intermediateBankAddress"
                    [isLarge]="isLarge"
                    [requireAll]="requireAll"
                    [disabled]="disabled"
                    prefix="intermediateBank"
                    #intermediateBankAddressForm
                ></address-form>
            </ng-container>
        </ng-container>
    </ng-container>
</form>
