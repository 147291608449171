import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { finalize, flatMap, tap } from 'rxjs/operators'
import { RPCResult, TwoFactorRPCResult } from '../../../common/api-interfaces'
import { SecurityCheckService } from '../../../common/security-check/security-check.service'
import { UserService } from '../../../common/services/core/user.service'
import { SessionService } from '../../../common/services/session.service'
import { TokenService } from '../../../common/services/token.service'

@Component({
    selector: 'two-factor',
    templateUrl: 'two-factor.component.html',
})
export class TwoFactorComponent implements OnDestroy {
    @ViewChild('verificationForm')
    public verificationForm: NgForm
    public twoFactor: TwoFactorRPCResult | null
    public verificationCode: string

    public isLoading = false

    @Output()
    public readonly onSuccess = new EventEmitter<void>()

    private subcriptions = new Subscription()

    constructor(
        public session: SessionService,
        private http: HttpClient,
        private securityCheckService: SecurityCheckService,
        private tokenService: TokenService,
        private userService: UserService
    ) {}

    public ngOnDestroy(): void {
        this.subcriptions.unsubscribe()
    }

    public generate2FASecret(): void {
        this.isLoading = true
        this.subcriptions.add(
            this.http
                .post<RPCResult<TwoFactorRPCResult>>(
                    `/users/${this.session.user.id}`,
                    {
                        id: Math.floor(Math.random() * 10000),
                        jsonrpc: '2.0',
                        method: 'generate2FASecret',
                    },
                    {
                        headers: new HttpHeaders({ 'Content-Type': 'application/json-rpc' }),
                    }
                )
                .pipe(
                    finalize(() => {
                        this.isLoading = false
                    })
                )
                .subscribe(response => {
                    this.twoFactor = response.result
                })
        )
    }

    public toggleTwoFactorAuth(status: boolean): void {
        this.subcriptions.add(
            this.securityCheckService
                .getScopePermission('securitySettings')
                .pipe(
                    tap(() => {
                        this.isLoading = true
                    }),
                    flatMap(() =>
                        this.userService.changeTwoFactor(
                            this.session.user.id,
                            status,
                            this.verificationCode
                                ? {
                                      'X-2FA-Code': this.verificationCode?.toString(),
                                  }
                                : {}
                        )
                    ),
                    flatMap(() => this.tokenService.refresh()),
                    finalize(() => {
                        this.isLoading = false
                    })
                )
                .subscribe(() => {
                    this.verificationCode = ''
                    this.twoFactor = null
                    this.onSuccess.emit()
                })
        )
    }

    public onOtpChange(value: any): void {
        this.verificationCode = value

        if (this.verificationCode.length === 6) {
            this.toggleTwoFactorAuth(true)
        }
    }
}
