<ng-container *ngIf="user === undefined; else userTemplate">
    <ng-container *ngTemplateOutlet="mint"></ng-container>
</ng-container>
<ng-template #userTemplate>
    <ng-container *ngIf="user; else platform">
        <div class="avatar" [ngClass]="styles">
            <img [src]="avatarUrl | safeResourceUrl" class="img-avatar" />
            <span class="avatar-status bg-success" *ngIf="online"></span>
        </div>
    </ng-container>
</ng-template>
<ng-template #mint>
    <!-- htmllint attr-bans="[]" -->
    <div class="avatar" [ngClass]="styles">
        <svg
            class="img-avatar"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            text-rendering="optimizeLegibility"
            style="
                -webkit-font-smoothing: auto;
                shape-rendering: geometricPrecision;
                image-rendering: optimizeQuality;
                fill-rule: evenodd;
                clip-rule: evenodd;
            "
            viewBox="0 0 120 120"
        >
            <g>
                <rect [attr.fill]="successColor" width="120" height="120"></rect>
                <text font-weight="300" font-size="3.125em" font-family="sans-serif" fill="#ffffff" x="50%" y="50%" text-anchor="middle" dy="0.35em">
                    MI
                </text>
            </g>
        </svg>
    </div>
    <!-- htmllint attr-bans="$previous" -->
</ng-template>
<ng-template #platform>
    <!-- htmllint attr-bans="[]" -->
    <div class="avatar" [ngClass]="styles">
        <svg
            class="img-avatar"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            text-rendering="optimizeLegibility"
            style="
                -webkit-font-smoothing: auto;
                shape-rendering: geometricPrecision;
                image-rendering: optimizeQuality;
                fill-rule: evenodd;
                clip-rule: evenodd;
            "
            viewBox="0 0 120 120"
        >
            <g>
                <rect [attr.fill]="primaryColor" width="120" height="120"></rect>
                <text font-weight="300" font-size="3.125em" font-family="sans-serif" fill="#ffffff" x="50%" y="50%" text-anchor="middle" dy="0.35em">
                    {{ appInitials }}
                </text>
            </g>
        </svg>
    </div>
    <!-- htmllint attr-bans="$previous" -->
</ng-template>
