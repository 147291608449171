import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core'

@Directive({
    selector: '[maxFileSize]',
})
export class MaxFileSizeDirective {
    @Input('maxFileSize') maxSizeInMB = 10 // Default to 10 MB
    @Output() validFileChange = new EventEmitter<File>()

    constructor(private el: ElementRef) {}

    @HostListener('change', ['$event'])
    public onChange(_event: Event): void {
        const input = this.el.nativeElement as HTMLInputElement
        if (input.files && input.files.length > 0) {
            const file = input.files[0]
            const maxSizeInBytes = this.maxSizeInMB * 1024 * 1024

            if (file.size <= maxSizeInBytes) {
                this.validFileChange.emit(file)
            } else {
                alert(`File size exceeds ${this.maxSizeInMB} MB.`)
                input.value = '' // Clear the input
            }
        }
    }
}
