import { isPlatformBrowser } from '@angular/common'
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { environment } from 'src/environments/environment'
import { ConfirmationService } from '../common/services/confirmation.service'
import { SessionService, UserEventType } from '../common/services/session.service'

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    private sentryHub: Sentry.Hub
    constructor(
        session: SessionService,
        @Inject(PLATFORM_ID) private platformId: Record<string, any>,
        private injector: Injector
    ) {
        if (isPlatformBrowser(platformId) && window.SENTRY_DSN) {
            this.sentryHub = new Sentry.Hub(
                new Sentry.BrowserClient({
                    dsn: window.SENTRY_DSN,
                    debug: false,
                    release: '1.0.0',
                    environment: environment.appName,
                    integrations: [new Integrations.Dedupe()],
                })
            )
            session.userEvent.subscribe(event => {
                this.sentryHub.configureScope(scope => {
                    if (event.type === UserEventType.LOGIN) {
                        scope.setUser({
                            id: event.user.id,
                            email: event.user.email,
                            username: event.user.accountNumber,
                        })
                    } else if (event.type === UserEventType.LOGOUT) {
                        scope.setUser(null)
                    }
                })
            })
        }
    }
    public handleError(error: Error): void {
        if ((error as any).caughtByInterceptor) {
            // silent
            return
        }
        // eslint-disable-next-line no-console
        console.error(error)
        if (!isPlatformBrowser(this.platformId)) {
            return
        }
        // Reload webpage on chunk loading error when browsing with outdated client
        const chunkFailedMessage = /Loading chunk [\d]+ failed/
        if (chunkFailedMessage.test(error.message)) {
            const confirmation = this.injector.get(ConfirmationService)
            confirmation
                .show({
                    type: 'danger',
                    text: 'It seems that you are using an outdated version of our website. Please reload to continue browsing our shiny new one.',
                    title: 'New version detected',
                    showCancel: false,
                    confirmText: 'Reload',
                    confirmClass: 'danger',
                })
                .subscribe(() => {
                    window.location.reload()
                })
            return
        }
        if (this.sentryHub) {
            this.sentryHub.captureException((error as any).originalError || error)
        }
    }
}
