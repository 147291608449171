import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { NoAccessError } from '../no-access-modal/no-access-modal.component'
import { UserService } from '../services/core/user.service'
import { SessionService } from '../services/session.service'
import { ToastrService } from '../services/toastr.service'

@Component({
    selector: 'access-errors',
    templateUrl: 'access-errors.component.html',
})
export class AccessErrorsComponent implements OnInit {
    public resendTriggered = false
    @Input()
    public errors: NoAccessError[] = []
    public whiteListedErrors: NoAccessError[]
    private subscriptions = new Subscription()

    constructor(
        public session: SessionService,
        private router: Router,
        private userService: UserService,
        private toastr: ToastrService,
        public translate: TranslateService
    ) {}

    public ngOnInit(): void {
        if (this.errors.length > 0) {
            this.whiteListedErrors = this.errors.filter(error => this.session.user.accessErrors.includes(error))
        } else {
            this.whiteListedErrors = this.session.user.accessErrors
        }
    }

    public async goToVerification(): Promise<void> {
        await this.router.navigate(['register', 'identity-verification'])
    }

    public async goToRequest(): Promise<void> {
        await this.router.navigate(['/deposit'])
    }

    public resend(): void {
        this.resendTriggered = true
        this.subscriptions.add(
            this.userService.resendOTPMail(this.session.user.id).subscribe(
                () => {
                    this.toastr.success(this.translate.instant('common.mail-confirmation-sent-again'))
                },
                () => {
                    this.resendTriggered = false
                }
            )
        )
    }
}
