import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { UserService } from '../services/core/user.service'
import { SessionService } from '../services/session.service'
import { ToastrService } from '../services/toastr.service'

export type NoAccessError = 'VerificationRequiredError' | 'AccountFrozenError'

@Component({
    selector: 'no-access-modal',
    templateUrl: 'no-access-modal.component.html',
})
export class NoAccessModalComponent {
    public faExclamationCircle = faExclamationCircle
    public error: NoAccessError
    public resendTriggered = false
    private subscriptions = new Subscription()

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private userService: UserService,
        private session: SessionService,
        private toastr: ToastrService,
        public translate: TranslateService
    ) {}

    public async goToVerification(): Promise<void> {
        this.activeModal.dismiss()
        await this.router.navigate(['register', 'identity-verification'])
    }

    public async goToRequest(): Promise<void> {
        this.activeModal.close()
        await this.router.navigate(['/deposit'])
    }

    public resend(): void {
        this.resendTriggered = true
        this.subscriptions.add(
            this.userService.resendOTPMail(this.session.user.id).subscribe(
                () => {
                    this.toastr.success(this.translate.instant('common.mail-confirmation-sent-again'))
                },
                () => {
                    this.resendTriggered = false
                }
            )
        )
    }
}

export function openNoAccessModal(ngbModal: NgbModal, error: NoAccessError): NgbModalRef {
    const modal = ngbModal.open(NoAccessModalComponent, {
        windowClass: 'modal-primary',
    })
    modal.componentInstance.error = error
    return modal
}
