import { Pipe, PipeTransform } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import { toStartCase } from './common.mixin'

@Pipe({ name: 'startCase' })
export class StartCasePipe implements PipeTransform {
    public transform(value: any): SafeUrl {
        if (typeof value !== 'string') {
            return value
        }
        return toStartCase(value)
    }
}
