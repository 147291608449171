<div class="card">
    <div class="card-header">{{ 'common.activities' | translate }}</div>
    <div class="card-body overflow-auto">
        <div class="row no-gutters align-items-center mb-3">
            <div class="col-lg-7 mb-2 mb-lg-0">
                <div class="input-group">
                    <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
                    <input
                        type="text"
                        name="searchText"
                        (ngModelChange)="searchEvent.next()"
                        [placeholder]="'common.search' | translate"
                        class="form-control"
                        [(ngModel)]="search"
                    />
                </div>
            </div>
            <div class="col-lg-17 d-flex justify-content-between align-items-center">
                <div class="ms-lg-3">
                    <span>{{ count | number }} {{ (count === 1 ? 'common.entry-found' : 'common.entries-found') | translate | lowercase }}</span>
                    <span *ngIf="!compact" class="ms-3">
                        <a href (click)="isFilterOptionsCollapsed = !isFilterOptionsCollapsed; $event.preventDefault()">
                            {{ isFilterOptionsCollapsed ? '+' : '-' }} {{ 'common.filter-options' | translate }}
                        </a>
                    </span>
                </div>
                <div class="d-flex align-items-center">
                    <ng-select
                        [ngClass]="{ 'me-2': !compact }"
                        [(ngModel)]="limit"
                        dropdownPosition="auto"
                        (change)="select(1)"
                        [items]="limitOpts"
                        [placeholder]="'common.show-entries-per-page' | translate"
                        [clearable]="false"
                        [searchable]="false"
                        required
                    >
                        <ng-template ng-label-tmp let-item="item">{{ 'common.show-entries' | translate : { value: item } }}</ng-template>
                        <ng-template ng-option-tmp let-item="item">{{ item }} {{ 'common.entries' | translate }}</ng-template>
                    </ng-select>
                    <ngb-pagination
                        *ngIf="!compact"
                        [(page)]="page"
                        [collectionSize]="count"
                        (pageChange)="select()"
                        [pageSize]="limit"
                        [maxSize]="5"
                        [ellipses]="false"
                        [rotate]="true"
                        [boundaryLinks]="true"
                    >
                    </ngb-pagination>
                </div>
            </div>
        </div>
        <div *ngIf="!compact" class="card" [ngbCollapse]="isFilterOptionsCollapsed">
            <div class="card-body" #filterCardBody>
                <div class="row">
                    <div
                        [ngClass]="{
                            'col-xl-8': filterCardBody.offsetWidth > 600,
                            'col-24': filterCardBody.offsetWidth < 600
                        }"
                    >
                        <div class="form-group row">
                            <label for="method" class="col-sm-6 col-form-label text-body">{{ 'common.type' | translate }}</label>
                            <div class="col-sm-18">
                                <ng-select
                                    id="type"
                                    name="type"
                                    [items]="types"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    [searchable]="false"
                                    [placeholder]="'common.filter-by-type' | translate"
                                    [ngModel]="queryParams['type']['in']"
                                    (ngModelChange)="queryParams['type']['in'] = $event; searchEvent.next()"
                                >
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                        <input class="me-2" [id]="'item-' + index" type="checkbox" [ngModel]="item$.selected" />
                                        <span>{{ item | startCase }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-group list-group-flush mb-3 d-table w-100">
            <div
                class="list-group-item d-table-row b-b-1"
                *ngFor="let activity of itemsStream | async"
                [ngClass]="'list-group-item-' + activity.severity"
            >
                <span class="d-table-cell p-2 w-100 b-b-1">
                    <activity
                        [activity]="activity"
                        (click)="$event.preventDefault(); activeActivity === activity ? (activeActivity = undefined) : (activeActivity = activity)"
                    ></activity>
                    <div class="py-2" *ngIf="activeActivity && activeActivity === activity">
                        <div class="mb-1"><b>IP Address:</b><br />{{ activeActivity.ipAddress || 'Not present' }}</div>
                        <div class="mb-1"><b>Origin:</b><br />{{ activeActivity.origin || 'Not present' }}</div>
                        <div class="mb-1">
                            <b>{{ 'common.referrer' | translate }}:</b><br />{{ activeActivity.referrer || 'Not present' }}
                        </div>
                        <div class="mb-1">
                            <b>OS:</b><br />{{ activeActivity.parsedUserAgent?.os?.name }} {{ activeActivity.parsedUserAgent?.os?.version }}
                        </div>
                        <div class="mb-1">
                            <b>Browser:</b><br />{{ activeActivity.parsedUserAgent?.browser?.name }}
                            {{ activeActivity.parsedUserAgent?.browser?.major }}
                        </div>
                        <pre class="text-start font-sm hljs mb-0" *ngIf="activity.dataHtml" [innerHTML]="activity.dataHtml"></pre>
                    </div>
                </span>
                <span class="d-table-cell py-2 px-1 text-nowrap b-b-1"
                    ><ng-container *ngTemplateOutlet="userTemplate; context: { user: activity.actor }"></ng-container
                ></span>
                <span class="d-table-cell p-2 text-nowrap b-b-1">{{ activity.createdAt | date : 'MMM dd, hh:mm:ss a' }}</span>
            </div>
        </div>
        <ng-container *ngIf="compact">
            <div class="row">
                <div class="col d-flex justify-content-end">
                    <ngb-pagination
                        [(page)]="page"
                        [collectionSize]="count"
                        (pageChange)="select()"
                        [pageSize]="limit"
                        [maxSize]="5"
                        [ellipses]="false"
                        [rotate]="true"
                        [boundaryLinks]="true"
                    >
                    </ngb-pagination>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<ng-template let-user="user" #userTemplate>
    <a
        [routerLink]="['/admin/accounts/users', user?.id || undefined]"
        (click)="$event.stopPropagation()"
        [ngbTooltip]="user?.accountNumber | accountNumber"
        *ngIf="user; else unknown"
        >{{ user?.name }}</a
    >
    <ng-template #unknown>Unknown</ng-template>
</ng-template>
