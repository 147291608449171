import { HttpClient } from '@angular/common/http'
import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef, ViewRef } from '@angular/core'
import { interval, merge, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { Setting } from '../../common/api-interfaces'

@Injectable()
export class StatusMessageService {
    public message: string | undefined

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private http: HttpClient) {}

    public initializeListener(viewContainerRef: ViewContainerRef, statusMessageComponent: Type<any>): void {
        let insertedViewRef: ViewRef | undefined
        merge(of(undefined), interval(120 * 1000))
            .pipe(
                switchMap(() =>
                    this.http
                        .get<Setting>('/settings/status-message', {
                            headers: { 'X-Ignore-Error-Handling': 'true' },
                        })
                        .pipe(
                            // ignore errors
                            catchError(() => []),
                            map(setting => setting && (setting.value as { message: string; expiryDate: string }))
                        )
                )
            )
            .subscribe(statusMessage => {
                if (!statusMessage || this.isExpired(statusMessage.expiryDate)) {
                    if (insertedViewRef) {
                        insertedViewRef.destroy()
                        insertedViewRef = undefined
                    }
                    this.message = undefined
                    return
                }

                if (this.message === statusMessage.message) {
                    // no change in status
                    return
                }

                const wasEmpty = !this.message
                this.message = statusMessage.message

                if (wasEmpty && !!this.message) {
                    // inject status message to given outlet, we have the <status-message-outlet> component therefore
                    const componentFactory =
                        this.componentFactoryResolver.resolveComponentFactory(statusMessageComponent)
                    const component = componentFactory.create(viewContainerRef.parentInjector)
                    // insert into the outlet, to maintain DOM structure
                    insertedViewRef = viewContainerRef.insert(component.hostView)
                } else if (!this.message && insertedViewRef) {
                    insertedViewRef.destroy()
                    insertedViewRef = undefined
                }
            })
    }

    private isExpired(expiryDate: string): boolean {
        const expiry = new Date(expiryDate)
        const now = new Date()
        return now > expiry
    }
}
