import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Operation } from 'fast-json-patch'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { ICurrency, IUser, Risk, RPCResult, UserFlag, UserRole, UserSegment } from '../../api-interfaces'
import { User } from '../../models/core/user.model'

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {}

    public getUser(userId: string): Observable<User> {
        return this.http.get<IUser>(`/users/${userId}`).pipe(map(payload => new User(payload)))
    }

    public updateDetails(id: string, body: Partial<any>): Observable<User> {
        if (environment.appName === 'dukia') {
            return this.http.put<User>(`/admin/dukia/users/${id}/details`, body)
        }
        return this.http.put<User>(`/users/${id}/details`, body)
    }

    public changePassword(id: string, password: string, headers?: { [name: string]: string }): Observable<User> {
        return this.http
            .post<User>(
                `/users/${id}/change-password`,
                { password },
                {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        ...headers,
                    }),
                }
            )
            .pipe(map(payload => new User(payload)))
    }

    public changeEmail(id: string, email: string): Observable<User> {
        return this.http.post<User>(`/users/${id}/change-email`, { email }).pipe(map(payload => new User(payload)))
    }

    public changePermission(id: string, permissions: string[] | null): Observable<User> {
        return this.http
            .post<User>(`/users/${id}/change-permission`, { permissions })
            .pipe(map(payload => new User(payload)))
    }

    public changeMerchantPermission(id: string, permissions: string[] | null): Observable<User> {
        return this.http
            .post<User>(`/users/${id}/change-merchant-permission`, { permissions })
            .pipe(map(payload => new User(payload)))
    }

    public changeSegment(id: string, segment: UserSegment): Observable<User> {
        return this.http.post<User>(`/users/${id}/change-segment`, { segment }).pipe(map(payload => new User(payload)))
    }

    public changeReferrer(id: string, referrerId: string): Observable<User> {
        return this.http
            .post<User>(`/users/${id}/change-referrer`, { referrerId })
            .pipe(map(payload => new User(payload)))
    }

    public changeAssociation(id: string, associationId: string | null): Observable<User> {
        return this.http
            .post<User>(`/admin/users/${id}/change-association`, { associationId })
            .pipe(map(payload => new User(payload)))
    }

    public changeWhitelabel(id: string, whitelabelId: string | null): Observable<User> {
        return this.http
            .post<User>(`/admin/users/${id}/change-whitelabel`, { whitelabelId })
            .pipe(map(payload => new User(payload)))
    }

    public changeExternalId(id: string, externalId: string | null): Observable<User> {
        return this.http
            .post<User>(`/admin/users/${id}/change-external-id`, { externalId })
            .pipe(map(payload => new User(payload)))
    }

    public changeRole(id: string, role: UserRole | null): Observable<User> {
        return this.http.post<User>(`/users/${id}/change-role`, { role }).pipe(map(payload => new User(payload)))
    }

    public changeStatus(
        id: string,
        status: string,
        otpCode?: string,
        otpToken?: string
    ): Observable<User & { otpToken?: string }> {
        let headers = new HttpHeaders()

        if (otpCode) {
            headers = headers.set('X-OTP-Code', otpCode)
        }

        if (otpToken) {
            headers = headers.set('X-OTP-Token', otpToken)
        }

        return this.http
            .post<User>(`/users/${id}/change-status`, { status }, { headers })
            .pipe(map(payload => new User(payload)))
    }

    public changeRiskRating(id: string, riskRating: Risk): Observable<User> {
        return this.http
            .post<User>(`/users/${id}/change-risk-rating`, { riskRating })
            .pipe(map(payload => new User(payload)))
    }

    public changeTwoFactor(id: string, twoFactor: boolean, headers?: { [name: string]: string }): Observable<User> {
        return this.http
            .post<User>(
                `/users/${id}/change-two-factor`,
                { twoFactor },
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json', ...headers }),
                }
            )
            .pipe(map(payload => new User(payload)))
    }

    public changePreferredCurrency(id: string, preferredCurrency: ICurrency): Observable<User> {
        return this.http
            .post<User>(`/users/${id}/change-preferred-currency`, { preferredCurrency })
            .pipe(map(payload => new User(payload)))
    }

    public changeBillingCurrency(id: string, billingCurrency: ICurrency): Observable<User> {
        return this.http
            .post<User>(`/users/${id}/change-billing-currency`, { billingCurrency })
            .pipe(map(payload => new User(payload)))
    }

    public changeSendEmailNotifications(id: string, sendEmailNotifications: boolean): Observable<User> {
        return this.http
            .post<User>(`/users/${id}/change-send-email-notifications`, { sendEmailNotifications })
            .pipe(map(payload => new User(payload)))
    }

    public changeFlags(id: string, flags: UserFlag[]): Observable<User> {
        return this.http.post<User>(`/users/${id}/change-flags`, { flags }).pipe(map(payload => new User(payload)))
    }

    public changeCardProgram(id: string, cardProgramId: string | null): Observable<User> {
        return this.http
            .post<User>(`/admin/users/${id}/change-card-program`, { cardProgramId })
            .pipe(map(payload => new User(payload)))
    }

    public patch(id: string, operations: Operation[]): Observable<User> {
        return this.http
            .patch<User>(`/users/${id}`, operations, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json-patch+json',
                }),
            })
            .pipe(map(payload => new User(payload)))
    }

    public resendOTPMail(id: string): Observable<RPCResult<void>> {
        return this.http.post<RPCResult<void>>(
            `/users/${id}`,
            {
                id: Math.floor(Math.random() * 10000),
                jsonrpc: '2.0',
                method: 'resendOTPMail',
            },
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json-rpc' }),
            }
        )
    }
}
