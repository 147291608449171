import { Injectable } from '@angular/core'
import { QueryEntity } from '@datorama/akita'
import { combineLatest, Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { ICurrency } from 'src/app/common/api-interfaces'
import { WhitelabelService } from 'src/app/common/services/core/whitelabel.service'
import { CurrenciesState, CurrenciesStore } from './currencies.store'
import { SessionService } from '../../common/services/session.service'

@Injectable({ providedIn: 'root' })
export class CurrenciesQuery extends QueryEntity<CurrenciesState> {
    public preferredCurrency$ = combineLatest(this.selectAll(), this.session.userStream).pipe(
        map(([currencies, user]) => currencies.find(currency => currency.code === user.preferredCurrency.code)!)
    )

    constructor(
        protected store: CurrenciesStore,
        private session: SessionService,
        private whitelabelService: WhitelabelService
    ) {
        super(store)
    }

    public selectAllByPermission(): Observable<ICurrency[]> {
        return combineLatest(this.selectAll(), this.session.userStream).pipe(
            map(([currencies, user]) => {
                if (user.permissions.find(permission => permission.startsWith('currencies:'))) {
                    const index = this.session.user.permissions.findIndex(permission =>
                        permission.startsWith('currencies:')
                    )

                    if (index !== -1) {
                        const currencyPermissions = this.session.user.permissions[index].split(':')[1].split(',')
                        return currencies.filter(currency => currencyPermissions.includes(currency.code))
                    } else {
                        return currencies
                    }
                } else {
                    return currencies
                }
            })
        )
    }

    public selectAllByPermissionWithPriority(): Observable<ICurrency[]> {
        const priorityCurrencies = this.whitelabelService.getPriorityCurrencies()

        return this.selectAllByPermission().pipe(
            mergeMap(currencies =>
                this.selectAll({
                    filterBy: currency => currencies.map(c => c.code).includes(currency.code),
                    sortBy: (a, b) => {
                        const aWeight = priorityCurrencies.get(a.code) || 0
                        const bWeight = priorityCurrencies.get(b.code) || 0
                        // Both currencies are not in the priority. Compare alphabetically ASC
                        if (aWeight === 0 && bWeight === 0) {
                            if (a.code > b.code) {
                                return 1
                            } else if (a.code < b.code) {
                                return -1
                            }
                            return 0
                        } else if (aWeight > bWeight) {
                            // Atleast one of the currency is in the list. Compare by weight
                            return -1
                        }
                        return 0
                    },
                })
            )
        )
    }
}
