<div
    *ngIf="canCreate"
    ngfDrop
    [accept]="'image/jpeg,image/gif,image/png,application/pdf,.docx' + (allowVideo ? ',video/mp4' : '')"
    maxSize="52428800"
    selectable="true"
    [files]="files"
    (filesChange)="files = $event; filesChange.next(files)"
    class="dropzone"
    [ngClass]="{
        'ng-invalid': !isValid() && submitted,
        dragover: isDragValid,
        'invalid-dragover': isDragInvalid
    }"
    [(invalidDrag)]="isDragInvalid"
    [(validDrag)]="isDragValid"
    [style.height]="height"
>
    <fa-icon class="dz-icon" [icon]="faCloudUpload"></fa-icon>
    <div class="small">
        <span *ngIf="!isDragValid && !isDragInvalid"> {{ 'common.drag-files-here' | translate }} </span>
        <span *ngIf="isDragValid" class="valid">{{ 'common.drop-file' | translate }}</span>
        <span *ngIf="isDragInvalid" class="invalid">{{ 'common.this-file-is-invalid' | translate }}</span>
    </div>
</div>
<div class="small text-muted" *ngIf="canCreate">
    {{ 'common.the-following-format' | translate }}: jpg, gif, png, pdf, docx{{ allowVideo ? ', mp4' : '' }} (Max. 5{{ allowVideo ? '0' : '' }}MB)
</div>
<div *ngIf="!isValid() && submitted" class="error-messages">
    <div class="form-control-feedback">{{ requiredMessage || 'This is required.' }}</div>
</div>
<div class="table-responsive" *ngIf="showTable && ((files && files.length > 0) || (existingFiles && existingFiles.length > 0))">
    <table class="table table-hover align-items-center table-borderless mt-1">
        <thead>
            <tr>
                <th scope="col" colspan="2" class="b-b-0">{{ 'common.file-name' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white e2e-file" *ngFor="let existingFile of existingFiles">
                <th scope="row">
                    <file-item [file]="existingFile"></file-item>
                </th>
                <td class="text-end" nowrap>
                    <button *ngIf="canDelete" type="button" class="btn btn-danger btn-sm" (click)="onDelete.emit(existingFile)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </tr>
            <tr class="bg-white e2e-file" *ngFor="let item of files; let i = index">
                <th scope="row">
                    <div *ngIf="['image/gif', 'image/png', 'image/jpeg'].indexOf(item.type) >= 0">
                        <div class="previewIcon" [ngfBackground]="item"></div>
                    </div>
                    <div>{{ item.name }}</div>
                    <small class="text-muted">{{ item.type }} / {{ item.size / 1024 / 1024 | number : '.2' }} MB</small>
                </th>
                <td class="text-end" nowrap>
                    <button type="button" class="btn btn-danger btn-sm" (click)="files.splice(i, 1)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
