import { Pipe, PipeTransform } from '@angular/core'
import { formatAccountNumber, isAccountNumber } from './common.mixin'

@Pipe({ name: 'accountNumber' })
export class AccountNumberPipe implements PipeTransform {
    public transform(value: string | null | undefined): string {
        if (!value || !isAccountNumber(value)) {
            return value ? value : ''
        }
        return formatAccountNumber(value)
    }
}
