import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faCaretDown, faChevronDown, faChevronUp, faDownload, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import formatDate from 'date-fns/format'
import { find, findIndex, isEqual, last, matches } from 'lodash'
import { BehaviorSubject, merge, Observable, of, Subject, Subscription, throwError } from 'rxjs'
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators'
import { ConfirmationResult, ConfirmationService } from './services/confirmation.service'
import { SessionService } from './services/session.service'

export enum Scope {
    DEFAULT = 'default',
}

export interface Query {
    [param: string]: { [operator: string]: string | string[] }
}

/**
 * Base class for list controllers.
 */
@Component({
    selector: 'undef-list', // this is required by angular but we don't want it to be used accidentally
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: '',
})
export class ListComponent<T extends { id: number | string }> implements OnInit, OnDestroy {
    public scope: string = Scope.DEFAULT
    public page: number
    public order: string[][]
    public search = ''
    public itemsStream: Observable<T[]>
    public limitOpts: number[] = [5, 8, 10, 12, 15, 20, 50]
    public limit: number
    public offset = 0
    public count = 0
    public fill: undefined[]
    public queryParams: Query = {}
    public searchEvent = new Subject<void>()
    public paginationEvent = new Subject<void>()
    public updateEvent = new Subject<void>()
    public isFetching = new BehaviorSubject<boolean>(false)
    public isFilterOptionsCollapsed = true
    public doCollapseFilterOptionsIfParamsSet = true

    public formatDate = formatDate

    public faPlus = faPlus
    public faSearch = faSearch
    public faChevronUp = faChevronUp
    public faChevronDown = faChevronDown
    public faDownload = faDownload
    public faCaretDown = faCaretDown

    /**
     * The template to use for the edit/create dialog.
     * The scope inherits from the current scope and gets passed `item` (the item to edit/create) as a scope variable
     */
    protected formComponent?: any
    protected model?: new (...args: any[]) => T
    protected apiUrl: string
    protected apiQuery?: string[]
    protected defaultLimit = this.limitOpts[2]
    protected defaultOrder: string[][] = [['createdAt', 'DESC']]
    protected defaultQuery: Query = {}
    protected subscriptions = new Subscription()

    constructor(
        protected http: HttpClient,
        protected ngbModal: NgbModal,
        protected changeDetector: ChangeDetectorRef,
        protected route: ActivatedRoute,
        public router: Router,
        public session: SessionService,
        public confirmation: ConfirmationService
    ) {}

    public ngOnInit(): void {
        this.limit = this.defaultLimit
        this.order = this.defaultOrder
        if (this.apiQuery) {
            for (const property of this.apiQuery) {
                this.queryParams[property] = {}
                if (this.defaultQuery && this.defaultQuery[property]) {
                    // eslint-disable-next-line guard-for-in
                    for (const key in this.defaultQuery[property]) {
                        this.queryParams[property][key] = this.defaultQuery[property][key]
                    }
                }
            }
        }
        this.itemsStream = this.mergeFindEvents().pipe(
            switchMap(() => this.find()),
            catchError(err => {
                this.isFetching.next(false)
                return throwError(err)
            }),
            tap(response => {
                this.count =
                    response.headers && response.headers.has('x-total-count')
                        ? ~~response.headers.get('x-total-count')!
                        : 0
                this.page = this.offset / this.limit + 1
                const routeParams: { [K: string]: string | string[] | undefined } = {
                    page: this.page !== 1 ? this.page + '' : undefined,
                    perPage: this.limit !== this.defaultLimit ? this.limit + '' : undefined,
                    order: !isEqual(this.order, this.defaultOrder) ? this.order.map(item => item.join(':')) : undefined,
                    search: this.search || undefined,
                    scope: this.scope !== Scope.DEFAULT ? this.scope : undefined,
                }
                for (const param of Object.keys(this.queryParams)) {
                    if (Object.keys(this.queryParams[param]).length > 0) {
                        routeParams[param] = !isEqual(this.queryParams[param], this.defaultQuery[param])
                            ? this.encodeQueryParam(this.queryParams[param])
                            : undefined
                    }
                }
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.changeState(routeParams)
            }),
            tap(response => {
                this.fill = new Array(this.limit - response.body!.length)
            }),
            map(response => response.body!.map(item => this.mapItem(item))),
            tap(() => {
                this.changeDetector.detectChanges()
                this.isFetching.next(false)
            })
        )
        this.route.queryParamMap.subscribe(params => {
            if (params.has('perPage')) {
                this.limit = ~~params.get('perPage')!
            }
            if (params.has('page')) {
                this.offset = (~~params.get('page')! - 1) * this.limit
            }
            if (!this.page) {
                this.page = 1
            }
            if (params.has('search')) {
                this.search = decodeURIComponent(params.get('search')!)
            }
            if (params.has('scope')) {
                this.scope = decodeURIComponent(params.get('scope')!) as Scope
            }
            if (params.has('order')) {
                this.order = params.getAll('order').map(item => item.split(':'))
            }
            for (const key of Object.keys(this.queryParams)) {
                if (params.has(key)) {
                    this.queryParams[key] = this.decodeQueryParam(params.getAll(key))
                    // Expand filter options if custom filter is set
                    if (this.doCollapseFilterOptionsIfParamsSet) {
                        this.isFilterOptionsCollapsed = false
                    }
                }
            }
        })
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public find(): Observable<HttpResponse<any[]>> {
        this.isFetching.next(true)
        return this.http.get<any[]>(this.apiUrl, {
            observe: 'response',
            params: this.generateListParams(),
        })
    }

    public generateListParams(override: { limit?: number } = {}): HttpParams {
        let params = new HttpParams().set('limit', (override.limit || this.limit) + '').set('offset', this.offset + '')
        if (this.order) {
            for (const item of this.order) {
                params = params.append('order', item.join(':'))
            }
        }
        if (this.search) {
            // the + sign is already encoded for space, should actually be a plus though
            params = params.set('search', this.search.replace('+', '%2B'))
        }
        if (this.scope && this.scope !== Scope.DEFAULT) {
            params = params.set('scope', this.scope)
        }
        for (const param of Object.keys(this.queryParams)) {
            const encoded = this.encodeQueryParam(this.queryParams[param])
            for (const value of encoded) {
                params = params.append(param, value)
            }
        }
        return params
    }

    /** Modal: Read, update and delete */
    public showForm(_: MouseEvent, item: Partial<T>, options: NgbModalOptions = {}): void {
        if (!this.formComponent) {
            throw new Error('No form-template given in controller.')
        }
        const modal = this.ngbModal.open(this.formComponent, {
            backdrop: 'static',
            windowClass: 'modal-primary',
            ...options,
        })
        modal.componentInstance.item = this.mapItem(item)
        if (modal.componentInstance.onSave) {
            modal.componentInstance.onSave.subscribe(() => {
                modal.close()
                this.updateEvent.next()
            })
        }
        if (modal.componentInstance.onDelete) {
            modal.componentInstance.onDelete.subscribe(() => {
                modal.close()
                this.updateEvent.next()
            })
        }
    }

    public requestExport(ignoreCount?: boolean): void {
        if (this.count > 10000 && !ignoreCount) {
            this.subscriptions.add(
                this.confirmation
                    .show({
                        type: 'warning',
                        text: 'Export exceeds the maximum of 10,000 records. You can use filters to limit your export query. Do you still want to continue?',
                        confirmText: 'Yes',
                        confirmClass: 'warning',
                        cancelText: 'No',
                        cancelClass: 'primary',
                    })
                    .pipe(filter(result => result === ConfirmationResult.CONFIRMED))
                    .subscribe(() => {
                        this.generateList()
                    })
            )
        } else {
            this.generateList()
        }
    }

    public generateList(): void {
        this.subscriptions.add(
            this.http
                .get(this.apiUrl, {
                    params: this.generateListParams({ limit: 10000 }).delete('offset'),
                    headers: new HttpHeaders({
                        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                    observe: 'response',
                    responseType: 'blob',
                })
                .subscribe(response => {
                    const data = window.URL.createObjectURL(response.body!)
                    const link = document.createElement('a')
                    link.href = data
                    const contentDisposition = response.headers.get('Content-Disposition')
                    link.download = contentDisposition
                        ? contentDisposition.substring(
                              contentDisposition.indexOf('filename=') + 9,
                              contentDisposition.length - 1
                          )
                        : 'export.xlsx'
                    link.click()
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data)
                    }, 100)
                })
        )
    }

    /** Select specified page */
    public select(page?: number): void {
        if (typeof page !== 'undefined') {
            this.page = page
        }
        this.offset = (this.page - 1) * this.limit || 0
        this.paginationEvent.next()
    }

    /**
     * Change the sorting order
     * @param order Sequelize Order syntax
     * @param toggle Whether to toggle the order instead of replacing
     */
    public sort(order: string[], toggle = false): void {
        // TODO: Allow order functions to be additive
        if (typeof this.order === 'string') {
            return
        }
        // Find out if order for that attribute is active already
        const index = findIndex(this.order, matches(order))
        // if is already selected, toggle
        if (index === -1 || last(this.order[index]) === 'DESC') {
            order = [...order, 'ASC']
        } else {
            order = [...order, 'DESC']
        }

        if (!toggle) {
            // Replace whole order
            this.order = order.length > 0 ? [order] : this.defaultOrder
        } else {
            if (index !== -1) {
                // If sorting for this attribute is active
                if (order.length === 0) {
                    // If the same icon is clicked three times, remove it
                    this.order.splice(index, 1)
                } else {
                    // If it was clicked twice, swap the direction
                    this.order[index] = order
                }
            } else {
                // If not active, add new order criteria
                this.order.push(order)
            }
        }
        this.select(1)
    }

    public isActiveSorting(order: string[]): boolean {
        return !!find(this.order, matches(order))
    }

    public getSortingDirection(order: string[]): string | null | undefined {
        const orderElement = find(this.order, matches(order))
        return last(orderElement)
    }

    public isActiveQuery(query: Query): boolean {
        return isEqual(this.queryParams, query)
    }

    public getDefaultQuery(): Query {
        return this.defaultQuery
    }

    protected mergeFindEvents(): Observable<void> {
        return merge(of(undefined), this.searchEvent.pipe(debounceTime(600)), this.paginationEvent, this.updateEvent)
    }

    protected mapItem(item: any): T {
        return this.model ? new this.model(item) : item
    }

    protected async changeState(queryParams: { [K: string]: string | string[] | undefined }): Promise<void> {
        const scroll = window.scrollY
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        })
        window.scrollTo(0, scroll)
    }

    private decodeQueryParam(query: string[]): { [operator: string]: string | string[] } {
        const decoded: { [operator: string]: string | string[] } = {}
        for (const q of query) {
            const [op, value] = q.split(':')
            decoded[op] = value?.split(',')
        }
        return decoded
    }

    private encodeQueryParam(query: { [operator: string]: string | string[] }): string[] {
        const encoded: string[] = []
        for (const op of Object.keys(query)) {
            const value = query[op]
            if (!value || (Array.isArray(value) && value.length === 0)) {
                continue
            }
            encoded.push(`${op}:${Array.isArray(value) ? value.join(',') : value}`)
        }
        return encoded
    }
}
