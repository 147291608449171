import {
    animate,
    state as animationState,
    style,
    transition as animationTransition,
    trigger,
} from '@angular/animations'
import { Component, OnDestroy } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { BigNumber } from 'bignumber.js'
import { combineLatest, Observable, Subscription } from 'rxjs'
import { filter, flatMap, map, switchMap, take } from 'rxjs/operators'
import { ICurrency, Wallet } from '../../../../common/api-interfaces'
import { UserService } from '../../../../common/services/core/user.service'
import { SessionService, User } from '../../../../common/services/session.service'
import { ToastrService } from '../../../../common/services/toastr.service'
import { TokenService } from '../../../../common/services/token.service'
import { WalletsQuery } from '../../../../store/wallets/wallets.query'

@Component({
    selector: 'menu-profile',
    templateUrl: 'menu-profile.component.html',
    animations: [
        trigger('toggleBalances', [
            animationState('false', style({ height: '0px' })),
            animationState('true', style({ height: '*' })),
            animationTransition('1 => 0', animate('280ms ease-in')),
            animationTransition('0 => 1', animate('280ms ease-out')),
        ]),
    ],
})
export class MenuProfileComponent implements OnDestroy {
    public faAngleDown = faAngleDown
    public isOpen = false

    public user$: Observable<User>
    public currentBalance$: Observable<string>
    public wallets$: Observable<(Wallet & { value: string })[]>

    private subscriptions = new Subscription()

    constructor(
        private session: SessionService,
        private toastr: ToastrService,
        private userService: UserService,
        private tokenService: TokenService,
        private router: Router,
        private walletsQuery: WalletsQuery,
        public translate: TranslateService
    ) {
        this.user$ = this.session.userStream
        this.wallets$ = this.walletsQuery.filteredBalances$
        this.currentBalance$ = combineLatest(this.walletsQuery.selectAll(), this.session.userStream).pipe(
            map(
                ([wallets, user]) =>
                    wallets.find(wallet => wallet.currency.code === user.preferredCurrency.code)?.availableBalance ||
                    '0.00'
            )
        )
        this.subscriptions.add(
            this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
                this.isOpen = false
            })
        )
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public changePreferredCurrency(currency: ICurrency): void {
        this.subscriptions.add(
            this.session.userStream
                .pipe(
                    take(1),
                    filter(user => user.preferredCurrency.code !== currency.code),
                    switchMap(user => this.userService.changePreferredCurrency(user.id, currency)),
                    flatMap(() => this.tokenService.refresh())
                )
                .subscribe(() => {
                    this.isOpen = false
                    this.toastr.success(this.translate.instant('common.preferred-currency-changed'))
                })
        )
    }

    public hasFrozenBalance(wallet: Wallet) {
        return new BigNumber(wallet.frozenBalance).isGreaterThan(0)
    }
}
