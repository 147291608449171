import { Component, Input, OnChanges } from '@angular/core'
import { IBeneficiary } from '../api-interfaces'

const protocolMap: Record<string, string> = {
    BTC: 'bitcoin',
}

@Component({
    selector: 'crypto-qrcode',
    templateUrl: 'crypto-qrcode.component.html',
})
export class CryptoQrCodeComponent implements OnChanges {
    @Input()
    public beneficiary: IBeneficiary
    @Input()
    public amount: string

    public uri: string

    public ngOnChanges(): void {
        if (!!this.beneficiary) {
            this.uri = `${this.beneficiary.accountNumber}`
        }
        if (this.amount) {
            const protocol = protocolMap[this.beneficiary.currency.code]
            this.uri = `${protocol ? `${protocol}:` : ''}${this.beneficiary.accountNumber}?amount=${this.amount}`
        }
    }
}
