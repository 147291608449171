<div class="container">
    <div class="mt-5 row justify-content-center">
        <div class="col-md-12 col-24">
            <h1>🧐 404!</h1>
            <h4 class="pt-3">{{ 'common.you-lost' | translate }}</h4>
            <p>
                {{ 'common.the-page' | translate }}
            </p>
            <a class="btn btn-outline-secondary" [routerLink]="['/dashboard']">{{ 'common.back' | translate }}</a>
        </div>
    </div>
</div>
