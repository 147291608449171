import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { mergeMap, finalize } from 'rxjs/operators'
import { IUser } from '../api-interfaces'
import { AvatarService } from '../services/avatar.service'
import { ToastrService } from '../services/toastr.service'
import { TokenService } from '../services/token.service'

@Component({
    selector: 'avatar-upload',
    templateUrl: 'avatar-upload.component.html',
})
export class AvatarUploadComponent {
    @Input()
    public user: IUser

    @Output()
    public onChange = new EventEmitter<void>()

    public isAvatarUploading = false

    constructor(
        private http: HttpClient,
        private token: TokenService,
        private avatar: AvatarService,
        private toastr: ToastrService,
        private translate: TranslateService
    ) {}

    public uploadAvatar(avatar: File): void {
        this.isAvatarUploading = true
        this.http
            .request('POST', `/users/${this.user.id}/avatar`, {
                body: avatar,
                headers: new HttpHeaders({
                    'X-File-Name': encodeURIComponent(avatar.name),
                    'Content-Type': avatar.type,
                }),
            })
            .pipe(
                mergeMap(() => this.token.refresh()),
                finalize(() => {
                    this.isAvatarUploading = false
                })
            )
            .subscribe(() => {
                this.avatar.reload()
                this.toastr.success(this.translate.instant('profile.avatar-updated'))
                this.onChange.emit()
            })
    }

    public deleteAvatar(): void {
        this.http
            .delete(`/users/${this.user.id}/avatar`)
            .pipe(mergeMap(() => this.token.refresh()))
            .subscribe(() => {
                this.avatar.reload()
                this.onChange.emit()
            })
    }
}
