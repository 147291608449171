<div class="modal-header">
    <h5 class="modal-title">{{ 'common.confirm-to-continue' | translate }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="modal.dismiss()">&times;</button>
</div>
<form class="e2e-confirm-password" #passwordForm="ngForm" (ngSubmit)="submit()" novalidate>
    <div class="modal-body">
        <div class="alert alert-danger" *ngIf="alert">{{ alert }}</div>
        <div class="form-group" *ngIf="!showTwoFactorForm; else twoFactorForm">
            <label>{{ 'common.password' | translate }}</label>
            <input
                type="password"
                class="form-control"
                name="password"
                [placeholder]="'common.enter-your-password' | translate"
                [(ngModel)]="password"
                [focus]="true"
                required
            />
        </div>
        <ng-template #twoFactorForm>
            <div class="alert alert-info">{{ 'common.two-factor-authentication-is-enabled' | translate }}</div>
            <div class="form-group">
                <label>{{ 'common.two-factor-code' | translate }}</label>
                <input
                    type="text"
                    class="form-control"
                    name="twoFactorCode"
                    [placeholder]="'common.enter-your-code' | translate"
                    number
                    [(ngModel)]="twoFactorCode"
                    [focus]="true"
                    required
                />
            </div>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-icon" [ngClass]="{loading: isLoading}" [disabled]="!canSubmit()">
            <fa-icon class="btn-icon-symbol" [icon]="faUnlockAlt"></fa-icon>
            <span>{{ 'common.confirm' | translate }}</span>
        </button>
    </div>
</form>
