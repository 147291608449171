import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { environment } from 'src/environments/environment'
import { IBeneficiary, Transaction } from '../api-interfaces'
import { calculateCreditableAmount } from '../transactions.mixins'

@Component({
    selector: 'deposit-instruction-table',
    templateUrl: 'deposit-instruction-table.component.html',
})
export class DepositInstructionTableComponent {
    public layout = environment.layout
    @Input()
    public beneficiary: IBeneficiary
    @Input()
    public transaction: Transaction
    @Input()
    public showWarning = false
    @Input()
    public showMethod = true
    @Input()
    public showCurrency = false
    @Input()
    public borderless = false
    @Input()
    public remitter = false

    public calculateCreditableAmount = calculateCreditableAmount

    constructor(public translate: TranslateService) {}

    /** For local method branching */

    public getAccountNumberLabel(): string {
        if (this.beneficiary) {
            if (this.beneficiary.method === 'local') {
                if (this.beneficiary.currency?.code === 'EUR') {
                    return 'IBAN'
                }
            }
            if (this.beneficiary.method === 'crypto') {
                return `${this.translate.instant('common.wallet-address')}`
            }
        }
        return `${this.translate.instant('common.account-number')}`
    }

    public showSwift(): boolean {
        return !(this.beneficiary.method === 'local' && this.beneficiary.currency?.code === 'USD')
    }

    public getSwiftLabel(): string {
        if (this.beneficiary.currency && this.beneficiary.method === 'local') {
            if (this.beneficiary.currency.code === 'AUD') {
                return 'BSB'
            }
            if (this.beneficiary.currency.code === 'EUR') {
                return 'BIC'
            }
            if (['GBP', 'NGN'].includes(this.beneficiary.currency.code)) {
                return 'Sort Code'
            }
            if (this.beneficiary.currency.code === 'JPY') {
                return 'Bank Code'
            }
        }
        return `${this.translate.instant('common.swift-code')}`
    }

    public showRoutingNumber(): boolean {
        return (
            this.beneficiary.method === 'local' &&
            this.beneficiary.currency &&
            ['AUD', 'CAD', 'USD'].includes(this.beneficiary.currency.code!)
        )
    }

    public getRoutingNumberLabel(): string {
        if (this.beneficiary.currency && this.beneficiary.method === 'local') {
            if (this.beneficiary.currency.code === 'AUD') {
                return 'Pay ID'
            }
            if (this.beneficiary.currency.code === 'CAD') {
                return 'Transit Code'
            }
        }
        return 'Routing Number'
    }
}
