import { Directive, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms'

@Directive({
    selector: '[decimalValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DecimalValidatorDirective, multi: true }],
})
export class DecimalValidatorDirective implements Validator {
    @Input()
    public decimal: number

    public validate(control: AbstractControl): ValidationErrors | null {
        if (!control) {
            return null
        }
        // Determines if control passes the required validator
        const required = Validators.required(control)
        // Determines if control has required validator
        const isRequired = control.validator && control.validator({} as AbstractControl)
        if (isRequired !== null && required !== null) {
            return null
        }
        const decimal = control.value?.toString().split('.')[1]
        if (decimal && decimal.length > this.decimal) {
            return { invalidDecimal: true }
        }
        return null
    }
}

export function validateDecimal(decimal: number) {
    return (control: AbstractControl) => {
        // Determines if control passes the required validator
        const required = Validators.required(control)
        // Determines if control has required validator
        const isRequired = control.validator && control.validator({} as AbstractControl)
        if (isRequired !== null && required !== null) {
            return null
        }
        const input = control.value?.toString().split('.')[1]
        if (input && input.length > decimal) {
            return { invalidDecimal: true }
        }
        return null
    }
}
