<div class="container">
    <div class="row no-gutters">
        <div class="footer-left" [ngClass]="appName === 'dukia' ? 'col-lg-12' : 'col-lg-8'">
            <ng-container *ngIf="showCopyRight()"> &copy; {{ year }} Aerapass Ltd. - All rights reserved. </ng-container>
            <ng-container *ngIf="appName === 'dukia'">
                <div class="small">
                    Need help? Get in touch with our support team at
                    <a href="mailto:support@dukiapreciousmetals.co">support@dukiapreciousmetals.co</a> or call
                    <a href="tel:+234 803 569 2881">+234 803 569 2881</a> or <a href="tel:+234 703 323 8121">+234 703 323 8121</a>.
                </div>
            </ng-container>
            <li class="d-flex align-items-center" ngbDropdown placement="top-left" container="body" *ngIf="appName === 'neopay'">
                <button type="button" class="btn btn-transparent" ngbDropdownToggle>
                    <span>{{ translate.currentLang | uppercase }}</span>
                </button>
                <div ngbDropdownMenu>
                    <button *ngFor="let lang of languages" ngbDropdownItem (click)="changeLanguage(lang)">
                        <span>{{ getCountryEmoji(lang) }}&nbsp;</span> {{ mapCountryName(lang) }}
                    </button>
                </div>
            </li>
        </div>
        <div class="footer-right" [ngClass]="appName === 'dukia' ? 'col-lg-12' : 'col-lg-16'">
            <ul>
                <li class="d-flex align-items-center" ngbDropdown placement="top-left" container="body" *ngIf="showLanguageSelector()">
                    <button type="button" class="btn btn-transparent" ngbDropdownToggle>
                        <span>{{ translate.currentLang | uppercase }}</span>
                    </button>
                    <div ngbDropdownMenu>
                        <button *ngFor="let lang of languages" ngbDropdownItem (click)="changeLanguage(lang)">
                            <span>{{ getCountryEmoji(lang) }}&nbsp;</span> {{ mapCountryName(lang) }}
                        </button>
                    </div>
                </li>
                <li class="d-flex align-items-center">
                    <a [routerLink]="['/fees']" class="text-muted">{{ 'common.fee-schedule' | translate | titlecase }}</a>
                </li>
                <li *ngIf="showHelpCenter() && helpCenterUrl" class="d-flex align-items-center">
                    <a [href]="helpCenterUrl" class="text-muted" target="_blank" rel="noopener">{{ 'footer.help-center' | translate }}</a>
                </li>
                <li class="d-flex align-items-center">
                    <a [routerLink]="['/terms']" class="text-muted">{{ 'common.terms-conditions' | translate }}</a>
                </li>
                <li class="d-flex align-items-center">
                    <a [routerLink]="['/privacy']" class="text-muted">{{ 'common.data-privacy-notice' | translate | titlecase }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
