<div class="modal-header">
    <h5 class="modal-title">
        {{ (error === 'VerificationRequiredError' ? 'common.verify-your-identity' : 'common.confirm-your-email-address') | translate }}
    </h5>
    <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body text-center">
    <h3 class="m-0">{{ 'common.you-dont-have-access-to-this-feature' | translate }}</h3>
    <img src="/img/icons/lock.svg" class="img-md my-3" />
    <p class="text-body" *ngIf="error === 'VerificationRequiredError'">
        {{ 'common.please-verify-identity' | translate }}
        <a href (click)="$event.preventDefault(); goToVerification()">{{ 'common.start-now' | translate }}</a>
    </p>
</div>
