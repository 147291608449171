<div class="alert-outlet" [ngClass]="{ 'alert-outlet-full-width': layout === 'full-width' }">
    <div
        class="alert"
        [ngClass]="'alert-' + alert.severity + (layout === 'default' ? ' alert-dismissible' : '')"
        role="alert"
        *ngFor="let alert of alertService.alerts"
    >
        <ng-container *ngIf="layout === 'default'">
            <span [innerHtml]="alert.message"></span>
            <button type="button" class="btn-close" (click)="alertService.remove(alert)"></button>
        </ng-container>
        <ng-container *ngIf="layout === 'full-width'">
            <div class="container d-flex justify-content-between align-items-center py-2">
                <div>
                    <fa-icon class="mx-2" [icon]="alert.icon" *ngIf="alert.icon"></fa-icon>
                    <span [innerHtml]="alert.message"></span>
                    <ng-container *ngIf="alert.callToAction">
                        <span>&nbsp;</span><a [routerLink]="[alert.callToAction.route]">{{ alert.callToAction.label }}</a>
                    </ng-container>
                </div>
                <div>
                    <button type="button" class="btn-close" (click)="alertService.remove(alert)"></button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
