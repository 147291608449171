import { Injectable } from '@angular/core'
import { QueryEntity } from '@datorama/akita'
import BigNumber from 'bignumber.js'
import { orderBy } from 'lodash'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { WalletsState, WalletsStore } from './wallets.store'
import { Wallet } from '../../common/api-interfaces'
import { SessionService } from '../../common/services/session.service'
import { convertCurrency } from '../currencies/currencies.mixin'
import { CurrenciesQuery } from '../currencies/currencies.query'

@Injectable({ providedIn: 'root' })
export class WalletsQuery extends QueryEntity<WalletsState> {
    /** Estimates the value in the user's preferred currency as well */
    public balances$: Observable<(Wallet & { value: string; usdValue: string })[]> = combineLatest(
        this.selectAll(),
        this.currenciesQuery.selectAll(),
        this.session.userStream
    ).pipe(
        map(([wallets, currencies, user]) => {
            const withFrozen = wallets.map(wallet => ({
                ...wallet,
                availableBalance: new BigNumber(wallet.availableBalance || 0)
                    .plus(wallet.frozenBalance || 0)
                    .toString(),
            }))
            const withFrozenAndValue: (Wallet & { value: string; usdValue: string })[] = withFrozen.map(wallet => ({
                ...wallet,
                value: convertCurrency(
                    wallet.availableBalance,
                    currencies.find(currency => currency.code === wallet.currency.code)!,
                    currencies.find(currency => currency.code === user.preferredCurrency.code)!
                ),
                usdValue: new BigNumber(wallet.availableBalance).isGreaterThan(0)
                    ? new BigNumber(
                          convertCurrency(
                              wallet.availableBalance,
                              currencies.find(currency => currency.code === wallet.currency.code)!,
                              currencies.find(currency => currency.code === 'USD')!
                          )
                      ).toFixed(2)
                    : '0.00',
            }))

            // If no wallet of the preferred currency exists, create placeholder
            if (!wallets.find(wallet => wallet.currency.code === user.preferredCurrency.code)) {
                withFrozenAndValue.push({
                    balance: new BigNumber(0).toFixed(8, 1),
                    value: new BigNumber(0).toFixed(8, 1),
                    currency: currencies.find(currency => currency.code === user.preferredCurrency.code),
                } as any)
            }

            // Sort alphabetically by currency name
            return orderBy(withFrozenAndValue, [wallet => wallet.currency?.name.toLowerCase()], ['asc'])
        })
    )

    /** If more than 4 wallets strip of 0 balance wallets */
    public filteredBalances$ = this.balances$.pipe(
        map(wallets => {
            if (wallets.length > 4) {
                let strippable = 0
                return wallets.filter(wallet => {
                    const hasZeroBlance = new BigNumber(wallet.balance).isEqualTo(0)
                    if (hasZeroBlance) {
                        strippable++
                    }
                    return !(hasZeroBlance && wallets.length - strippable >= 4)
                })
            }
            return wallets
        })
    )

    public totalBalance$ = this.balances$.pipe(
        map(wallets => wallets.reduce((netWorth, wallet) => netWorth.plus(wallet.value), new BigNumber(0)).toFixed(2))
    )

    constructor(
        protected store: WalletsStore,
        private currenciesQuery: CurrenciesQuery,
        private session: SessionService
    ) {
        super(store)
    }
}
