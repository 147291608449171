import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'
import isHexColor from 'validator/lib/isHexColor'

@Directive({
    selector: '[colorValidator][formControlName], [colorValidator][formControl], [colorValidator][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ColorValidatorDirective, multi: true }],
})
export class ColorValidatorDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        const color = control.value
        if (color && !isHexColor(color)) {
            return { colorError: true }
        }
        return null
    }
}
