import { Component, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { faCheckCircle, faExclamationTriangle, faInfoCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { BootstrapComponentType } from '../common.mixin'

@Component({
    selector: 'confirmation-modal',
    templateUrl: 'confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
    public type: BootstrapComponentType = 'default'
    public title = 'Confirm'
    public text?: string
    public confirmText? = 'Ok'
    public confirmClass: BootstrapComponentType = 'primary'
    public cancelText? = 'Cancel'
    public cancelClass: BootstrapComponentType = 'default'
    public showCancel? = true

    @ViewChild('inputForm')
    public form: NgForm
    public submitted = false
    public input?: 'text'
    public inputLabel?: string
    public inputValue: string

    private faExclamationTriangle = faExclamationTriangle
    private faInfoCircle = faInfoCircle
    private faCheckCircle = faCheckCircle

    constructor(public activeModal: NgbActiveModal) {}

    public inputValidator: (value: string) => string | null = (_: string) => null

    public confirm(): void {
        if (this.input) {
            this.submitted = true
            if (this.form.invalid) {
                return
            }
        }
        this.activeModal.close(true)
    }

    public get icon(): IconDefinition | null {
        switch (this.type) {
            case 'info':
                return this.faInfoCircle
            case 'success':
                return this.faCheckCircle
            case 'warning':
                return this.faExclamationTriangle
            case 'danger':
                return this.faExclamationTriangle
            default:
                return null
        }
    }
}
