import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import {
    faAngleDown,
    faBars,
    faClock,
    faSearch,
    faSearchPlus,
    faTools,
    faUserCircle,
    faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { WhitelabelService } from 'src/app/common/services/core/whitelabel.service'
import { environment } from 'src/environments/environment'
import { LogoutService } from '../../../common/services/logout.service'
import { SessionService } from '../../../common/services/session.service'
import { ToastrService } from '../../../common/services/toastr.service'
import { LogoutTimerService } from '../logout-timer/logout-timer.service'
import { MenuService, MobileNavLayout, SidebarLayout } from '../menu/menu.service'

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    public faAngleDown = faAngleDown
    public faBars = faBars
    public faSearch = faSearch
    public faUserCircle = faUserCircle
    public faClock = faClock
    public faPaperPlane = faPaperPlane
    public faSearchPlus = faSearchPlus
    public faTools = faTools

    public appName = environment.appName

    public sessionTimeLeft$ = this.logoutTimerService.leftInMinutes$.pipe(
        map(minutes => {
            if (minutes > 60) {
                const hh = Math.floor(minutes / 60)
                return hh + 'h'
            }
            return minutes + 'min' + (minutes === 1 ? '' : 's')
        })
    )

    private subscriptions = new Subscription()

    constructor(
        private logoutService: LogoutService,
        private toastr: ToastrService,
        public session: SessionService,
        public router: Router,
        private menuService: MenuService,
        public logoutTimerService: LogoutTimerService,
        public translate: TranslateService,
        public whitelabel: WhitelabelService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
                this.menuService.mobileNav = MobileNavLayout.HIDDEN
            })
        )
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public logout(): void {
        this.subscriptions.add(
            this.logoutService.logout().subscribe(() => {
                this.toastr.success(this.translate.instant('common.you-have-been-logged-out'))
            })
        )
    }

    public openMobileNav(): void {
        this.menuService.mobileNav = MobileNavLayout.VISIBLE
        this.menuService.sidebar = SidebarLayout.DEFAULT
    }

    public toggleSidebar(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.menuService.sidebar === SidebarLayout.TOGGLED
            ? (this.menuService.sidebar = SidebarLayout.DEFAULT)
            : (this.menuService.sidebar = SidebarLayout.TOGGLED)
    }
}
