import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'collapsible',
    templateUrl: 'collapsible.component.html',
})
export class CollapsibleComponent implements OnInit {
    public isCollapsed = true
    public text: string

    constructor(private translate: TranslateService) {}

    public ngOnInit(): void {
        if (!this.text) {
            this.text = this.translate.instant('common.more-actions')
        }
    }
}
