import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { StorageService } from '../storage.service'

export interface WhitelabelEnv {
    id: string
    name: string
    domain: string
    owner: string
    horizontalLogo: string
    verticalLogo: string
    symbolLogo: string
    favicon: string
}

@Injectable({ providedIn: 'root' })
export class WhitelabelService {
    private whitelabel = new BehaviorSubject<WhitelabelEnv>(window.WHITELABEL ?? null)
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public whitelabel$ = this.whitelabel.asObservable()

    constructor(private http: HttpClient, private storage: StorageService) {}

    public getWhitelabel(): WhitelabelEnv | null {
        return this.whitelabel.getValue()
    }

    public getDisplayName(): string {
        const whitelabel = this.getWhitelabel()
        return whitelabel ? whitelabel.name : environment.displayName
    }

    public checkIfCorrectWhitelabelForUser(userId: string): void {
        this.http.post<{ domain: string } | null>(`/whitelabels/user-check`, { userId }).subscribe(response => {
            if (response && response.domain !== window.location.origin) {
                this.storage.removeItem('token')
                this.storage.removeItem('refreshToken')
                this.storage.removeItem('user')
                window.location.href = response.domain
            }
        })
    }

    /**
     * Returns a Map of priority currencies. It used currency code as key, weight as value
     */
    public getPriorityCurrencies(): Map<string, number> {
        let currencies: Map<string, number> = new Map()

        switch (this.getWhitelabel()?.name) {
            case 'South Bank':
                currencies = new Map([
                    // Fiat
                    ['USD', 11],
                    ['AED', 10],
                    ['GBP', 9],
                    ['CHF', 8],
                    ['EUR', 7],

                    // Crypto
                    ['BTC', 6],
                    ['ETH', 5],
                    ['USDC', 4],
                    ['USDT.erc20', 3],
                    ['USDT.trc20', 2],
                    ['ADA', 1],
                ])
        }

        return currencies
    }
}
