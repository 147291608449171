import { Injectable } from '@angular/core'
import { Meta } from '@angular/platform-browser'

@Injectable({
    providedIn: 'root',
})
export class ViewportService {
    public static readonly MINIMUM_WIDTH = 375
    private originalViewport: string | undefined

    constructor(private meta: Meta) {}

    public update(): void {
        // Ensures a minimum viewport width
        const metaEl = this.meta.getTag('name = "viewport"')
        if (!metaEl) {
            return
        }
        if (window.outerWidth < ViewportService.MINIMUM_WIDTH) {
            if (!this.originalViewport) {
                this.originalViewport = metaEl.content
                metaEl.content = `width=${ViewportService.MINIMUM_WIDTH}, user-scalable=0`
            }
        } else {
            if (this.originalViewport) {
                metaEl.content = this.originalViewport
                this.originalViewport = undefined
            }
        }
    }
}
