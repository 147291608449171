import { Injectable } from '@angular/core'
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita'
import { Wallet } from '../../common/api-interfaces'

export interface WalletsState extends EntityState<Wallet, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'wallets' })
export class WalletsStore extends EntityStore<WalletsState> {
    constructor() {
        super()
    }
}
