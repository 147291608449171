<a
    href
    class="text-decoration-none"
    (click)="$event.preventDefault(); $event.stopPropagation(); copy()"
    ngbTooltip="Copied"
    [triggers]="''"
    #tooltip="ngbTooltip"
>
    <ng-content></ng-content>
</a>
