import { Pipe, PipeTransform } from '@angular/core'
import BigNumber from 'bignumber.js'

@Pipe({ name: 'percentage' })
export class PercentagePipe implements PipeTransform {
    public transform(
        value: string | number | null | undefined,
        minimalDecimalPlaces: number = 2
    ): string | null | undefined {
        const numericValue = new BigNumber(value ? value : 0)
        const multipliedValue = numericValue.multipliedBy(100)

        // Strip zeros without rounding decimals
        let strippedValue = multipliedValue.toString()

        // Handle trailing zeros after the decimal point
        const decimalIndex = strippedValue.indexOf('.')
        if (decimalIndex !== -1) {
            let endIndex = strippedValue.length - 1
            while (strippedValue[endIndex] === '0' && endIndex > decimalIndex) {
                endIndex--
            }
            strippedValue = strippedValue.slice(0, endIndex + 1)
        }

        // Ensure at least minimalDecimalPlaces decimal places
        if (minimalDecimalPlaces > 0) {
            const decimalPart = strippedValue.split('.')[1]
            const currentDecimalPlaces = decimalPart ? decimalPart.length : 0

            if (currentDecimalPlaces < minimalDecimalPlaces) {
                strippedValue += '.'
                for (let i = 0; i < minimalDecimalPlaces - currentDecimalPlaces; i++) {
                    strippedValue += '0'
                }
            }
        }

        return strippedValue.replace(/\.?0+$/, '') + '%'
    }
}
