import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { faEye } from '@fortawesome/pro-solid-svg-icons'
import { Activity, Alert, ChainalysisAlert } from 'src/app/common/api-interfaces'
import { Transaction } from 'src/app/common/models/accounting/transaction.model'

@Component({
    selector: 'chainalysis-table',
    templateUrl: 'chainalysis-table.component.html',
})
export class ChainalysisTableComponent implements OnInit {
    @Input()
    public transaction: Transaction

    public chainalysisApiCall: Activity
    public chainalysisAlerts: ChainalysisAlert[]

    public faEye = faEye

    constructor(private http: HttpClient) {}

    public ngOnInit(): void {
        this.http.get<Activity[]>(`/transactions/${this.transaction.id}/activities`).subscribe(activities => {
            this.chainalysisApiCall = activities[0]
        })

        if (this.transaction.alerts.length) {
            this.http.get<Alert>(`/alerts/${this.transaction.alerts[0].id}`).subscribe(alert => {
                if (
                    alert.trigger &&
                    alert.trigger.type === 'transaction-chainalysis-alert' &&
                    Array.isArray(alert.chainalysisAlerts) &&
                    alert.chainalysisAlerts.length > 0
                ) {
                    this.chainalysisAlerts = alert.chainalysisAlerts
                }
            })
        }
    }
}
