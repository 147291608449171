import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { File, IUser, UserFile, UserFileType } from '../../api-interfaces'
import { toStartCase } from '../../common.mixin'
import { ToastrService } from '../../services/toastr.service'

@Component({
    selector: 'user-file-form',
    templateUrl: 'user-file-form.component.html',
})
export class UserFileFormComponent implements OnInit, OnDestroy {
    @Input()
    public user: IUser
    @Input()
    public file: File
    @Output()
    public readonly onSave = new EventEmitter<void>()
    @Output()
    public readonly onDelete = new EventEmitter<void>()

    public toStartCase = toStartCase

    public copy: Partial<UserFile> = {}
    @ViewChild('userFileForm')
    public userFileForm: NgForm

    public types: UserFileType[] = ['identity', 'address', 'biometrics', 'selfie', 'registration-letter', 'other']
    public isSaving = false

    private subscriptions = new Subscription()

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
        public translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.copy = Object.assign({}, this.file.userFile!)
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public mapTypes(type: UserFileType): any {
        switch (type) {
            case 'identity':
                return 'proof-of-identity'
            case 'address':
                return 'proof-of-address'
            case 'biometrics':
                return 'biometric-video'
            default:
                return type
        }
    }

    public save(): void {
        if (this.userFileForm.invalid) {
            this.toastr.error(this.translate.instant('common.form-invalid'))
            return
        }
        this.isSaving = true

        this.http
            .put(`/users/${this.user.id}/files/${this.file.id}`, this.copy)
            .pipe(
                finalize(() => {
                    this.isSaving = false
                })
            )
            .subscribe(() => {
                this.toastr.success(this.translate.instant('user-file-form.file-updated'))
                this.onSave.emit()
            })
    }
}
