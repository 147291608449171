import { formatNumber } from '@angular/common'
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import BigNumber from 'bignumber.js'

@Pipe({ name: 'amount' })
export class AmountPipe implements PipeTransform {
    public static readonly DEFAULT_DECIMAL_PLACES = 2

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    public transform(
        value: string | number | null | undefined,
        decimalPlaces: number | null | undefined = null,
        // shows null as 0 if set to false
        allowNull = false
    ): string | null | undefined {
        if (value === null || value === undefined) {
            return allowNull ? null : '0'
        }

        const bigValue = new BigNumber(value || 0)

        if (decimalPlaces === null || typeof decimalPlaces === 'undefined') {
            decimalPlaces = Math.max(2, Math.min(bigValue.decimalPlaces() || 0, 8))
            // No decimalPlaces provided, apply stripZeros after formatting
            return formatNumber(bigValue.toNumber(), this.locale, `1.${decimalPlaces}-${decimalPlaces}`)
        } else if (decimalPlaces >= 0) {
            // DecimalPlaces provided, format with the specified decimal places
            return formatNumber(
                bigValue.decimalPlaces(decimalPlaces, 1).toString() as any,
                this.locale,
                `1.${
                    decimalPlaces < AmountPipe.DEFAULT_DECIMAL_PLACES
                        ? decimalPlaces
                        : AmountPipe.DEFAULT_DECIMAL_PLACES
                }-${decimalPlaces}`
            )
        }

        return null
    }
}
