import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms'

@Directive({
    selector: '[min][formControlName],[min][formControl],[min][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MinValidatorDirective,
            multi: true,
        },
    ],
})
export class MinValidatorDirective implements Validator, OnChanges {
    @Input()
    public min: number
    private onChanges: () => void

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.min) {
            this.min = changes.min.currentValue
            if (this.onChanges) {
                this.onChanges()
            }
        }
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (this.min === undefined || this.min === null) {
            return null
        }
        const required = Validators.required(control)
        if (required !== null) {
            return null
        }
        const v = +control.value
        return v >= +this.min ? null : { actualValue: v, requiredValue: +this.min, min: true }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onChanges = fn
    }
}
