import { Component, Input, OnDestroy } from '@angular/core'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { AlertService } from '../services/alert.service'

type AlertLayout = 'default' | 'full-width'

@Component({
    selector: 'alert-outlet',
    templateUrl: 'alert-outlet.component.html',
})
export class AlertOutletComponent implements OnDestroy {
    @Input()
    public layout: AlertLayout = 'default'
    public faTimes = faTimes
    constructor(public alertService: AlertService) {}

    public ngOnDestroy(): void {
        this.alertService.clear()
    }
}
