import { Directive, ElementRef, Input } from '@angular/core'
import { NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'

@Directive({
    selector: '[matchPassword]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MatchPasswordDirective, multi: true }],
})
export class MatchPasswordDirective implements Validator {
    @Input()
    public matchPassword: string

    constructor(private el: ElementRef) {}

    public validate(): ValidationErrors | null {
        return this.el.nativeElement.value !== this.matchPassword ? { matchPassword: true } : null
    }
}
