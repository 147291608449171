import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableAkitaProdMode, persistState } from '@datorama/akita'
import BigNumber from 'bignumber.js'
import { AppModule } from './app/app.module'

function logSelfXssMessage(): void {
    /* eslint-disable no-console */
    console.log('%cStop!', 'color: red; font-size: x-large')
    console.log(
        `%cThis is a browser feature intended for developers only. If someone told you to copy-paste something here, it is a scam and will give them access to your account.`,
        'font-size: large'
    )
    console.log('%cAre you a developer seeking a job? Ask hire() for more information.', 'color: orange')
    /* eslint-enable no-console */
}

;(window as any).hire = () => {
    // eslint-disable-next-line no-console
    console.log('%cHey there! For job inquiries, contact us at jobs@aerapass.com', 'color:green')
}

BigNumber.config({ EXPONENTIAL_AT: [-6, 18] })

if (!window.DEBUG) {
    enableProdMode()
    enableAkitaProdMode()
    logSelfXssMessage()
}

const storage = persistState()

const providers = [{ provide: 'persistStorage', useValue: storage }]

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch(error => console.error(error))
