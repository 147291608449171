<div class="toastr-anchor">
    <div
        *ngFor="let toast of toasts"
        [@flyInOut]
        (click)="hide(toast)"
        (mouseover)="stopTimer(toast)"
        (mouseleave)="startTimer(toast)"
        class="toastr-item toastr-item-{{ toast.type }} media show"
    >
        <div class="d-flex pt-1 me-3">
            <fa-icon [icon]="toast.icon" size="2x"></fa-icon>
        </div>
        <div class="media-body">
            <p class="toastr-item-message">
                <b>{{ toast.title }}</b>
            </p>
            <p class="toastr-item-message">{{ toast.message }}</p>
        </div>
    </div>
</div>
