import { Directive, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms'
import isValid from 'date-fns/isValid'

@Directive({
    selector: '[useValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ValidateDirective, multi: true }],
})
export class ValidateDirective implements Validator {
    @Input()
    public useValidator: string

    public validate(control: AbstractControl): { [key: string]: any } | null {
        const value = control.value
        if (!value) {
            return null
        }
        switch (this.useValidator) {
            case 'object':
                return typeof value !== 'object' ? { validateObject: true } : null
            case 'date':
                if (typeof value === 'string') {
                    const date = value.replace(/_/g, '').replace(/\//g, '')
                    if (
                        date.length === 8 &&
                        isValid(new Date(`${date.slice(4)}-${date.slice(2, 4)}-${date.slice(0, 2)}`))
                    ) {
                        return null
                    }
                }
                return { validateDate: true }
        }
        return null
    }
}
