import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, interval, merge, of } from 'rxjs'
import { tap, mergeMap, filter } from 'rxjs/operators'
import { AlertService } from 'src/app/common/services/alert.service'
import { LogoutService } from 'src/app/common/services/logout.service'
import { SessionService } from 'src/app/common/services/session.service'
import { ToastrService } from 'src/app/common/services/toastr.service'
import { TokenService } from 'src/app/common/services/token.service'
import { LogoutTimerModalComponent } from './logout-timer-modal.component'

@Injectable()
export class LogoutTimerService {
    public leftInMinutes$ = new BehaviorSubject<number>(-1)

    constructor(
        public session: SessionService,
        private logout: LogoutService,
        private alerts: AlertService,
        private tokenService: TokenService,
        private toastr: ToastrService,
        private ngbModal: NgbModal,
        public translate: TranslateService,
        private router: Router
    ) {}

    public init(): void {
        let modal: NgbModalRef | undefined
        merge(of(undefined), this.session.tokenChangeEvent, interval(10000))
            .pipe(
                filter(() => this.session.hasValidRefreshToken()),
                tap(() => {
                    const secondsLeft = this.session.getExpiryTimeInSeconds()
                    this.leftInMinutes$.next(Math.ceil(secondsLeft / 60))
                    if (secondsLeft <= 60 && !modal) {
                        if (this.router.isActive('/register', false)) {
                            this.reset(true)
                            return
                        }
                        modal = this.ngbModal.open(LogoutTimerModalComponent, {
                            windowClass: 'modal-primary',
                        })
                        const handler = () => (modal = undefined)
                        modal.result.then(handler, handler)
                    }
                }),
                filter(() => this.session.getExpiryTimeInSeconds() < 15),
                mergeMap(() => this.logout.logout())
            )
            .subscribe(() => {
                const message = this.translate.instant('common.your-session-expired')
                this.alerts.add({ message, severity: 'warning' })
            })
    }

    public reset(silent = false): void {
        this.tokenService.refresh().subscribe(() => {
            if (!silent) {
                this.toastr.success(this.translate.instant('logout-timer.your-session-has-been-refreshed'))
            }
        })
    }
}
