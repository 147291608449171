import { Injectable } from '@angular/core'

export enum MobileNavLayout {
    HIDDEN = '',
    VISIBLE = 'sidebar-show',
}

export enum SidebarLayout {
    DEFAULT = '',
    TOGGLED = 'sidebar-toggled',
}

@Injectable()
export class MenuService {
    public mobileNav: MobileNavLayout = MobileNavLayout.HIDDEN

    public sidebar: SidebarLayout = SidebarLayout.DEFAULT

    public get style(): string {
        return [this.mobileNav, this.sidebar].join(' ')
    }
}
