import { HttpClient } from '@angular/common/http'
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { faPencil, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { filter, finalize, flatMap } from 'rxjs/operators'
import { BeneficiaryMethod, IBeneficiary } from '../../api-interfaces'
import { BeneficiaryFormComponent } from '../../beneficiary-form/beneficiary-form.component'
import { ListComponent } from '../../list.component'
import { Beneficiary } from '../../models/accounting/beneficiary.model'
import { User } from '../../models/core/user.model'
import { ConfirmationResult, ConfirmationService } from '../../services/confirmation.service'
import { UserService } from '../../services/core/user.service'
import { SessionService } from '../../services/session.service'
import { ToastrService } from '../../services/toastr.service'

@Component({
    selector: 'beneficiary-book',
    templateUrl: 'beneficiary-book.component.html',
})
export class BeneficiaryBookComponent extends ListComponent<IBeneficiary> implements OnInit {
    @Input()
    public userId: string
    @Output()
    public readonly onSelect = new EventEmitter<IBeneficiary>()
    @Input()
    public isAdmin = false

    public user: User

    public defaultLimit = 5

    public isLoading = false
    public isSaving = false
    public isDeleting = false

    public faTimes = faTimes
    public faPencil = faPencil

    constructor(
        http: HttpClient,
        ngbModal: NgbModal,
        changeDetector: ChangeDetectorRef,
        route: ActivatedRoute,
        router: Router,
        session: SessionService,
        confirmation: ConfirmationService,
        public modal: NgbActiveModal,
        private toastr: ToastrService,
        public translate: TranslateService,
        public userService: UserService
    ) {
        super(http, ngbModal, changeDetector, route, router, session, confirmation)
    }

    public ngOnInit(): void {
        if (this.session.user.role !== 'admin') {
            this.userId = this.session.user.id
        }

        this.apiUrl = this.isAdmin ? `/admin/deposit-instructions` : `/users/${this.userId}/beneficiaries`
        this.queryParams = {
            type: {
                eq: 'template',
            },
            method: {
                or: '',
            },
        }

        if (!this.isAdmin) {
            this.subscriptions.add(
                this.userService.getUser(this.userId).subscribe(user => {
                    this.user = user
                    const allowed = user
                        .permissions!.filter(
                            permission => permission.startsWith('payment-') && !permission.startsWith('payment-card')
                        )
                        .map(permission => permission.split('-')[1].split(':')[0])

                    this.queryParams = {
                        ...this.queryParams,
                        method: {
                            or: allowed.join(','),
                        },
                    }

                    super.ngOnInit()
                })
            )
        }
        super.ngOnInit()
    }

    public openBeneficiaryForm(beneficiary: Beneficiary): void {
        // Only display methods that are applicable to the user
        let methods
        if (!this.isAdmin) {
            methods = this.user.permissions
                ?.filter(permission => permission.startsWith('payment-') && !permission.startsWith('payment-card'))
                .map(permission => permission.split('-')[1].split(':')[0]) as BeneficiaryMethod[]
        }

        const modal = this.ngbModal.open(BeneficiaryFormComponent, {
            backdrop: 'static',
            windowClass: 'modal-primary',
        })
        const form = modal.componentInstance as BeneficiaryFormComponent
        form.compact = true
        form.item = beneficiary
        if (!this.isAdmin && methods) {
            form.beneficiaryMethods = methods
        }

        form.onSave.subscribe(() => {
            modal.close()
            this.updateEvent.next()
        })
        form.onDelete.subscribe(() => {
            modal.close()
            this.updateEvent.next()
        })
    }

    public delete(beneficiary: Beneficiary): void {
        this.confirmation
            .show({
                type: 'danger',
                text: `${this.translate.instant(
                    'common.are-you-sure-want-to-delete-beneficiary'
                )}<br>${this.translate.instant('common.you-cant-undo-action')}`,
                confirmText: this.translate.instant('common.delete'),
                confirmClass: 'danger',
            })
            .pipe(
                filter(result => result === ConfirmationResult.CONFIRMED),
                flatMap(() => {
                    this.isDeleting = true
                    return this.http.delete<void>(`/beneficiaries/${beneficiary.id}`)
                }),
                finalize(() => {
                    this.isDeleting = false
                })
            )
            .subscribe(() => {
                this.toastr.success(this.translate.instant('common.beneficiary-deleted'))
                this.updateEvent.next()
            })
    }

    public determineUser(): Record<string, unknown> | null {
        return this.isAdmin ? null : { id: this.session.user.id }
    }

    protected async changeState(): Promise<void> {
        // do nothing
    }
}
