<div ngbDropdown placement="bottom-right">
    <button type="button" class="btn btn-transparent me-1" ngbDropdownToggle>
        <fa-icon [fixedWidth]="true" [icon]="faEnvelope"></fa-icon>
        <span class="badge bg-primary badge-pill" *ngIf="count > 0">
            {{ count | number : '1.0-0' }}
        </span>
    </button>
    <div class="dropdown-menu-w-lg dropdown-menu-right" ngbDropdownMenu>
        <div class="dropdown-header text-center">
            {{ 'common.you-have' | translate }} <strong class="text-primary">{{ count }}</strong>
            {{ (count !== 1 ? 'common.unread-mails' : 'common.unread-mail') | translate }}
        </div>
        <div class="dropdown-divider mb-0"></div>
        <div class="scrollable">
            <a [routerLink]="['/mailbox', mail.id]" class="dropdown-item media d-flex align-items-center e2e-header-mail" *ngFor="let mail of mails">
                <div class="me-3">
                    <user-avatar size="sm" [user]="null"></user-avatar>
                </div>
                <div class="media-body">
                    <small class="text-muted">
                        {{ displayName + ' Team' }}
                    </small>
                    <small class="text-muted float-end mt-q" *ngIf="mail.sentAt"
                        >{{ mail.sentAt | formatDistanceToNow }} {{ 'common.ago' | translate }}</small
                    >
                    <div class="text-truncate font-weight-bold">{{ mail.subject }}</div>
                    <div class="small text-muted" *ngIf="mail.content">{{ striptags(mail.content) | truncate : true : 60 }}</div>
                </div>
            </a>
        </div>
        <div class="dropdown-item text-center bg-white py-3" *ngIf="mails.length === 0">
            <span class="text-muted">{{ 'header.no-unread-mails' | translate }}</span>
        </div>
        <div class="dropdown-divider mt-0"></div>
        <a class="dropdown-item text-center" ngbDropdownItem [routerLink]="['/mailbox']">{{ 'common.view-all' | translate }}</a>
    </div>
</div>
