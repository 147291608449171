import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { Subject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { SupplyStatistic, Wallet } from '../../common/api-interfaces'
import { ListComponent } from '../../common/list.component'

const CurrencyMap = {
    USD: 'USD',
}

@Component({
    selector: 'funds-under-management',
    templateUrl: 'funds-under-management.component.html',
})
export class FundsUnderManagementComponent extends ListComponent<Wallet> implements OnInit, OnDestroy {
    @Input()
    public view: 'merchant' | 'admin' = 'admin'

    public layout = environment.layout
    public faCircle = faCircle
    public currencies: { currencyCode: string; decimalPlaces: number; sum: string }[] = []
    public currency: { currencyCode: string; decimalPlaces: number; sum: string } | null = null

    protected defaultOrder = [['balance', 'DESC']]
    protected apiQuery = ['$user.cardProgramId']
    private fetchEvent = new Subject<void>()

    public ngOnInit(): void {
        this.apiUrl = this.getApiUrl(this.session.user.preferredCurrency.code || CurrencyMap.USD)
        super.ngOnInit()
        this.subscriptions.add(
            this.fetchEvent
                .pipe(
                    switchMap(() =>
                        this.http.get<SupplyStatistic[]>(
                            this.view === 'admin' ? '/admin/statistics/supply' : '/statistics/supply',
                            { params: { limit: 100 + '' } }
                        )
                    )
                )
                .subscribe(response => {
                    this.currencies = response.map(statistic => ({
                        currencyCode: statistic.currency.code,
                        decimalPlaces: statistic.currency.decimalPlaces,
                        sum: statistic.sum,
                    }))
                    this.currency = this.currencies.find(
                        currency => currency.currencyCode === this.session.user.preferredCurrency.code
                    )!
                })
        )
        this.fetchEvent.next()
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public onCurrencyChanged(option: { currencyCode: string; sum: string }): void {
        this.apiUrl = this.getApiUrl(option.currencyCode)
        this.select(1)
    }

    private getApiUrl(currencyCode: string): string {
        return this.view === 'admin'
            ? `/admin/currencies/${currencyCode}/wallets?userId=ne:null`
            : `/groups/${this.session.user.id}/wallets?currencyCode=${currencyCode}`
    }
}
