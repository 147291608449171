import { IAddress } from '../../api-interfaces'

export class Address implements IAddress {
    public id: string
    public addressLine1: string
    public addressLine2: string
    public zipCode: string
    public city: string
    public state: string
    public country: string

    constructor(data: IAddress) {
        Object.assign(this, data)
    }
}
