import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { finalize, flatMap, take, tap } from 'rxjs/operators'
import { RPCResult, TwoFactorRPCResult } from '../api-interfaces'
import { SecurityCheckService } from '../security-check/security-check.service'
import { UserService } from '../services/core/user.service'
import { SessionService } from '../services/session.service'
import { ToastrService } from '../services/toastr.service'
import { TokenService } from '../services/token.service'

@Component({
    selector: 'two-factor-modal',
    templateUrl: 'two-factor-modal.component.html',
})
export class TwoFactorModalComponent implements OnInit {
    public twoFactor: TwoFactorRPCResult | null
    public verificationCode: number | null
    public isLoading = false

    constructor(
        public activeModal: NgbActiveModal,
        private http: HttpClient,
        private session: SessionService,
        private securityCheckService: SecurityCheckService,
        private userService: UserService,
        private tokenService: TokenService,
        private toastr: ToastrService
    ) {}

    public ngOnInit(): void {
        this.session.userStream
            .pipe(
                take(1),
                flatMap(user =>
                    this.http.post<RPCResult<TwoFactorRPCResult>>(
                        `/users/${user.id}`,
                        {
                            id: Math.floor(Math.random() * 10000),
                            jsonrpc: '2.0',
                            method: 'generate2FASecret',
                        },
                        {
                            headers: new HttpHeaders({ 'Content-Type': 'application/json-rpc' }),
                        }
                    )
                )
            )
            .subscribe(res => {
                this.twoFactor = res.result
            })
    }

    public enableTwoFactor(): void {
        this.securityCheckService
            .getScopePermission('securitySettings')
            .pipe(
                tap(() => {
                    this.isLoading = true
                }),
                flatMap(() =>
                    this.userService.changeTwoFactor(this.session.user.id, true, {
                        'X-2FA-Code': this.verificationCode?.toString() || '',
                    })
                ),
                flatMap(() => this.tokenService.refresh()),
                finalize(() => {
                    this.isLoading = false
                })
            )
            .subscribe(() => {
                this.toastr.success('2FA enabled')
                this.verificationCode = null
                this.twoFactor = null
                this.activeModal.close()
            })
    }
}
