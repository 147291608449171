import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    selector: 'uuid',
    templateUrl: 'uuid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UuidComponent {
    @Input() public value: string
    @Input() public ngClass = 'text-muted'
    @Input() public shortened = true
}
