import { NgModule } from '@angular/core'
import { NotificationComponent } from './notification.component'
import { CommonModule } from '../common.module'

@NgModule({
    declarations: [NotificationComponent],
    exports: [NotificationComponent],
    imports: [CommonModule],
})
export class NotificationModule {}
