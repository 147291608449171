import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { xor } from 'lodash'
import { forkJoin, Subject, Subscription } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import { CurrenciesQuery } from '../../store/currencies/currencies.query'
import { IBeneficiary, ICurrency, IFee, IUser } from '../api-interfaces'

@Component({
    selector: 'currency-select-tab',
    templateUrl: 'currency-select-tab.component.html',
    styleUrls: ['currency-select-tab.component.scss'],
})
export class CurrencySelectTabComponent implements OnInit {
    @Input()
    public currency: ICurrency
    @Output()
    public readonly currencyChange = new EventEmitter<ICurrency>()
    @Input()
    public user: IUser | null
    @Input()
    public useShortCurrencyNames = false
    @Input()
    public moreButtonOptions: { text: string; hasCaret: boolean } = { text: '+', hasCaret: false }
    @Input()
    public type: 'deposit-instructions' | 'fees' = 'fees'
    public usd: ICurrency
    public setCurrencies: ICurrency[] = []
    public unsetCurrencies: ICurrency[] = []

    public faCaretDown = faCaretDown

    private fetchEvent = new Subject<void>()
    private subscriptions = new Subscription()

    constructor(private currenciesQuery: CurrenciesQuery, private http: HttpClient) {}

    public ngOnInit(): void {
        this.currenciesQuery
            .selectEntity('USD')
            .pipe(take(1))
            .subscribe(usd => (this.usd = usd!))

        this.subscriptions.add(
            this.fetchEvent
                .pipe(
                    switchMap(() =>
                        forkJoin<ICurrency[], (IFee | IBeneficiary)[]>([
                            this.currenciesQuery
                                .selectAll({
                                    filterBy: [
                                        entity => {
                                            switch (this.type) {
                                                case 'deposit-instructions':
                                                    return entity.code !== 'USD' && entity.type === 'fiat'
                                                default:
                                                    return (
                                                        entity.code !== 'USD' &&
                                                        ['fiat', 'crypto', 'metal'].includes(entity.type)
                                                    )
                                            }
                                        },
                                    ],
                                })
                                .pipe(take(1)),
                            this.type === 'deposit-instructions'
                                ? this.http.get<IBeneficiary[]>(`/deposit-instructions`, {
                                      params: this.user ? { userId: this.user.id } : {},
                                  })
                                : this.http.get<IFee[]>(`/fees`, { params: this.user ? { userId: this.user.id } : {} }),
                        ])
                    )
                )
                .subscribe(([currencies, items]) => {
                    this.setCurrencies = currencies.filter(currency =>
                        items.find(item => item.currency.code === currency.code)
                    )
                    this.unsetCurrencies = xor(currencies, this.setCurrencies)
                })
        )
        this.fetchEvent.next()
    }

    public selectCurrency(code: string): void {
        this.subscribeCurrencyQuery(code)
        this.refreshTabs()
    }

    public createTabSelectedCurrency(currency: ICurrency): void {
        this.setCurrencies.push(currency)
        this.unsetCurrencies = this.unsetCurrencies.filter(data => data !== currency)
        this.subscribeCurrencyQuery(currency.code)
    }

    public isMoreTabActive(): boolean {
        return this.unsetCurrencies.map(currency => currency.code).indexOf(this.currency.code) > -1
    }

    public refreshTabs(): void {
        this.fetchEvent.next()
    }

    public getCurrencyName(currency: ICurrency | undefined): string {
        if (!currency) {
            return ''
        }
        return this.useShortCurrencyNames ? currency.code : `${currency.code} - ${currency.name}`
    }

    private subscribeCurrencyQuery(code: string) {
        this.currenciesQuery
            .selectEntity(code)
            .pipe(take(1))
            .subscribe(currency => {
                this.currencyChange.next(currency)
            })
    }
}
