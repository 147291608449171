<ng-container *ngIf="feeStream | async as fee">
    <ng-container *ngIf="!isLoading; else loadingFee">
        <span>
            <ng-container *ngIf="prefix && currency">{{ currencySymbolMap[currency.code] }}</ng-container
            >{{ calculateAmount(fee) }} {{ currency?.code }}
        </span>
    </ng-container>
</ng-container>
<ng-template #loadingFee>
    <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
</ng-template>
