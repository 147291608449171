import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { merge, ReplaySubject, Subject } from 'rxjs'
import { filter, flatMap, map, switchMap } from 'rxjs/operators'
import { SessionService, UserEventType } from './session.service'

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    public unreadNotificationsStream = new ReplaySubject<number>(1)
    public notifyEvent = new Subject<void>()

    constructor(public session: SessionService, private http: HttpClient) {
        merge(this.notifyEvent, this.session.userEvent.pipe(filter(event => event.type === UserEventType.LOGIN)))
            .pipe(
                switchMap(() => this.session.userStream),
                flatMap(user =>
                    this.http.head(`/users/${user.id}/notifications`, {
                        observe: 'response',
                        params: new HttpParams().set('read', 'false'),
                    })
                ),
                map(response =>
                    response && response.headers.has('x-total-count') ? ~~response.headers.get('x-total-count')! : 0
                )
            )
            .subscribe(count => {
                this.unreadNotificationsStream.next(count)
            })
    }
}
