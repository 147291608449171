import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
    public transform(value: string | null | undefined, wordwise = true, max = 100, tail = '...'): string {
        if (!value) {
            return ''
        }
        if (value.length <= max) {
            return value
        }
        value = value.substr(0, max)
        if (wordwise) {
            const lastSpace = value.lastIndexOf(' ')
            if (lastSpace !== -1) {
                value = value.substr(0, lastSpace)
                if (value[value.length - 1] === ',') {
                    value = value.substr(0, value.length - 1)
                }
            }
        }
        return value + tail
    }
}
