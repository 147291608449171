import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { CurrenciesStore } from './currencies.store'
import { ICurrency, Paginated } from '../../common/api-interfaces'

@Injectable({ providedIn: 'root' })
export class CurrenciesService {
    constructor(protected store: CurrenciesStore, private http: HttpClient) {}

    public fetchAsObservable(): Observable<Paginated<ICurrency>> {
        return this.http.get<Paginated<ICurrency>>('/currencies', { params: { limit: 100 + '' } }).pipe(
            tap(response => {
                this.store.set(response.data)
            })
        )
    }

    public fetch(): void {
        this.fetchAsObservable().subscribe(() => undefined)
    }
}
