import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isValid from 'date-fns/isValid'
import subYears from 'date-fns/subYears'
import { formatMaskedDate } from '../user-details-form/user-details-form.component'

@Directive({
    selector: '[dateOfBirthValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateOfBirthValidatorDirective, multi: true }],
})
export class DateOfBirthValidatorDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value) {
            const date = formatMaskedDate(control.value)
            if (!isValid(new Date(date))) {
                return { invalidDate: true }
            }
            if (isBefore(new Date(date), new Date('1900-01-01'))) {
                return { dateLongAgo: true }
            }
            if (isAfter(new Date(date), subYears(new Date(), 18))) {
                return { dateInFuture: true }
            }
        }
        return null
    }
}
