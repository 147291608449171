import { Component, Input, OnChanges } from '@angular/core'

@Component({
    selector: 'password-strength-bar',
    templateUrl: 'password-strength-bar.component.html',
})
export class PasswordStrengthBarComponent implements OnChanges {
    @Input()
    public requiredStrength: number
    @Input()
    public strength: number
    public progress = 0
    public color: 'danger' | 'warning' | 'success'

    public ngOnChanges(): void {
        this.progress = (this.strength / this.requiredStrength) * 100
        if (this.progress >= 100) {
            this.color = 'success'
        } else if (this.progress >= 50) {
            this.color = 'warning'
        } else if (this.progress >= 1) {
            this.color = 'danger'
        }
    }
}
