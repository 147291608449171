import { User } from './user.model'
import { IContact } from '../../api-interfaces'

export class Contact implements IContact {
    public id: string
    public baseUser: User
    public counterUser: User

    constructor(data: IContact = {} as IContact) {
        Object.assign(this, data)
        if (data.baseUser) {
            this.baseUser = new User(data.baseUser)
        }
        if (data.counterUser) {
            this.counterUser = new User(data.counterUser)
        }
    }
}
