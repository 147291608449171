import { HttpClient } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { faRss } from '@fortawesome/pro-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import differenceInDays from 'date-fns/differenceInDays'
import { Subscription } from 'rxjs'
import { buildFileUrl } from 'src/app/common/file'
import { News } from '../../../../common/api-interfaces'
import { SessionService } from '../../../../common/services/session.service'

@Component({
    selector: 'header-news',
    templateUrl: 'header-news.component.html',
})
export class HeaderNewsComponent implements OnInit, OnDestroy {
    public faRss = faRss
    public news: News[] = []
    public buildFileUrl = buildFileUrl
    private subscriptions = new Subscription()

    constructor(
        private http: HttpClient,
        public session: SessionService,
        public translate: TranslateService,
        public router: Router
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.http
                .get<News[]>('/news/latest', {
                    headers: {
                        'X-No-Toast': 'true',
                    },
                })
                .subscribe(news => {
                    this.news = news
                })
        )
    }

    public isHighlightedNews(news: News): boolean {
        let readNews = localStorage.getItem('readNews')

        if (readNews) {
            readNews = JSON.parse(readNews)

            return (
                !(readNews || '').includes(news.id) &&
                Math.abs(differenceInDays(new Date(news.createdAt), new Date())) < 30
            )
        } else {
            return Math.abs(differenceInDays(new Date(news.createdAt), new Date())) < 30
        }
    }

    public countHighlightedNews(): number {
        let count = 0

        for (const n of this.news) {
            if (this.isHighlightedNews(n)) {
                count += 1
            }
        }

        return count
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
