import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { faBell, faEye } from '@fortawesome/pro-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, forkJoin, of, Subject, Subscription } from 'rxjs'
import { debounceTime, flatMap, mergeMap, switchMap, take } from 'rxjs/operators'
import { Notification, RPCResult } from '../../../../common/api-interfaces'
import { NotificationsService } from '../../../../common/services/notifications.service'
import { SessionService } from '../../../../common/services/session.service'

@Component({
    selector: 'header-notifications',
    templateUrl: 'header-notifications.component.html',
})
export class HeaderNotificationsComponent implements OnInit, OnDestroy {
    public notifications: Notification[]
    public faBell = faBell
    public faEye = faEye
    private fetchEvent = new Subject<void>()
    private subscriptions = new Subscription()

    constructor(
        private http: HttpClient,
        public session: SessionService,
        public notificationsService: NotificationsService,
        private router: Router,
        public translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            combineLatest(this.notificationsService.notifyEvent, this.fetchEvent, this.session.userStream)
                .pipe(
                    debounceTime(600),
                    switchMap(([_, __, user]) => {
                        const userNotifications$ = this.http.get<Notification[]>(`/users/${user.id}/notifications`, {
                            params: new HttpParams().set('limit', '10'),
                        })

                        const signatoryId = this.session.getSignatoryId()
                        const signatoryNotifications$ = signatoryId
                            ? this.http.get<Notification[]>(`/users/${signatoryId}/notifications`, {
                                  params: new HttpParams().set('limit', '10'),
                              })
                            : of([])

                        return forkJoin([userNotifications$, signatoryNotifications$])
                    }),
                    mergeMap(([userNotifications, signatoryNotifications]) => {
                        const allNotifications = [...userNotifications, ...signatoryNotifications]

                        allNotifications.sort(
                            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                        )

                        return of(allNotifications)
                    })
                )
                .subscribe(notifications => {
                    this.notifications = notifications
                })
        )

        this.notificationsService.notifyEvent.next()
        this.fetchEvent.next()
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public markAllAsRead(): void {
        this.subscriptions.add(
            this.session.userStream
                .pipe(
                    take(1),
                    flatMap(user =>
                        this.http.post<RPCResult<void>>(
                            `/users/${user.id}/notifications`,
                            {
                                id: Math.floor(Math.random() * 10000),
                                jsonrpc: '2.0',
                                method: 'markAllAsRead',
                            },
                            {
                                headers: new HttpHeaders({ 'Content-Type': 'application/json-rpc' }),
                            }
                        )
                    )
                )
                .subscribe(() => {
                    this.fetchEvent.next()
                    this.notificationsService.notifyEvent.next()
                })
        )
    }

    public async goto(notification: Notification): Promise<void> {
        if (notification.invoice) {
            await this.router.navigate(['/services/invoices', notification.invoice.id])
        } else if (notification.transaction) {
            await this.router.navigate(['/transactions', notification.transaction.id])
        } else if (notification.conversion) {
            await this.router.navigate(['/services/conversions', notification.conversion.id])
        } else {
            await this.router.navigate(['/notifications'])
        }
    }

    public handleDropdownClosed(payload: boolean): void {
        const unreadNotifications = this.notifications.filter(notification => !notification.read)
        if (!payload && unreadNotifications.length) {
            this.markAllAsRead()
        }
    }
}
