import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'sentenceCase' })
export class SentenceCasePipe implements PipeTransform {
    public transform(value: any): string {
        if (typeof value !== 'string') {
            return value
        }
        return value.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, c => c.toUpperCase())
    }
}
