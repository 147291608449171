import { Component, Input } from '@angular/core'
import { IUser } from '../api-interfaces'

@Component({
    selector: 'account-number',
    templateUrl: 'account-number.component.html',
})
export class AccountNumberComponent {
    @Input()
    public user: IUser
}
