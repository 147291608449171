<div class="profile-documents">
    <dropzone
        *ngIf="canCreate"
        [showTable]="false"
        [files]="files"
        [existingFiles]="existingFiles"
        [requiredMessage]="requiredMessage"
        [allowVideo]="true"
        #dropzone
    ></dropzone>
    <div class="table-responsive">
        <table class="table table-hover align-items-center table-borderless mb-0" *ngIf="files && files.length > 0">
            <thead>
                <tr>
                    <th scope="col" colspan="2">{{ 'common.file-name' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white e2e-file" *ngFor="let item of files; let i = index">
                    <th scope="row">
                        <div class="text-primary">{{ item.name }}</div>
                        <small class="text-muted">{{ item.size / 1024 / 1024 | number : '.2' }} MB</small>
                    </th>
                    <td class="text-end" nowrap>
                        <button type="button" class="btn btn-danger btn-sm" (click)="files.splice(i, 1)">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <button
        *ngIf="canCreate && showButton"
        type="button"
        (click)="uploadFiles()"
        class="btn btn-primary btn-w-sm mt-3 e2e-upload-documents-btn mb-3"
        [ngClass]="{ loading: isUploading, 'float-end': buttonAlign === 'right' }"
        [disabled]="isUploading"
    >
        {{ 'common.upload' | translate }}
    </button>
    <ng-container *ngIf="itemsStream | async; let items">
        <div class="table-responsive" *ngIf="items.length; else noFiles">
            <table class="table table-hover align-items-center table-borderless mb-0">
                <tbody>
                    <tr class="bg-white e2e-file" *ngFor="let existingFile of items">
                        <th scope="row">
                            <file-item [file]="existingFile" [canView]="canView"></file-item>
                        </th>
                        <td class="text-end" nowrap>
                            <button
                                type="button"
                                class="btn btn-primary btn-sm me-1 e2e-edit-file-btn"
                                (click)="showFileModal(existingFile)"
                                *ngIf="canEdit"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger btn-sm e2e-delete-file-btn"
                                (click)="deleteFile(existingFile)"
                                *ngIf="canDelete"
                            >
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #noFiles>
            <div class="card card-body text-center text-muted py-4">No documents found</div>
        </ng-template>
        <div class="d-flex align-items-center mt-3 float-end">
            <ng-select
                class="me-2"
                [(ngModel)]="limit"
                dropdownPosition="auto"
                (change)="select(1)"
                [items]="limitOpts"
                [placeholder]="'common.show-entries-per-page' | translate"
                [clearable]="false"
                [searchable]="false"
                required
            >
                <ng-template ng-label-tmp let-item="item">{{ 'common.show-entries' | translate : { value: item } }}</ng-template>
                <ng-template ng-option-tmp let-item="item">{{ item }} {{ 'common.entries' | translate }}</ng-template>
            </ng-select>
            <ngb-pagination
                [(page)]="page"
                [collectionSize]="count"
                (pageChange)="select()"
                [pageSize]="limit"
                [maxSize]="5"
                [ellipses]="false"
                [rotate]="true"
                [boundaryLinks]="true"
            >
            </ngb-pagination>
        </div>
    </ng-container>
</div>
