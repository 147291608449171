<div class="e2e-access-errors callout callout-danger" *ngIf="whiteListedErrors.length > 0">
    <p>You don't have an access to this feature for the following reasons:</p>
    <ul class="ps-3" *ngFor="let error of whiteListedErrors">
        <li class="mb-2" *ngIf="error === 'VerificationRequiredError'">
            Please complete the verification process to gain access to this feature.
            <a href (click)="$event.preventDefault(); goToVerification()">Verify now</a>
        </li>
        <li class="mb-2" *ngIf="error === 'AccountFrozenError'">
            Your account has been frozen. Please contact customer support to re-activate your account.
        </li>
    </ul>
</div>
