import { Component } from '@angular/core'
import { IntercomService } from 'src/app/common/services/intercom.service'
import { StatusMessageService } from './status-message.service'

@Component({
    selector: 'status-message',
    templateUrl: 'status-message.component.html',
})
export class StatusMessageComponent {
    constructor(public statusMessage: StatusMessageService, public intercom: IntercomService) {}
}
