import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { AddressFormComponent } from '../../address-form/address-form.component'
import { IBeneficiary, ICurrency } from '../../api-interfaces'
import { countriesSortedByName } from '../../countries'

@Component({
    selector: 'beneficiary-details-form',
    templateUrl: 'beneficiary-details-form.component.html',
})
export class BeneficiaryDetailsFormComponent implements OnInit, OnDestroy {
    @Input()
    public beneficiary: IBeneficiary
    @Input()
    public currency?: ICurrency
    @Input()
    public isLarge = false
    @Input()
    public disabled = false
    @Input()
    public requireAll = true
    @ViewChild('beneficiaryDetailsForm')
    public form: NgForm
    @ViewChild('accountHolderAddressForm')
    public accountHolderAddressForm: AddressFormComponent
    @ViewChild('bankAddressForm')
    public bankAddressForm: AddressFormComponent
    @ViewChild('intermediateBankAddressForm')
    public intermediateBankAddressForm: AddressFormComponent
    public countriesSortedByName = countriesSortedByName
    public isIntermediateBankCollapsed = true
    public submitted = false
    public subscriptions = new Subscription()

    public hideAddressForm = false

    constructor(public translate: TranslateService) {}

    public ngOnInit(): void {
        if (this.beneficiary.intermediateBankAddress) {
            this.isIntermediateBankCollapsed = false
        }

        if (window.location.href.includes('aerapass.ch')) {
            this.hideAddressForm = true
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public submit(): void {
        this.submitted = true
        if (this.accountHolderAddressForm) {
            this.accountHolderAddressForm.submit()
        }
        if (this.bankAddressForm) {
            this.bankAddressForm.submit()
        }
        if (this.intermediateBankAddressForm) {
            this.intermediateBankAddressForm.submit()
        }
        Object.assign(this.beneficiary, {
            // Remove intermediateBank if collapsed
            intermediateBankName: this.isIntermediateBankCollapsed ? null : this.beneficiary.intermediateBankName,
            intermediateBankSwiftCode: this.isIntermediateBankCollapsed
                ? null
                : this.beneficiary.intermediateBankSwiftCode,
            intermediateBankAddress: this.isIntermediateBankCollapsed ? null : this.beneficiary.intermediateBankAddress,
        })
    }

    public isValid(): boolean {
        let valid = !!this.form.valid
        if (this.accountHolderAddressForm) {
            valid = valid && this.accountHolderAddressForm.isValid()
        }
        if (this.bankAddressForm) {
            valid = valid && this.bankAddressForm.isValid()
        }
        if (this.intermediateBankAddressForm && !this.isIntermediateBankCollapsed) {
            valid = valid && this.intermediateBankAddressForm.isValid()
        }
        return valid
    }

    public reset(): void {
        this.submitted = false
        this.form.reset()
        if (this.accountHolderAddressForm) {
            this.accountHolderAddressForm.form.reset()
            this.accountHolderAddressForm.submitted = false
        }
        if (this.bankAddressForm) {
            this.bankAddressForm.form.reset()
            this.bankAddressForm.submitted = false
        }
        if (this.intermediateBankAddressForm) {
            this.intermediateBankAddressForm.form.reset()
            this.intermediateBankAddressForm.submitted = false
        }
    }

    /** For local method branching */

    public getAccountNumberLabel(): string {
        if (this.beneficiary) {
            if (this.beneficiary.method === 'local') {
                if (this.getCurrencyCode() === 'EUR') {
                    return 'IBAN'
                }
            }
            if (this.beneficiary.method === 'crypto') {
                return `${this.translate.instant('common.wallet-address')}`
            }
        }
        return `${this.translate.instant('common.account-number')}`
    }

    public showSwift(): boolean {
        return !(this.beneficiary.method === 'local' && this.getCurrencyCode() === 'USD')
    }

    public getSwiftLabel(): string {
        if (this.beneficiary.method === 'local') {
            if (this.getCurrencyCode() === 'AUD') {
                return 'BSB'
            }
            if (this.getCurrencyCode() === 'EUR') {
                return 'BIC'
            }
            if (['GBP', 'NGN'].includes(this.getCurrencyCode())) {
                return 'Sort Code'
            }
            if (this.getCurrencyCode() === 'JPY') {
                return 'Bank Code'
            }
        }
        return 'SWIFT Code'
    }

    public showRoutingNumber(): boolean {
        return this.beneficiary.method === 'local' && ['AUD', 'CAD', 'USD'].includes(this.getCurrencyCode())
    }

    public getRoutingNumberLabel(): string {
        if (this.beneficiary.method === 'local') {
            if (this.getCurrencyCode() === 'AUD') {
                return 'Pay ID'
            }
            if (this.getCurrencyCode() === 'CAD') {
                return 'Transit Code'
            }
        }
        return 'Routing Number'
    }

    private getCurrencyCode(): string {
        return this.currency?.code || this.beneficiary.currency?.code
    }
}
