import { Component, Input } from '@angular/core'
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { File } from '../api-interfaces'
import { toStartCase } from '../common.mixin'
import { ImageComponent } from '../image.component'

@Component({
    selector: 'file-item',
    templateUrl: 'file-item.component.html',
})
export class FileItemComponent {
    @Input()
    public file: File
    @Input()
    public canView = true
    public faSpinner = faSpinner
    public faTimesCircle = faTimesCircle
    public faCheckCircle = faCheckCircle
    public toStartCase = toStartCase

    public imageComponent = ImageComponent

    public constructor(public ngbModal: NgbModal) {}

    public open(file: File): void {
        if (file.type.includes('image')) {
            const modal = this.ngbModal.open(this.imageComponent, {
                size: 'lg',
                centered: true,
            })

            modal.componentInstance.src = file.signedUrl
        } else {
            window.open(file.signedUrl, '_blank', 'noopener')
        }
    }
}
