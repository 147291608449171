import { Component, Input } from '@angular/core'
import { IAddress, IUser } from '../api-interfaces'
import { countryMap } from '../countries'

@Component({
    selector: 'postal-address',
    templateUrl: 'postal-address.component.html',
})
export class PostalAddressComponent {
    public countryMap = countryMap
    @Input()
    public user?: IUser
    @Input()
    public address: IAddress
    @Input()
    public theme: 'input'
}
