import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { SessionService } from 'src/app/common/services/session.service'
import { TokenService } from 'src/app/common/services/token.service'
import { LogoutService } from '../../../common/services/logout.service'
import { ToastrService } from '../../../common/services/toastr.service'

@Component({ selector: 'logout-timer-modal', templateUrl: 'logout-timer-modal.component.html' })
export class LogoutTimerModalComponent {
    constructor(
        public activeModal: NgbActiveModal,
        private logoutService: LogoutService,
        private toastr: ToastrService,
        public session: SessionService,
        private tokenService: TokenService,
        public translate: TranslateService
    ) {}

    public logout(): void {
        this.logoutService.logout().subscribe(() => {
            this.toastr.success(this.translate.instant('common.you-have-been-logged-out'))
        })
    }

    public refresh(): void {
        this.tokenService.refresh().subscribe(() => {
            this.toastr.success(this.translate.instant('logout-timer.your-session-has-been-refreshed'))
        })
    }
}
