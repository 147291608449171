import { Directive } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'
import isValid from 'date-fns/isValid'
import { formatMaskedDate } from '../user-details-form/user-details-form.component'

@Directive({
    selector: '[dateValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true }],
})
export class DateValidatorDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value) {
            const date = formatMaskedDate(control.value)
            if (!isValid(new Date(date))) {
                return { invalidDate: true }
            }
        }
        return null
    }
}
