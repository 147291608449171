<form
    class="form-validation e2e-beneficiary-form"
    #beneficiaryForm="ngForm"
    (ngSubmit)="submit()"
    [ngClass]="{ 'ng-submitted': beneficiaryForm.submitted }"
    autocomplete="false"
    novalidate
>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ (item.id ? 'common.update' : 'common.create') | translate }}
            {{ (item.type === 'deposit-instruction' ? 'common.deposit-instruction' : 'common.beneficiary') | translate }}
        </h5>
        <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!compact">
            <div *ngIf="!hideFields.includes('owner')" class="form-group">
                <label for="owner">{{ 'common.owner' | translate }}</label>
                <ng-select
                    id="owner"
                    name="owner"
                    [placeholder]="'common.enter-owner' | translate"
                    [(ngModel)]="owner"
                    #ownerInput="ngModel"
                    [items]="legalEntitiesSteam | async"
                    [typeahead]="ownerAutocomplete"
                    [disabled]="item.id || fixateType"
                >
                    <ng-template ng-label-tmp let-entry="item">
                        {{ entry?.user.name }}
                        <small class="text-medium">{{ entry?.user.email }}</small>
                    </ng-template>
                    <ng-template ng-option-tmp let-entry="item">
                        <div class="d-flex align-items-center justify-content-center">
                            <user-avatar *ngIf="entry" [user]="entry?.user" size="sm" elementClass="me-2"></user-avatar>
                            <div class="w-100">
                                <div>{{ entry?.user.name }}</div>
                                <small class="text-medium">{{ entry?.user.email }}</small>
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
                <div *ngIf="ownerInput.invalid && (beneficiaryForm.submitted || ownerInput.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="ownerInput?.errors?.validateObject">
                        {{ 'common.please-choose-owner' | translate }}.
                    </div>
                </div>
            </div>
            <div *ngIf="!hideFields.includes('type')" class="form-group">
                <label for="type">{{ 'common.type' | translate }}</label>
                <ng-select
                    name="type"
                    [items]="beneficiaryTypes"
                    [placeholder]="'common.select-type' | translate"
                    [(ngModel)]="copy.type"
                    [clearable]="false"
                    #type="ngModel"
                    [disabled]="item.id || fixateType"
                    required
                >
                    <ng-template ng-label-tmp let-item="item">
                        {{ item | startCase }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{ item | startCase }}
                    </ng-template>
                </ng-select>
                <div *ngIf="type.invalid && (beneficiaryForm.submitted || type.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="type?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="!hideFields.includes('method')" class="form-group">
            <label for="type">{{ 'common.method' | translate }}</label>
            <ng-select
                name="method"
                [items]="beneficiaryMethods"
                [placeholder]="'common.select-method' | translate"
                [(ngModel)]="copy.method"
                [clearable]="false"
                (change)="onChangeMethod($event)"
                [disabled]="item.id || fixateType"
                #method="ngModel"
                required
            >
                <ng-template ng-label-tmp let-item="item">
                    {{ item | uppercase }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{ item | uppercase }}
                </ng-template>
            </ng-select>
            <div *ngIf="method.invalid && (beneficiaryForm.submitted || method.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="method?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
            </div>
        </div>
        <ng-container *ngIf="copy.method">
            <div class="form-group" *ngIf="!hideFields.includes('currency')">
                <label for="currency">{{ 'common.currency' | translate }}</label>
                <ng-select
                    [items]="currencies"
                    [placeholder]="'common.select-currency' | translate"
                    name="currency"
                    id="currency"
                    [clearable]="false"
                    [(ngModel)]="copy.currency"
                    #currency="ngModel"
                    required
                    [searchable]="false"
                    [disabled]="item.id || fixateType"
                >
                    <ng-template ng-label-tmp let-item="item"> {{ item?.code }} - {{ item?.name }} </ng-template>
                    <ng-template ng-option-tmp let-item="item"> {{ item?.code }} - {{ item?.name }} </ng-template>
                </ng-select>
                <div *ngIf="currency.invalid && (beneficiaryForm.submitted || currency.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="currency?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
            <div class="text-center" *ngIf="showTemplatesButton">
                <button type="button" class="btn btn-primary-air" (click)="openBeneficiaryBook()">
                    {{ 'common.from-templates' | translate }}<fa-icon [icon]="faArrowRight" class="ml-1"></fa-icon>
                </button>
            </div>
            <beneficiary-details-form [beneficiary]="copy" [requireAll]="false" #beneficiaryDetailsForm></beneficiary-details-form>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-danger btn-w-sm e2e-delete-beneficiary-btn"
            (click)="delete()"
            *ngIf="item.id"
            [ngClass]="{ loading: isDeleting }"
            [disabled]="isSaving || isDeleting"
        >
            {{ 'common.delete' | translate }}
        </button>
        <button type="submit" class="btn btn-primary btn-w-sm" [ngClass]="{ loading: isSaving }" [disabled]="isSaving || isDeleting">
            {{ (item.id ? 'common.update' : 'common.create') | translate }}
        </button>
    </div>
</form>
