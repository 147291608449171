import { File } from './api-interfaces'

export class FileView {
    private tab: Window

    constructor() {
        const tab = window.open()
        if (!tab) {
            throw new Error('Could not open new window')
        }
        this.tab = tab
    }

    public show(blob: Blob, name?: string): void {
        this.tab.location.replace(URL.createObjectURL(blob))
    }

    public close(): void {
        if (this.tab) {
            this.tab.close()
        }
    }
}

export function buildFileUrl(file: File): string {
    return `https://s3.amazonaws.com/${file.bucket}/${file.key}`
}
