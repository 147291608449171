import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, of } from 'rxjs'
import { catchError, finalize, map, tap } from 'rxjs/operators'
import { SessionService } from './session.service'

@Injectable({
    providedIn: 'root',
})
export class LogoutService {
    private logoutRequest: Observable<void> | undefined
    constructor(
        private http: HttpClient,
        private session: SessionService,
        private ngbModal: NgbModal,
        private router: Router
    ) {}

    public logout(): Observable<void> {
        if (!this.logoutRequest) {
            this.logoutRequest = (
                this.session.hasValidToken() && !!this.session.refreshToken
                    ? this.http
                          .delete<void>(
                              `/users/${this.session.user.id}/refresh-tokens/${this.session.refreshToken.id}`,
                              {
                                  headers: { 'X-No-Logout': 'true' },
                              }
                          )
                          // mute errors of refresh token deletion, logout should happen anyways
                          .pipe(catchError(() => of(undefined)))
                    : of(undefined)
            ).pipe(
                tap(() => {
                    if (this.session.getExpiryTimeInSeconds() < 15) {
                        this.router.navigateByUrl(this.router.parseUrl('/login?redirect=' + this.router.url))
                    } else {
                        this.router.navigate(['/login'])
                    }
                    this.session.logout()
                    this.ngbModal.dismissAll()
                }),
                map(() => undefined),
                finalize(() => {
                    this.logoutRequest = undefined
                })
            )
        }
        return this.logoutRequest
    }
}
