import { NgModule } from '@angular/core'
import { FooterComponent } from './footer/footer.component'
import { HeaderMailboxComponent } from './header/header-mailbox/header-mailbox.component'
import { HeaderNewsComponent } from './header/header-news/header-news.component'
import { HeaderNotificationsComponent } from './header/header-notifications/header-notifications.component'
import { HeaderComponent } from './header/header.component'
import { OnlineUsersCountComponent } from './header/online-users-count/online-users-count.component'
import { LogoutTimerModalComponent } from './logout-timer/logout-timer-modal.component'
import { LogoutTimerService } from './logout-timer/logout-timer.service'
import { MenuProfileComponent } from './menu/menu-profile/menu-profile.component'
import { MenuComponent } from './menu/menu.component'
import { MenuService } from './menu/menu.service'
import { ToastrComponent } from './toastr/toastr.component'
import { CommonModule } from '../../common/common.module'
import { NotificationModule } from '../../common/notification/notification.module'
import { NotFoundModule } from '../not-found/not-found.module'

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        HeaderNotificationsComponent,
        HeaderMailboxComponent,
        HeaderNewsComponent,
        OnlineUsersCountComponent,
        MenuComponent,
        ToastrComponent,
        MenuProfileComponent,
        LogoutTimerModalComponent,
    ],
    exports: [FooterComponent, HeaderComponent, MenuComponent, ToastrComponent],
    providers: [LogoutTimerService, MenuService],
    imports: [CommonModule, NotFoundModule, NotificationModule],
})
export class CoreModule {}
