import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap'
import { of, Subscription } from 'rxjs'
import { delay } from 'rxjs/operators'
import { CopierService } from '../services/copier.service'

@Component({
    selector: 'copy-icon',
    templateUrl: 'copy-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyIconComponent implements OnChanges, OnDestroy {
    @Input()
    public value: string
    @ViewChild('tooltip')
    public tooltip: NgbTooltip
    public faCopy = faCopy
    private subscription?: Subscription
    constructor(private copier: CopierService, private changeDetector: ChangeDetectorRef) {}
    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.value = changes.value.currentValue
            this.changeDetector.markForCheck()
        }
    }
    public copy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
        this.copier.copyText(this.value)
        // detect changes first, the ngbTooltip doesn't get updated otherwise and won't show up
        this.changeDetector.detectChanges()
        this.tooltip.open()
        this.subscription = of(undefined)
            .pipe(delay(1500))
            .subscribe(() => {
                this.changeDetector.markForCheck()
                this.tooltip.close()
            })
    }
}
