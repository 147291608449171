import { NgModule } from '@angular/core'
import { StatusMessageOutletComponent } from './status-message-outlet.component'
import { StatusMessageComponent } from './status-message.component'
import { StatusMessageService } from './status-message.service'
import { CommonModule } from '../../common/common.module'

@NgModule({
    declarations: [StatusMessageComponent, StatusMessageOutletComponent],
    providers: [StatusMessageService],
    imports: [CommonModule],
    exports: [StatusMessageComponent, StatusMessageOutletComponent],
})
export class StatusMessageModule {}
