<ng-container *ngIf="user$ | async; let user">
    <a
        href
        class="profile"
        [ngClass]="{ open: isOpen }"
        (click)="isOpen = !isOpen; $event.preventDefault()"
        [ngbTooltip]="profileTooltip"
        placement="right"
    >
        <user-avatar [user]="user" [border]="true" [online]="true" elementClass="me-2"></user-avatar>
        <ng-container *ngTemplateOutlet="profileTooltip"></ng-container>
        <ng-template #profileTooltip>
            <div>
                <div class="e2e-profile-name">
                    {{ user.name }}
                </div>
                <span class="text-primary available-balance"
                    >{{ currentBalance$ | async | amount : user.preferredCurrency.decimalPlaces }} {{ user.preferredCurrency.code }}</span
                >
            </div>
        </ng-template>
        <fa-icon [fixedWidth]="true" [icon]="faAngleDown" [rotate]="isOpen ? 180 : undefined" class="ms-auto"></fa-icon>
    </a>
    <div class="list-group" [@toggleBalances]="isOpen">
        <a
            href
            class="list-group-item list-group-item-action"
            *ngFor="let wallet of wallets$ | async"
            (click)="changePreferredCurrency(wallet.currency); $event.preventDefault()"
        >
            <span class="text-white">{{ wallet.availableBalance | amount : wallet.currency?.decimalPlaces }}</span>
            &nbsp;<span class="other-balance-currency">{{ wallet.currency?.code }}</span>
            <ng-container *ngIf="hasFrozenBalance(wallet)">
                <br />
                &nbsp;<span class="font-sm text-muted"
                    >({{ 'common.frozen' | translate }}: {{ wallet.frozenBalance | amount : wallet.currency.decimalPlaces }}
                    {{ wallet.currency.code }})</span
                >
            </ng-container>
        </a>
    </div>
</ng-container>
