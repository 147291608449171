import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
/**
 * Local storage abstraction layer that handles browsers without an
 * implementation of localStorage and adds global error handling for storage issues
 */
export class StorageService {
    public storageChange = new Subject<StorageEvent>()
    /* fallback in memory map to simulate local storage */
    private inMemoryLocalStorage = new Map<string, string>()

    private useLocalStorage = this.isLocalStorageAvailable()

    constructor() {
        // add listener for storageEvents
        window.addEventListener('storage', event => {
            this.storageChange.next(event)
        })
    }

    public getItem(key: string, defaultValue?: any): string | null {
        const item = this.useLocalStorage
            ? localStorage.getItem(key)
            : this.inMemoryLocalStorage.has(key)
            ? this.inMemoryLocalStorage.get(key)!
            : null
        if (item === null) {
            return defaultValue
        }
        return item
    }

    public hasItem(key: string): boolean {
        if (this.useLocalStorage) {
            return localStorage.getItem(key) !== null
        } else {
            return this.inMemoryLocalStorage.has(key)
        }
    }

    public setItem(key: string, value: string): void {
        if (this.useLocalStorage) {
            try {
                localStorage.setItem(key, value)
            } catch (error) {
                // hide safari in-private browsing quota exceeded error and don't save to local storage
                if (error.name !== 'QuotaExceededError') {
                    throw error
                }
            }
        } else {
            const oldValue = this.inMemoryLocalStorage.get(key)
            this.inMemoryLocalStorage.set(key, value)
            this.storageChange.next(
                new StorageEvent('change', { url: window.location.href, oldValue, newValue: value, key })
            )
        }
    }

    public removeItem(key: string): void {
        if (this.useLocalStorage) {
            localStorage.removeItem(key)
        } else {
            const oldValue = this.inMemoryLocalStorage.get(key)
            this.inMemoryLocalStorage.delete(key)
            this.storageChange.next(
                new StorageEvent('change', { url: window.location.href, oldValue, newValue: undefined, key })
            )
        }
    }

    private isLocalStorageAvailable(): boolean {
        // taken from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
        const storage = window.localStorage
        try {
            const x = '__storage_test__'
            storage.setItem(x, x)
            storage.removeItem(x)
            return true
        } catch (error) {
            return (
                error instanceof DOMException &&
                // everything except Firefox
                (error.code === 22 ||
                    // Firefox
                    error.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    error.name === 'QuotaExceededError' ||
                    // Firefox
                    error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0
            )
        }
    }
}
