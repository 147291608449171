import { HttpClient } from '@angular/common/http'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { faArrowRight, faPaperPlane, faPhoneLaptop, faReceipt, faUniversity } from '@fortawesome/pro-light-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { Subject, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { IBeneficiary, Transaction } from '../api-interfaces'

@Component({
    selector: 'deposit-instruction',
    templateUrl: 'deposit-instruction.component.html',
})
export class DepositInstructionComponent implements OnInit, OnDestroy {
    @Input()
    public transaction: Transaction
    @Input()
    public remitter = false

    public isLoading = false
    public faPhoneLaptop = faPhoneLaptop
    public faPaperPlane = faPaperPlane
    public faReceipt = faReceipt
    public faUniversity = faUniversity
    public faArrowRight = faArrowRight

    private fetchBeneficiaryEvent = new Subject<void>()
    private subscriptions = new Subscription()

    constructor(private http: HttpClient, public translate: TranslateService) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.fetchBeneficiaryEvent
                .pipe(
                    switchMap(() =>
                        this.http.get<IBeneficiary>(
                            `/beneficiaries/${
                                this.remitter ? this.transaction.remitter!.id : this.transaction.beneficiary!.id
                            }`
                        )
                    )
                )
                .subscribe(beneficiary => {
                    if (this.remitter) {
                        this.transaction.remitter = beneficiary
                    } else {
                        this.transaction.beneficiary = beneficiary
                    }
                })
        )
        this.fetchBeneficiaryEvent.next()
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
