import { Directive, EventEmitter, Input, Output } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms'
const stringEntropy = require('fast-password-entropy/es5')

@Directive({
    selector: '[passwordStrength]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordStrengthDirective, multi: true }],
})
export class PasswordStrengthDirective implements Validator {
    /** Required entropy bits of password string */
    @Input()
    public passwordStrength: number
    @Output()
    public readonly onEntropyChange = new EventEmitter<number>()

    public validate(control: AbstractControl): { weakPassword: boolean } | null {
        const entropy = stringEntropy(control.value)
        this.onEntropyChange.emit(entropy)
        return entropy < this.passwordStrength ? { weakPassword: true } : null
    }
}
