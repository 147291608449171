<user-avatar [user]="user" size="xl" elementClass="d-block mb-2"></user-avatar>
<button
    type="button"
    class="btn btn-primary me-1 e2e-upload-avatar"
    ngfSelect
    (fileChange)="!isAvatarUploading && uploadAvatar($event)"
    [fileDropDisabled]="!user?.id"
    [disabled]="isAvatarUploading"
    [ngClass]="{ loading: isAvatarUploading }"
>
    {{ 'profile.choose-file' | translate }}
</button>
<button type="button" class="btn btn-danger" (click)="deleteAvatar()" [disabled]="isAvatarUploading" *ngIf="!!user?.avatar?.id">
    {{ 'profile.remove-avatar' | translate }}
</button>
