import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IContact } from '../../api-interfaces'
import { Contact } from '../../models/core/contact.model'

export interface ContactCreatePayload {
    baseUser: {
        id: string
    }
    counterUser: {
        accountNumber?: string
        email?: string
    }
}

@Injectable({
    providedIn: 'root',
})
export class ContactService {
    constructor(private http: HttpClient) {}

    public create(contact: ContactCreatePayload): Observable<Contact> {
        return this.http.post<IContact>(`/contacts`, contact).pipe(map(resonse => new Contact(resonse)))
    }
}
