import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { pick } from 'lodash'
import { Subscription } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { ICardOrder, IContact, IConversion, IInvoice, IUser, Notification, Transaction } from '../api-interfaces'
import { ContactService } from '../services/core/contact.service'
import { SessionService } from '../services/session.service'
import { ToastrService } from '../services/toastr.service'

@Component({
    selector: 'notification',
    templateUrl: 'notification.component.html',
})
export class NotificationComponent implements OnInit, OnDestroy {
    @Input()
    public notification: Notification
    @Input()
    public allowUserCopy = true // Use copy-wrap for user

    public user: IUser | null
    public transaction: Transaction | null
    public conversion: IConversion | null
    public invoice: IInvoice | null
    public contact: IContact | null
    public cardOrder: ICardOrder | null
    public displayName = environment.displayName

    public isLoading = false

    private subscriptions = new Subscription()

    constructor(
        public session: SessionService,
        private contactService: ContactService,
        private toastr: ToastrService
    ) {}

    public ngOnInit(): void {
        this.user = this.notification.user
        this.transaction = this.notification.transaction
        this.conversion = this.notification.conversion
        this.invoice = this.notification.invoice
        this.contact = this.notification.contact
        this.cardOrder = this.notification.cardOrder
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public addContact(): void {
        this.isLoading = true
        this.subscriptions.add(
            this.contactService
                .create({
                    baseUser: pick(this.session.user, ['id']),
                    counterUser: {
                        accountNumber: this.notification.contact?.baseUser?.accountNumber ?? '',
                    },
                })
                .pipe(
                    finalize(() => {
                        this.isLoading = false
                    })
                )
                .subscribe(() => {
                    this.toastr.success(
                        `${
                            this.notification.contact && this.notification.contact.baseUser!.accountNumber
                        } added as contact`
                    )
                })
        )
    }
}
