import { animate, keyframes, state, style, transition, trigger } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { Toast, ToastrService } from '../../../common/services/toastr.service'

@Component({
    selector: 'toastr',
    templateUrl: 'toastr.component.html',
    animations: [
        trigger('flyInOut', [
            state('in', style({ transform: 'translateY(-100%)' })),
            transition(':enter', [
                animate(
                    300,
                    keyframes([
                        style({ opacity: 0, transform: 'translateY(-100%)', offset: 0 }),
                        style({ opacity: 1, transform: 'translateY(8px)', offset: 0.3 }),
                        style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
                    ])
                ),
            ]),
            transition(':leave', [
                animate(
                    400,
                    keyframes([
                        style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
                        style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.7 }),
                        style({ opacity: 0, transform: 'translateX(100%)', offset: 1 }),
                    ])
                ),
            ]),
        ]),
    ],
})
export class ToastrComponent implements OnInit, OnDestroy {
    public toasts: Toast[] = []
    private toastSubscription: Subscription

    constructor(private toastr: ToastrService) {}

    public ngOnInit(): void {
        this.toastSubscription = this.toastr.toastPushed.subscribe(toast => {
            this.toasts.unshift(toast)
            this.startTimer(toast)
        })
    }

    public ngOnDestroy(): void {
        this.toastSubscription.unsubscribe()
    }

    public hide(toast: Toast): void {
        this.toasts.splice(
            this.toasts.findIndex(t => t.id === toast.id),
            1
        )
    }

    public stopTimer(toast: Toast): void {
        if (toast.timeout) {
            clearTimeout(toast.timeout)
        }
    }

    public startTimer(toast: Toast): void {
        this.stopTimer(toast)
        toast.timeout = setTimeout(() => this.hide(toast), 6000)
    }
}
