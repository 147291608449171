<button
    class="btn btn-default"
    type="button"
    (click)="$event.stopPropagation(); copy()"
    [ngbTooltip]="'common.copied' | translate"
    [triggers]="''"
    #tooltip="ngbTooltip"
    [disabled]="disabled"
>
    <fa-icon [icon]="faCopy" [fixedWidth]="true"></fa-icon>
</button>
