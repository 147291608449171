import {
    Association,
    File,
    IAddress,
    ICardProgram,
    IUser,
    IWhitelabel,
    Permission,
    Referrer,
    Risk,
    UserDetails,
    UserFlag,
    UserRole,
    UserSegment,
    UserStatus,
    UserType,
} from '../../api-interfaces'
import { Currency } from '../accounting/currency.model'

export const sourceOfIncomeOptions = [
    { label: 'Employment Income', value: 'employment-income' },
    { label: 'Super Pensions or Annuities', value: 'super-pensions-or-annuities' },
    { label: 'Business, Partnership Income', value: 'business-partnership-income' },
    { label: 'Investment Income (Interest, Dividends, Rent)', value: 'investment-income' },
    { label: 'Trust Income', value: 'trust-income' },
    { label: 'Social Security or Government Payments', value: 'social-security-or-government-payments' },
    { label: 'Cross Jurisdictional Income', value: 'cross-jurisdictional-income' },
    { label: 'Compensation or Insurance Payments', value: 'compensation-or-insurance-payments' },
    { label: 'Prizes and Awards', value: 'prizes-and-awards' },
]

export const industryOptions = [
    { label: 'Academic Institutions', value: '94120' },
    { label: 'Active Arms And Lethal Weapons Trade', value: '25400' },
    { label: 'Adult Oriented Products And Services', value: '792227' },
    { label: 'Advertising', value: '73110' },
    { label: 'Anything Linked To Military Contracts', value: '30400' },
    { label: 'Asset & Wealth Management', value: '66300' },
    { label: 'Building & Construction', value: '41100' },
    { label: 'Chemicals', value: '46750' },
    { label: 'Consulting', value: '70229' },
    { label: 'Cultural Artifacts And Art Dealers', value: '47781' },
    { label: 'Dealers In High Value Goods (Such As Precious Metals Including Precious Stones)', value: '46720' },
    { label: 'Distribution', value: '53100' },
    { label: 'Explosives', value: '20510' },
    { label: 'Financial Services', value: '64205' },
    { label: 'Food & Beverage', value: '47110' },
    { label: 'Gambling/Betting', value: '92000' },
    { label: 'Growing Of Spices, Aromatic, Drug And Pharmaceutical Crops', value: '01280' },
    { label: 'Insurance', value: '66220' },
    { label: 'Jewellers', value: '46480' },
    { label: 'Medical Services', value: '84120' },
    { label: 'Metals', value: '24410' },
    { label: 'Mining', value: '05200' },
    { label: 'Oil & Gas', value: '19201' },
    { label: 'Pharmaceuticals & Life Sciences', value: '21100' },
    { label: 'Political Organizations', value: '94920' },
    { label: 'Private Security Firms', value: '80100' },
    { label: 'Professional Services (Secretarial, Admin, Pay Roll)', value: '82990' },
    { label: 'Publishing & Translation', value: '58190' },
    { label: 'Publishing Of Computer Games', value: '58210' },
    { label: 'Real Estate', value: '68310' },
    { label: 'Religious Organizations', value: '94910' },
    { label: 'Shipping Services', value: '30110' },
    { label: 'Telecoms', value: '61900' },
    { label: 'Utilities', value: '35220' },
    { label: 'Venture And Development Capital', value: '64303' },
]

export const occupationOptions = [
    { label: 'Retired/House Person', value: 'retired-house-person' },
    { label: 'Investor', value: 'investor' },
    { label: 'Self Employed', value: 'self-employed' },
    { label: 'Civil Servant', value: 'civil-servant' },
    { label: 'Unemployed', value: 'unemployed' },
]

export const legalStructureOptions = [
    { label: 'Cooperative', value: 'cooperative' },
    { label: 'Corporation', value: 'corporation' },
    { label: 'Limited Liability Company', value: 'limited-liability-company' },
    { label: 'Limited Partnerships', value: 'limited-partnerships' },
    { label: 'Non-Profit Organization', value: 'non-profit-organization' },
    { label: 'Partnerships', value: 'partnerships' },
    { label: 'Private Limited Company', value: 'private-limited-company' },
    { label: 'Public Limited Company', value: 'public-limited-company' },
    { label: 'Trust', value: 'trust' },
]

export const businessPurposeOptions = [
    { label: 'Providing Services', value: 'providing-services' },
    { label: 'Selling Products', value: 'selling-products' },
    { label: 'Other', value: '' },
]

export const serviceOptions = [
    { label: 'International Remittance', value: 'international-remittance' },
    { label: 'Domestic Remittance', value: 'domestic-remittance' },
    { label: 'Digital Currency', value: 'digital-currency' },
    { label: 'Securitised Offerings', value: 'securitised-offerings' },
    { label: 'Prepaid Cards', value: 'prepaid-cards' },
    { label: 'Insurance', value: 'insurance' },
    { label: 'Payment Accounts', value: 'payment-accounts' },
    { label: 'Precious Metals Services', value: 'precious-metals-services' },
]

export class User implements IUser {
    public id: string
    public type: UserType
    public segment: UserSegment
    public accountNumber: string
    public email: string
    public status: UserStatus
    public role: UserRole | null
    public lastLogin: string | null
    public loginCount: number
    public permissions: Permission[] | null
    public twoFactor: boolean
    public riskRating: Risk
    public flags: UserFlag[]
    public approvedAt: Date | null
    public name: string
    public phoneCountryCode: string | null
    public phoneNumber: string | null
    public referrer: Referrer | null
    public preferredCurrency: Currency
    public billingCurrency: Currency
    public files: File[]
    public sendEmailNotifications: boolean
    public expectedTurnover: string | null
    public address: IAddress
    public details: UserDetails
    public association: Association | null
    public whitelabel: IWhitelabel | null
    public parent: User | null
    public cardProgram: ICardProgram | null
    public isManaged: boolean
    public createdAt: string
    public updatedAt: string
    public externalId: string
    public multiSig?: number
    public niumCustomerId?: string | null
    public accountOpeningFeePaidAt?: Date | null
    public merchantPermissions?: string[] | null

    constructor(data: Partial<IUser>) {
        Object.assign(this, data)
        if (data.preferredCurrency) {
            this.preferredCurrency = new Currency(data.preferredCurrency)
        }
    }
}
