<div ngbDropdown #newsDrop="ngbDropdown" placement="bottom-right">
    <button type="button" class="btn btn-transparent me-1" ngbDropdownToggle>
        <fa-icon [fixedWidth]="true" [icon]="faRss"></fa-icon>
        <span class="badge bg-primary badge-pill" *ngIf="countHighlightedNews()">{{ countHighlightedNews() }}</span>
    </button>
    <div class="dropdown-menu-w-lg dropdown-menu-right" ngbDropdownMenu>
        <div class="dropdown-header text-center">
            {{ 'common.news' | translate }}
        </div>
        <div class="dropdown-divider mb-0"></div>
        <div class="scrollable">
            <ng-container *ngIf="news.length; else noNews">
                <div
                    *ngFor="let n of news"
                    (click)="newsDrop.close()"
                    [routerLink]="['/news', n.id]"
                    class="p-2 clickable text-start"
                    [ngClass]="isHighlightedNews(n) ? 'text-primary' : ''"
                >
                    <div class="row m-1">
                        <div class="col-8 ps-0">
                            <img [src]="n.image ? (buildFileUrl(n.image) | safeUrl) : '/img/news.jpg'" class="img-fluid" />
                        </div>
                        <div class="col-16 p-0 text-justify">
                            <p class="mb-1">{{ n.title }}</p>
                            <small>{{ n.summary | truncate : true : 140 }}</small
                            ><br />
                            <small class="text-medium"
                                ><i>{{ n.createdAt | date : 'dd/MM/yyyy' }}</i></small
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noNews>
                <div class="dropdown-item text-center bg-white py-3">
                    <span class="text-muted">{{ 'common.no-news' | translate }}</span>
                </div>
            </ng-template>
        </div>
        <div class="dropdown-divider mt-0"></div>
        <a class="dropdown-item text-center" [routerLink]="['/news']" ngbDropdownItem>{{ 'common.view-all' | translate }}</a>
    </div>
</div>
