<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body e2e-confirmation-modal">
    <div class="media align-items-center" [ngClass]="{ 'mb-3': input }">
        <fa-icon class="mt-1 me-3" [ngClass]="'text-' + type" [icon]="icon" size="2x"></fa-icon>
        <div class="media-body" [innerHtml]="text"></div>
    </div>
    <form #inputForm="ngForm" class="form-validation" [ngClass]="{ 'ng-submitted': submitted }" *ngIf="input" novalidate>
        <ng-container *ngIf="input === 'text'">
            <label class="text-body" for="input" *ngIf="inputLabel">{{ inputLabel }}</label>
            <input
                type="text"
                class="form-control"
                id="input"
                name="input"
                [(ngModel)]="inputValue"
                #inputText="ngModel"
                [customValidator]="inputValidator"
                emptyToNull
            />
            <div *ngIf="inputText.invalid && (submitted || inputText.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="inputText?.errors?.customError">{{ inputValidator(inputValue) }}</div>
            </div>
        </ng-container>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn me-2 e2e-confirmation-modal-confirm-btn" [ngClass]="'btn-' + confirmClass" (click)="confirm()">
        {{ confirmText }}
    </button>
    <button
        *ngIf="showCancel"
        type="button"
        class="btn e2e-confirmation-modal-cancel-btn"
        [ngClass]="'btn-' + cancelClass"
        (click)="activeModal.close(false)"
    >
        {{ cancelText }}
    </button>
</div>
