import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { faUnlockAlt } from '@fortawesome/pro-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { capitalize } from 'lodash'
import { Observable, throwError } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'
import { SessionService, TokenScope, User } from '../services/session.service'
import { TokenService } from '../services/token.service'

@Component({
    selector: 'confirm-password-modal',
    templateUrl: 'confirm-password.modal.html',
})
export class ConfirmPasswordModalComponent implements OnInit {
    @ViewChild('passwordForm', { static: true })
    public passwordForm: NgForm
    public password: string | undefined
    public alert: string | undefined
    @Input()
    public scope: TokenScope
    public twoFactorCode?: string | undefined
    public showTwoFactorForm = false
    public isLoading = false
    public faUnlockAlt = faUnlockAlt

    constructor(public modal: NgbActiveModal, private tokenService: TokenService, private session: SessionService) {}

    public ngOnInit(): void {
        // don't ask for PW for users which use twoFactor auth
        if (this.session.user.twoFactor) {
            this.showTwoFactorForm = true
        }
    }

    public canSubmit(): boolean {
        return !this.isLoading && !!this.passwordForm.valid
    }

    public grantTokenScope(): Observable<User> {
        this.isLoading = true
        return this.tokenService
            .refresh({
                password: this.session.user.twoFactor ? undefined : this.password,
                scopes: Array.from(new Set([...this.session.getTokenScopes(), this.scope])),
                twoFactorCode: this.twoFactorCode,
            })
            .pipe(
                finalize(() => {
                    this.isLoading = false
                }),
                catchError(error => {
                    if (error.name === 'Require2FACodeError') {
                        this.showTwoFactorForm = true
                        this.alert = undefined
                    } else {
                        this.alert = capitalize(
                            error.name === 'WrongCredentialsError'
                                ? 'The password you entered was not correct'
                                : error.message
                        )
                    }
                    return throwError(error)
                })
            )
    }

    public submit(): void {
        this.grantTokenScope().subscribe(response => this.modal.close(response))
    }

    public reset(): void {
        this.isLoading = false
        this.password = undefined
        this.twoFactorCode = undefined
        this.alert = undefined
        if (this.passwordForm) {
            this.passwordForm.resetForm()
        }
    }
}
