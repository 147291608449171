import { Currency } from './currency.model'
import { BeneficiaryDetails, BeneficiaryMethod, BeneficiaryType, IBeneficiary } from '../../api-interfaces'
import { Address } from '../core/address.model'
import { User } from '../core/user.model'

export class Beneficiary implements IBeneficiary {
    public id: string
    public type: BeneficiaryType
    public method: BeneficiaryMethod
    public accountHolder: string | null
    public accountHolderAddress: Address | null
    public accountNumber: string | null
    public routingNumber: string | null
    public bankName: string | null
    public bankSwiftCode: string | null
    public bankAddress: Address | null
    public intermediateBankName: string | null
    public intermediateBankSwiftCode: string | null
    public intermediateBankAddress: Address | null
    public user: User | null
    public currency: Currency
    public createdAt: string
    public updatedAt: string
    public templateId: string | null
    public details: BeneficiaryDetails
    public userType: 'personal' | 'business' | null

    constructor(data: Partial<IBeneficiary>) {
        Object.assign(this, data)
        if (data.currency) {
            this.currency = new Currency(data.currency)
        }
        if (data.user) {
            this.user = new User(data.user)
        }
        if (data.accountHolderAddress) {
            this.accountHolderAddress = new Address(data.accountHolderAddress)
        }
        if (data.bankAddress) {
            this.bankAddress = new Address(data.bankAddress)
        }
        if (data.intermediateBankAddress) {
            this.intermediateBankAddress = new Address(data.intermediateBankAddress)
        }
    }
}
