import { Directive, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'

@Directive({
    selector: '[customValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CustomValidatorDirective, multi: true }],
})
export class CustomValidatorDirective implements Validator {
    @Input()
    public customValidator: (value: string) => string | null

    public validate(control: AbstractControl): ValidationErrors | null {
        if (this.customValidator(control.value)) {
            return { customError: true }
        }
        return null
    }
}
