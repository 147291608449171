import { countries as countryData, Country, CountryMap } from '@aerapass/country-data'
import { orderBy } from 'lodash'

export const countryMap: CountryMap = {}
export const countries: Country[] = []

for (const country of countryData.all) {
    if (country.status === 'assigned' && country.countryCallingCodes.length > 0) {
        countryMap[country.alpha2] = country
        countries.push(country)
    }
}

export const countriesSortedByName = orderBy(countries, [country => country.name.toLowerCase()], ['asc'])

export { Country }
