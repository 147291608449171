<ng-progress></ng-progress>
<div class="app" [ngClass]="menuService.style">
    <app-menu class="sidebar-wrapper" *ngIf="showSidebar()"></app-menu>
    <div class="app-body">
        <app-header class="app-header" *ngIf="showHeader()"></app-header>
        <main class="main">
            <status-message-outlet></status-message-outlet>
            <toastr></toastr>
            <router-outlet></router-outlet>
        </main>
        <app-footer class="app-footer" *ngIf="showFooter()"></app-footer>
    </div>
</div>
