import { Directive, ElementRef, Input, NgZone, OnChanges } from '@angular/core'
import { take } from 'rxjs/operators'

@Directive({
    selector: '[focus]',
})
export class FocusDirective implements OnChanges {
    @Input()
    public focus: boolean

    constructor(private el: ElementRef, private ngZone: NgZone) {}

    public ngOnChanges(): void {
        if (this.focus) {
            if (this.el.nativeElement) {
                this.ngZone.onStable
                    .asObservable()
                    .pipe(take(1))
                    .subscribe(() => {
                        this.el.nativeElement.focus()
                    })
            }
        }
    }
}
