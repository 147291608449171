import { Component, Input } from '@angular/core'

@Component({
    selector: 'circle-spinner',
    templateUrl: 'circle-spinner.component.html',
    styleUrls: ['circle-spinner.component.scss'],
})
export class CircleSpinnerComponent {
    @Input() public theme: string
}
