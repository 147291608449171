import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { faFilePdf, faFileCode } from '@fortawesome/pro-light-svg-icons'
import { faExclamationTriangle, faSyncAlt, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import BigNumber from 'bignumber.js'
import { cloneDeep } from 'lodash'
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    flatMap,
    map,
    mergeMap,
    switchMap,
    tap,
} from 'rxjs/operators'
import { toStartCase } from 'src/app/common/common.mixin'
import { BeneficiaryService } from 'src/app/common/services/accounting/beneficiary.service'
import { environment } from 'src/environments/environment'
import { TransactionProcessComponent } from './transaction-process.component'
import {
    File as IFile,
    IBeneficiary,
    ICurrency,
    IUser,
    Paginated,
    RPCResult,
    Transaction as ITransaction,
    TransactionMethod,
    Wallet,
    BeneficiaryMethod,
} from '../../common/api-interfaces'
import { BeneficiaryDetailsFormComponent } from '../../common/beneficiary-form/beneficiary-details-form/beneficiary-details-form.component'
import { Beneficiary } from '../../common/models/accounting/beneficiary.model'
import { Transaction } from '../../common/models/accounting/transaction.model'
import { BeneficiaryBookComponent } from '../../common/payment/beneficiary-book/beneficiary-book.component'
import { SecurityCheckService } from '../../common/security-check/security-check.service'
import { ConfirmationResult, ConfirmationService } from '../../common/services/confirmation.service'
import { SessionService } from '../../common/services/session.service'
import { ToastrService } from '../../common/services/toastr.service'
import { TransactionService } from '../../common/services/transaction.service'
import { isTransactionProcessable } from '../../common/transactions.mixins'

@Component({
    selector: 'transaction-form',
    templateUrl: 'transaction-form.component.html',
})
export class TransactionFormComponent implements OnInit, OnDestroy {
    @Input()
    public item: Transaction
    @Output()
    public readonly onSave = new EventEmitter<void>()
    @Output()
    public readonly onDelete = new EventEmitter<void>()

    @ViewChild('transactionForm')
    public transactionForm: NgForm
    @ViewChild('beneficiaryDetailsForm')
    public beneficiaryDetailsForm?: BeneficiaryDetailsFormComponent
    public copy: Partial<ITransaction> = {}
    public activeCurrencies: ICurrency[]
    public currencies: ICurrency[]
    public activeTransactionMethods: TransactionMethod[] = []
    public transactionMethods: TransactionMethod[] = ['internal', 'card', 'crypto', 'swift', 'local']
    public currentTab = 'details'

    public isSaving = false
    public isDeleting = false
    public isChangingStatus = false
    public isUploading = false
    public isUpdating = false

    public faTimes = faTimes
    public faPlus = faPlus
    public faMinus = faMinus
    public faFilePdf = faFilePdf
    public faFileCode = faFileCode
    public faExclamationTriangle = faExclamationTriangle
    public faSyncAlt = faSyncAlt
    public displayName = environment.displayName
    public layout = environment.layout

    public isBeneficiaryCollapsed = true

    public sender: { user: IUser | null } | undefined
    public recipient: { user: IUser | null } | undefined
    public userAutocomplete = new EventEmitter<string>()
    public usersStream = this.userAutocomplete.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(search =>
            this.http.get<IUser[]>('/users', {
                params: new HttpParams().set('limit', '25').set('search', search),
            })
        ),
        map(results => results.map(user => ({ user })))
    )
    public rootWallets: Wallet[] = []
    public isProcessable = isTransactionProcessable
    public toStartCase = toStartCase

    public existingFiles: IFile[] = []
    public existingBeneficiaryFiles: IFile[] = []

    public nameScreening: any

    private fetchEvent = new Subject<void>()
    private fetchRootWallets = new Subject<void>()
    private subscriptions = new Subscription()

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
        private transactionService: TransactionService,
        private securityCheckService: SecurityCheckService,
        private confirmation: ConfirmationService,
        private ngbModal: NgbModal,
        private session: SessionService,
        public translate: TranslateService,
        private renderer: Renderer2,
        private beneficiaryService: BeneficiaryService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.fetchEvent
                .pipe(
                    switchMap(() => this.fetchCurrencies()),
                    flatMap(() => (this.item && this.item.id ? this.fetch() : of(undefined)))
                )
                .subscribe(response => {
                    if (response) {
                        const transaction = response
                        Object.assign(this.item, transaction)
                    } else if (this.item.type && ['deposit', 'withdrawal'].includes(this.item.type)) {
                        this.changeType(this.item.type as 'deposit' | 'withdrawal')
                    }
                    this.copy = cloneDeep(this.item)
                })
        )
        this.subscriptions.add(
            this.fetchRootWallets
                .pipe(
                    switchMap(() =>
                        this.http.get<Wallet[]>(`/admin/wallets`, {
                            params: new HttpParams().set('limit', '100'),
                        })
                    )
                )
                .subscribe(wallets => (this.rootWallets = wallets))
        )
        this.copy = cloneDeep(this.item)
        this.fetchEvent.next()
        this.fetchRootWallets.next()
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public fetch(): Observable<Transaction> {
        return this.http.get<Transaction>(`/transactions/${this.item.id}`).pipe(
            map(transaction => new Transaction(transaction)),
            mergeMap(transaction =>
                forkJoin([
                    of(transaction),
                    this.http.get<IFile[]>(`/transactions/${transaction.id}/files`).pipe(
                        tap(files => {
                            this.existingFiles = files
                        })
                    ),
                    this.http.get<IFile[]>(`/beneficiaries/${transaction.beneficiary?.id}/files`).pipe(
                        tap(files => {
                            this.existingBeneficiaryFiles = files
                        })
                    ),
                    this.http.get<any>(`/admin/transactions/${transaction.id}/name-screening`).pipe(
                        tap(response => {
                            this.nameScreening = response
                        })
                    ),
                ]).pipe(map(([t]) => t))
            )
        )
    }

    public fetchCurrencies(): Observable<void> {
        return this.http.get<Paginated<ICurrency>>('/currencies', { params: { limit: 100 + '' } }).pipe(
            tap(response => {
                this.currencies = response.data
                this.activeCurrencies = cloneDeep(this.currencies)
            }),
            map(() => undefined)
        )
    }

    public submit(): void {
        if (this.transactionForm.invalid) {
            return
        }
        if (this.beneficiaryDetailsForm) {
            this.beneficiaryDetailsForm.submit()
            if (!(this.beneficiaryDetailsForm.isValid() || this.beneficiaryDetailsForm.disabled)) {
                return
            }
        }
        this.subscriptions.add(
            this.securityCheckService.getScopePermission('banking').subscribe(() => {
                const body = cloneDeep(this.copy)
                if (this.sender) {
                    body.baseWallet = {
                        user: this.sender.user ?? null,
                    } as Wallet
                }
                if (this.recipient) {
                    body.counterWallet = {
                        user: this.recipient.user ?? null,
                    } as Wallet
                }
                if (['deposit', 'withdrawal'].includes(this.item.type)) {
                    body.beneficiary = this.copy.beneficiary
                }
                this.isSaving = true
                ;(this.item.id
                    ? this.http.put<ITransaction>(`/transactions/${this.item.id}`, body)
                    : this.http.post<ITransaction>('/transactions', body)
                )
                    .pipe(
                        tap(transaction => {
                            this.copy = transaction
                        }),
                        finalize(() => {
                            this.isSaving = false
                        })
                    )
                    .subscribe(() => {
                        this.fetchEvent.next()
                        this.toastr.success(
                            `Transaction ${
                                this.item.id
                                    ? this.translate.instant('common.updated')
                                    : this.translate.instant('common.created')
                            }`
                        )
                        this.onSave.emit()
                        this.transactionService.changeEvent.emit()
                    })
            })
        )
    }

    public cancel(): void {
        this.isChangingStatus = true
        this.subscriptions.add(
            this.confirmation
                .show({
                    type: 'danger',
                    text: `${this.translate.instant('common.are-you-sure-want-to-cancel')} ${this.item.id}`,
                    confirmText: this.translate.instant('common.yes'),
                    confirmClass: 'danger',
                    cancelText: this.translate.instant('common.no'),
                    cancelClass: 'success',
                })
                .pipe(
                    filter(result => result === ConfirmationResult.CONFIRMED),
                    flatMap(() => this.transactionService.changeStatus(this.item.id, 'cancelled')),
                    finalize(() => {
                        this.isChangingStatus = false
                    })
                )
                .subscribe(() => {
                    this.toastr.success(this.translate.instant('common.transaction-cancelled'))
                    this.onSave.next()
                })
        )
    }

    public start(): void {
        this.isChangingStatus = true
        this.subscriptions.add(
            this.transactionService
                .changeStatus(this.item.id, 'processing')
                .pipe(
                    finalize(() => {
                        this.isChangingStatus = false
                    })
                )
                .subscribe(() => {
                    this.fetchEvent.next()
                })
        )
    }

    public process(): void {
        if (this.copy.type && ['deposit', 'withdrawal'].includes(this.copy.type)) {
            this.securityCheckService.getScopePermission('banking').subscribe(() => {
                this.activeModal.close()
                const modal = this.ngbModal.open(TransactionProcessComponent, {
                    backdrop: 'static',
                    windowClass: 'modal-primary',
                })
                const component = modal.componentInstance as TransactionProcessComponent
                component.transaction = this.copy as ITransaction
                component.onSave.subscribe(() => {
                    this.onSave.next()
                    modal.close()
                })
            })
        } else {
            this.subscriptions.add(
                this.securityCheckService.getScopePermission('banking').subscribe(() => {
                    this.http
                        .post<RPCResult<ITransaction>>(
                            `/transactions/${this.item.id}`,
                            {
                                id: Math.floor(Math.random() * 10000),
                                jsonrpc: '2.0',
                                method: 'process',
                            },
                            {
                                headers: new HttpHeaders({ 'Content-Type': 'application/json-rpc' }),
                            }
                        )
                        .subscribe(() => {
                            this.toastr.success(this.translate.instant('common.transaction-processed'))
                            this.onSave.next()
                        })
                })
            )
        }
    }

    public changeType(type: 'deposit' | 'transfer' | 'withdrawal'): void {
        this.reset()
        switch (type) {
            case 'deposit':
                this.item = new Transaction({
                    type: 'deposit',
                    baseWallet: { user: null },
                    counterWallet: {},
                } as ITransaction)
                this.activeTransactionMethods = this.transactionMethods.filter(
                    method => !['internal', 'card'].includes(method)
                )
                this.sender = undefined
                break
            case 'transfer':
                this.item = new Transaction({
                    type: 'transfer',
                    method: 'internal',
                    baseWallet: {},
                    counterWallet: {},
                } as ITransaction)
                this.activeCurrencies = cloneDeep(this.currencies)
                break
            case 'withdrawal':
                this.item = new Transaction({
                    type: 'withdrawal',
                    baseWallet: {},
                    counterWallet: { user: null },
                } as ITransaction)
                this.activeTransactionMethods = this.transactionMethods.filter(
                    method => !['internal', 'card'].includes(method)
                )
                this.recipient = undefined
                break
        }
        this.copy = this.item
    }

    public assignDepositInstruction(): void {
        if (this.item.type !== 'deposit' || !this.copy.method || !this.copy.currency || !this.recipient) {
            return
        }

        if (this.copy.method === 'crypto') {
            this.copy.beneficiary = {
                accountNumber: (environment.cryptoDepositAddresses as any)[this.copy.currency?.code as any],
                currency: {
                    code: this.copy.currency?.code,
                } as ICurrency,
                method: 'crypto',
                type: 'crypto-address',
            } as IBeneficiary
        } else {
            this.beneficiaryService
                .fetchDepositInstruction(
                    this.copy.method as BeneficiaryMethod,
                    this.copy.currency.code,
                    this.recipient.user?.id
                )
                .subscribe(depositInstruction => {
                    this.copy.beneficiary = depositInstruction
                })
        }
    }

    public resetBeneficiary(): void {
        if (['deposit', 'withdrawal'].includes(this.item.type)) {
            this.copy.beneficiary = {
                method: this.copy.method,
            } as IBeneficiary
        }
    }

    public openBeneficiaryBook(): void {
        if (!this.sender?.user) {
            return
        }

        const modal = this.ngbModal.open(BeneficiaryBookComponent, {
            windowClass: 'modal-primary',
            size: 'lg',
        })
        ;(modal.componentInstance as BeneficiaryBookComponent).userId = this.sender.user.id
        ;(modal.componentInstance as BeneficiaryBookComponent).onSelect.subscribe((beneficiary: Beneficiary) => {
            if (this.copy.currency?.code !== beneficiary.currency.code || this.copy.method !== beneficiary.method) {
                this.toastr.warning(
                    `${this.translate.instant('transaction-form.invalid-beneficiary-for-transaction')}`,
                    'Ooops...'
                )
                return
            }
            this.copy.beneficiary = beneficiary
            modal.close()
        })
    }

    public getRootBalance(): string | null {
        if (!this.copy.currency?.code) {
            return null
        }
        const walletInCurrency = this.rootWallets.find(wallet => wallet.currency.code === this.copy.currency!.code)
        const balance = walletInCurrency ? walletInCurrency.balance : 0
        return new BigNumber(balance).toFixed(this.copy.currency.decimalPlaces, 1)
    }

    public mint(type: 'generate' | 'destroy'): void {
        const modal = this.ngbModal.open(TransactionFormComponent, {
            backdrop: 'static',
            windowClass: 'modal-primary',
        })
        const form = modal.componentInstance as TransactionFormComponent
        form.item = new Transaction({
            type: 'mint',
            method: 'internal',
            baseWallet: type === 'destroy' ? ({ user: null } as Wallet) : null,
            counterWallet: type === 'generate' ? ({ user: null } as Wallet) : null,
            currency: this.copy.currency,
        } as ITransaction)
        form.onSave.subscribe(() => {
            modal.close()
            this.fetchRootWallets.next()
        })
    }

    public buildTitle(): string {
        let title: string
        if (this.item.type === 'mint') {
            title = `${this.item.baseWallet === null ? 'Generate' : 'Destroy'} funds`
        } else if (this.item.isPlatformTransfer()) {
            title = `Platform transfer [${this.item.baseWallet && this.item.baseWallet.isRootWallet() ? '+' : '-'}]`
        } else {
            title = `${this.item.id ? 'View' : 'Create'} ${
                ['deposit', 'withdrawal'].includes(this.item.type) ? this.item.type : 'transaction'
            }`
        }
        return title
    }

    public openProformaInvoice(): void {
        this.subscriptions.add(
            this.session.createSignedUrl(`/transactions/${this.item.id}/proforma-invoice`).subscribe(signedUrl => {
                window.open(signedUrl, '_blank', 'noopener')
            })
        )
    }

    public downloadXml(): void {
        this.subscriptions.add(
            this.http
                .get(`/admin/transactions/${this.item.id}/xml`, {
                    responseType: 'text',
                })
                .subscribe(file => {
                    const xmlFile = new Blob([file], { type: 'text/xml' })
                    const url = URL.createObjectURL(xmlFile)
                    const link = this.renderer.createElement('a')
                    link.setAttribute('target', '_blank')
                    link.setAttribute('href', url)
                    link.setAttribute('download', `withdrawal-${this.item.id}.xml`)
                    link.click()
                    link.remove()
                })
        )
    }

    public refreshFees(): void {
        this.http.put<ITransaction>(`/transactions/${this.item.id}/refresh-fees`, {}).subscribe(_ => {
            this.toastr.success(this.translate.instant('transaction-form.transaction-fees-updated'))
            this.fetchEvent.next()
        })
    }

    public updateExternalReference(): void {
        this.isUpdating = true

        this.http
            .post<Transaction>(
                `/admin/transactions/${this.item.id}/change-external-reference`,
                {
                    externalReference: this.copy.externalReference,
                },
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                }
            )
            .pipe(
                finalize(() => {
                    this.isUpdating = false
                })
            )
            .subscribe(transaction => {
                this.item.reference = transaction.reference
                this.toastr.success(this.translate.instant('transaction-form.external-reference-updated'))
            })
    }

    public isBitGoTransaction(transaction: Transaction): boolean {
        return this.transactionService.isBitGoTransaction(transaction)
    }

    public swapBetweenAddOrDeductFunds(value: string) {
        if (value === 'add') {
            if (this.item && this.item.baseWallet?.user !== null) {
                const { baseWallet, counterWallet } = this.item

                const temp = baseWallet
                this.item.baseWallet = counterWallet
                this.item.counterWallet = temp
            }
        } else if (value === 'deduct') {
            if (this.item && this.item.counterWallet?.user !== null) {
                const { baseWallet, counterWallet } = this.item

                const temp = baseWallet
                this.item.baseWallet = counterWallet
                this.item.counterWallet = temp
            }
        }

        this.copy = cloneDeep(this.item)
    }

    private reset(): void {
        this.sender = undefined
        this.recipient = undefined
        if (this.transactionForm) {
            this.transactionForm.reset()
            this.transactionForm.resetForm()
        }
    }
}
