import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { switchMap, take, tap } from 'rxjs/operators'
import { WalletsStore } from './wallets.store'
import { Wallet } from '../../common/api-interfaces'
import { SessionService } from '../../common/services/session.service'

@Injectable({ providedIn: 'root' })
export class WalletsService {
    constructor(protected store: WalletsStore, private session: SessionService, private http: HttpClient) {}

    public fetchAsObservable(params?: { limit?: string; order?: string }): Observable<Wallet[]> {
        return this.session.userStream.pipe(
            take(1),
            switchMap(user =>
                this.http.get<Wallet[]>(`/users/${user.id}/wallets`, {
                    params: {
                        limit: params?.limit || '20',
                        order: params?.order || 'balance:DESC',
                    },
                })
            ),
            tap(wallets => {
                this.store.set(wallets)
            })
        )
    }

    public fetch(): void {
        this.fetchAsObservable().subscribe(() => undefined)
    }
}
