import { Pipe, PipeTransform } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import { capitalize } from 'lodash'

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
    public transform(value: any): SafeUrl {
        if (typeof value !== 'string') {
            return value
        }
        return capitalize(value)
    }
}
