import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { BeneficiaryMethod, BeneficiaryType, IBeneficiary } from '../../api-interfaces'
import { Beneficiary } from '../../models/accounting/beneficiary.model'

@Injectable({
    providedIn: 'root',
})
export class BeneficiaryService {
    constructor(private http: HttpClient) {}

    public count(params: {
        type: BeneficiaryType
        method: BeneficiaryMethod
        currencyCode: string
    }): Observable<number> {
        return this.http
            .head('/beneficiaries', {
                observe: 'response',
                params: {
                    type: params.type,
                    method: params.method,
                    currencyCode: params.currencyCode,
                },
            })
            .pipe(
                map(response =>
                    response.headers && response.headers.has('x-total-count')
                        ? ~~response.headers.get('x-total-count')!
                        : 0
                )
            )
    }

    public fetchDepositInstruction(
        method: BeneficiaryMethod,
        currencyCode: string,
        userId?: string,
        userType?: 'personal' | 'business'
    ): Observable<Beneficiary | null> {
        let params = new HttpParams().set('method', method)
        if (userType) {
            params = params.set('userType', userType)
        }
        return this.http
            .get<IBeneficiary[]>((userId ? `/users/${userId}` : '') + '/deposit-instructions', {
                params,
            })
            .pipe(
                map(beneficiaries => {
                    const withSameCurrency = beneficiaries?.find(
                        beneficiary => beneficiary.currency.code === currencyCode
                    )
                    switch (method) {
                        case 'swift':
                            if (withSameCurrency) {
                                return new Beneficiary(withSameCurrency)
                            }
                            const withUsd = beneficiaries?.find(beneficiary => beneficiary.currency.code === 'USD')
                            if (withUsd) {
                                return new Beneficiary(withUsd)
                            }
                            return null
                        case 'local':
                            if (withSameCurrency) {
                                return new Beneficiary(withSameCurrency)
                            }
                            return null
                        default:
                            return null
                    }
                })
            )
    }
}
