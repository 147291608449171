import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'file-select',
    templateUrl: 'file-select.component.html',
})
export class FileSelectComponent {
    @Input()
    public label = ''

    @Input()
    public file: File
    @Output()
    public readonly fileChange = new EventEmitter<File>()

    public preview: string

    public onChange(file: File) {
        this.fileChange.emit(file)
    }
}
