import BigNumber from 'bignumber.js'
import * as Color from 'color'
import { ICurrency, IFee } from '../../common/api-interfaces'

const style = getComputedStyle(document.body)
const color = Color(style.getPropertyValue('--bs-primary').trim())

export function getCurrencyColor(currency: ICurrency, factor = 0): string {
    return currency.color || color.lighten((factor % 7) * 0.08).hex()
}

export function convertCurrency(
    amount: string | number,
    baseCurrency: ICurrency,
    counterCurrency: ICurrency,
    spreadFee?: IFee,
    spreadUp = true
): string {
    if (!baseCurrency || !counterCurrency) {
        return '0.00'
    }
    let exchangeRate = calculateExchangeRate(baseCurrency, counterCurrency)
    if (baseCurrency.code !== counterCurrency.code && spreadFee && spreadFee.relative) {
        if (spreadUp) {
            exchangeRate = new BigNumber(exchangeRate).times(new BigNumber(1).plus(spreadFee.relative))
        } else {
            exchangeRate = new BigNumber(exchangeRate).times(new BigNumber(1).minus(spreadFee.relative))
        }
    }
    return new BigNumber(amount).times(exchangeRate).toFixed(8, 1)
}

export function calculateExchangeRate(baseCurrency: ICurrency, counterCurrency: ICurrency): BigNumber {
    if (!counterCurrency || !baseCurrency) {
        return new BigNumber(1)
    }
    return new BigNumber(counterCurrency.exchangeRate).div(baseCurrency.exchangeRate)
}
