<div class="modal-header">
    <h5 class="modal-title">{{ (isAdmin ? 'common.deposit-instruction-templates' : 'common.beneficiary-templates') | translate }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="modal.dismiss()">&times;</button>
</div>
<div class="modal-body">
    <div class="row no-gutters align-items-center mb-3">
        <div class="col-lg-7 mb-2 mb-lg-0">
            <div class="input-group">
                <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
                <input
                    type="text"
                    name="searchText"
                    (ngModelChange)="searchEvent.next()"
                    [placeholder]="'common.search' | translate"
                    class="form-control"
                    [(ngModel)]="search"
                />
            </div>
        </div>
        <div class="col-lg-17 d-flex justify-content-between align-items-center">
            <span class="ms-lg-3"
                >{{ count | number }} {{ (count === 1 ? 'common.entry-found' : 'common.entries-found') | translate | lowercase }}</span
            >
            <div class="d-flex align-items-center">
                <ng-select
                    class="me-2"
                    [(ngModel)]="limit"
                    dropdownPosition="auto"
                    (change)="select(1)"
                    [items]="limitOpts"
                    [placeholder]="'common.show-entries-per-page' | translate"
                    [clearable]="false"
                    [searchable]="false"
                    required
                >
                    <ng-template ng-label-tmp let-item="item">{{ 'common.show-entries' | translate : { value: item } }}</ng-template>
                    <ng-template ng-option-tmp let-item="item">{{ item }} {{ 'common.entries' | translate }}</ng-template>
                </ng-select>
                <ngb-pagination
                    [(page)]="page"
                    [collectionSize]="count"
                    (pageChange)="select()"
                    [pageSize]="limit"
                    [maxSize]="5"
                    [ellipses]="false"
                    [rotate]="true"
                    [boundaryLinks]="true"
                >
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="table-responsive position-relative">
        <table class="table table-hover table-bordered table-data">
            <thead class="table-light">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ 'common.template' | translate | lowercase }}</th>
                    <th scope="col">{{ 'common.actions' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="itemsStream | async; let items">
                    <tr *ngFor="let item of items" class="clickable" (click)="onSelect.emit(item)">
                        <th scope="row" [width]="'1%'">
                            <uuid [value]="item.id"></uuid>
                        </th>
                        <td class="p-2">
                            <div class="d-flex w-100 justify-content-between">
                                <span
                                    class="text-uppercase label label-xs"
                                    [ngClass]="{
                                        'label-primary': item.method === 'swift',
                                        'label-success': item.method === 'local',
                                        'label-warning': item.method === 'crypto'
                                    }"
                                >
                                    {{ item.method }} - {{ item.currency?.code }}
                                </span>
                                <small class="text-muted">{{ item.createdAt | date }}</small>
                            </div>
                            <div>
                                {{ item.method === 'crypto' && !item.accountHolder ? ('common.wallet-address' | translate) : item.accountHolder }}
                            </div>
                            <span class="text-muted">{{ item.accountNumber }}</span>
                        </td>
                        <td class="action">
                            <button
                                type="button"
                                class="btn btn-primary-air btn-sm me-1"
                                (click)="$event.stopPropagation(); openBeneficiaryForm(item)"
                            >
                                <fa-icon [icon]="faPencil" [fixedWidth]="true"></fa-icon>
                            </button>
                            <button type="button" class="btn btn-danger-air btn-sm" (click)="$event.stopPropagation(); delete(item)">
                                <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="items.length === 0">
                        <td colspan="9" class="text-center">{{ 'common.no-entries-found' | translate }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <ng-container *ngIf="isFetching | async">
            <circle-spinner></circle-spinner>
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary btn-w-sm" (click)="openBeneficiaryForm($any({ type: 'template', user: determineUser() }))">
        <fa-icon class="me-1" [icon]="faPlus"></fa-icon> {{ 'common.new-beneficiary' | translate }}
    </button>
</div>
